import React, { Component } from 'react'
import { Manager, Popper, Reference } from 'react-popper'
import { ButtonMenuItem } from './ButtonMenuItem'
import ProjectsMenu from './ProjectsMenu'
import { connect } from 'react-redux'

class ProjectMenuItem extends Component {

  constructor (props, context) {
    super(props, context)
    this.state = {
      show: false,
    }
  }

  render () {

    return (
      <Manager>
        <Reference>
          {({ ref }) => (
            <li ref={ref}>
              <ButtonMenuItem className={'navigation-menu-separate'}
                              title={'Project'}
                              active={this.state.show}
                              darkMode={this.props.advancedSettings}
                              onClick={() => this.handleMenuItemClick()}/>
            </li>
          )}
        </Reference>
        {this.state.show &&
        <Popper placement="bottom-start">
          {({ ref, style, placement }) => (
            <div ref={ref} style={{ ...style, zIndex: 3 }} data-placement={placement}>
              <ProjectsMenu onClose={() => this.handleClose()}/>
            </div>
          )}
        </Popper>}

      </Manager>
    )

  }

  handleMenuItemClick () {
    let newValue = !this.state.show
    this.setMenuState(newValue)
  }

  handleClose () {
    this.setMenuState(false)
  }

  setMenuState (show) {
    this.setState({
      show: show,
    })
  }

}

let mapStateToProps = (state) => {
  return {
    advancedSettings: state.settings.appSettings.advancedSettings,
  }
}

export default connect(mapStateToProps)(ProjectMenuItem)