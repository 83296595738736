import React, { Component, Fragment } from 'react'
import { Manager, Popper, Reference } from 'react-popper'
import { Route, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import ScrollMenu from 'react-horizontal-scrolling-menu'

import { getCurrentVariationId } from '../../common/utils'
import { createVariation } from '../../common/redux/variations/variations-actions'
import { getVariation } from '../../common/redux/variations/variations-reducer'
import { setQueryParam } from '../../common/redux/router/router-actions'
import { redoProject, undoProject } from '../../common/redux/undo/undo-actions'

import Text from '../../common/components/Text'

import IconButton from '../../common/components/IconButton'
import VariationsMenu from './VariationsMenu'

import './VariationsNavigation.scss'
import { iconTypes } from '../../common/components/icons/IconFactory'

const VariationNavigationTabContent = ({ variation: { name }, darkMode }) => {
  return (
    <div className={'scenario-navigation-tab-content' + (darkMode ? ' dark-mode' : '')}>
      <div className='scenario-navigation-tab-text'>
        <span title={name}>{name}</span>
      </div>
    </div>
  )
}

const VariationScrollMenu = (props) => {

  const variationTabs = props.variations.map((variation) => (
    <VariationNavigationTabContent key={variation.id} variation={variation} darkMode={props.darkMode}/>
  ))

  return (
    <div className={'variation-scroll-menu-container' + (props.darkMode ? ' dark-mode' : '')}>
      <ScrollMenu
        data={variationTabs}
        transition={0.3}
        selected={props.selected}
        onSelect={(key) => props.onSelect(key)}
        translate={0}
        itemClass={'scenario-navigation-tab' + (props.darkMode ? ' dark-mode' : '')}
        itemStyle={{ display: 'inline-flex' }}
        useButtonRole={true}
        alignCenter={false}
        scrollToSelected={true}
        dragging={true}
        clickWhenDrag={false}
        wheel={true}/>
    </div>
  )
}

const VariationMenu = (props) => {

  const variationTabs = props.variations.map((variation) => {

    let style = variation.id === props.selected ? ' active' : ''

    style += (props.darkMode ? ' dark-mode' : '')

    return (
      <div key={variation.id} className={'scenario-navigation-tab' + style} role={'button'} onClick={() => props.onSelect(variation.id)}>
        <VariationNavigationTabContent variation={variation} darkMode={props.darkMode}/>
      </div>
    )
  })

  return <ul className={'variation-menu-container' + (props.darkMode ? ' dark-mode' : '')}>
    {variationTabs}
  </ul>
}

class VariationsNavigation extends Component {

  constructor (props, context) {
    super(props, context)
    this.state = {
      showMenu: false,
    }
  }

  setSelected (id) {
    this.props.setQueryParam('variation', id)
  }

  handleAddButtonClick () {

    const variation = {
      name: 'New Variation',
    }

    this.props.createVariation(this.props.match.params.projectId, variation)
  }

  render () {

    const { selected, variations } = this.props

    const undoButtonColor = this.props.advancedSettings ?
      this.props.undo.undoAvailable ? '#ffffff' : '#ffffff77' :
      this.props.undo.undoAvailable ? '#393B53' : '#C4C6D2'
    const redoButtonColor = this.props.advancedSettings ?
      this.props.undo.redoAvailable ? '#ffffff' : '#ffffff77' :
      this.props.undo.redoAvailable ? '#393B53' : '#C4C6D2'

    return (
      <div className={'scenario-navigation' + (this.props.advancedSettings ? ' dark-mode' : '')}>

        <div className={'undoRedo' + (this.props.advancedSettings ? ' dark-mode' : '')}>
          <IconButton onClick={() => this.undo()}
                      icon={iconTypes.undoActive}
                      color={undoButtonColor}
                      disabled={!this.props.undo.undoAvailable}/>
          <IconButton onClick={() => this.redo()}
                      icon={iconTypes.redoActive}
                      color={redoButtonColor}
                      disabled={!this.props.undo.redoAvailable}/>
        </div>
        <div className={'project-name-container' + (this.props.advancedSettings ? ' dark-mode' : '')}>
          {this.props.project && <Text outerClassName={'project-name-text'}>{this.props.project.name}</Text>}
        </div>


        {this.props.advancedSettings ?
          <Fragment>
            {variations.length > 3 ?
              <VariationScrollMenu variations={variations} selected={selected.id} onSelect={(key) => this.setSelected(key)} darkMode={this.props.advancedSettings}/> :
              <VariationMenu variations={variations} selected={selected.id} onSelect={(key) => this.setSelected(key)} darkMode={this.props.advancedSettings}/>
            }
            <Route exact path="/projects">
              <IconButton disabled className={'scenario-navigation-plus-button' + (this.props.advancedSettings ? ' dark-mode' : '')} icon={iconTypes.plus}/>
              <div className='scenario-navigation-more-button-container'>
                <IconButton disabled className={'scenario-navigation-more-button' + (this.props.advancedSettings ? ' dark-mode' : '')} icon={iconTypes.moreVertical}/>
              </div>
            </Route>
            <Route path="/projects/:projectId" render={({ match }) => (
              <React.Fragment>
                <IconButton className={'scenario-navigation-plus-button' + (this.props.advancedSettings ? ' dark-mode' : '')} icon={iconTypes.add} inverted={this.props.advancedSettings}
                            onClick={() => this.handleAddButtonClick()}/>
                <Manager>
                  <Reference>
                    {({ ref }) => (
                      <div ref={ref} className='scenario-navigation-more-button-container'>
                        <IconButton className={'scenario-navigation-more-button' + (this.state.showMenu ? ' active' : '') + (this.props.advancedSettings ? ' dark-mode' : '')}
                                    icon={iconTypes.moreVertical}
                                    onClick={() => this.handleMenuButtonClick()}/>
                      </div>
                    )}
                  </Reference>
                  {this.state.showMenu &&
                  <Popper placement="bottom-end">
                    {({ ref, style, placement }) => (
                      <div ref={ref} style={{ ...style, zIndex: 3 }} data-placement={placement}>
                        <VariationsMenu projectId={match.params.projectId} variationId={selected.id} onClose={() => this.handleClose()}/>
                      </div>
                    )}
                  </Popper>}
                </Manager>
              </React.Fragment>

            )}/>
          </Fragment>
          : null
        }
      </div>
    )

  }

  undo = () => {
    this.props.undoProject(this.props.project.id, this.props.selected.id, this.props.buildingId, this.props.floorId, this.props.furnitureEnsembleId, this.props.patternId)
  }

  redo = () => {
    this.props.redoProject(this.props.project.id, this.props.selected.id, this.props.buildingId, this.props.floorId, this.props.furnitureEnsembleId, this.props.patternId)

  }

  handleMenuButtonClick () {
    let newValue = !this.state.showMenu
    this.setMenuState(newValue)
  }

  handleClose () {
    this.setMenuState(false)
  }

  setMenuState (show) {
    this.setState({
      showMenu: show,
    })
  }

}

let mapStateToProps = (state) => {
  return {
    buildingId: state.appState.selectedBuildingId,
    floorId: state.appState.selectedFloorId,
    project: state.projects.project,
    variations: state.variations.variations,
    undo: state.floorPlanUiState.undoInformation,
    selected: getVariation(state, getCurrentVariationId(state)) || state.variations.variations[0] || { id: null },
    furnitureEnsembleId: state.furnitureEnsembles.furnitureEnsemblePlan.id,
    patternId: state.pattern.patternPlan.id,

    advancedSettings: state.settings.appSettings.advancedSettings,
  }
}

let mapDispatchToProps = {
  undoProject: undoProject,
  redoProject: redoProject,
  createVariation: createVariation,
  setQueryParam: setQueryParam,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VariationsNavigation))


