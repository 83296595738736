import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Scrollbars } from 'react-custom-scrollbars'
import { push } from 'connected-react-router'

import BuilderOverviewNavBar from '../../../common/components/builder/BuilderOverviewNavBar'
import BuilderOverviewGroup from '../../../common/components/builder/BuilderOverviewGroup'
import ModalConsumer from '../../../common/components/modal/ModalConsumer'
import UploadDialog from '../../../header/components/UploadDialog'

import { addNewApplication, renameApplication } from '../../actions/frontendActions'
import { deleteFurnitureEnsemble, duplicateApplication, patchFurnitureEnsemble } from '../../actions/backendActions'
import { deleteApplications, exportApplications, importApplications } from '../../../common/redux/applications/applications-actions'

import Button from '../../../common/components/Button'
import ButtonToolbar from '../../../common/components/ButtonToolbar'
import Inspector from '../../../common/components/Inspector'
import TreeList from '../../../common/components/TreeList'

import './ApplicationBuilderOverview.scss'
import { iconTypes } from '../../../common/components/icons/IconFactory'
import { withTranslation } from 'react-i18next'
import { viewNames } from '../../../common/globalConstants'

class ApplicationBuilderOverview extends Component {

  constructor (props, context) {
    super(props, context)
    this.state = {
      selectedCategory: 'all',
    }
  }

  render () {
    const { t } = this.props

    return (
      <div className={'application-builder-overview'}>

        <BuilderOverviewNavBar icon={iconTypes.furnitureSetsBlue} text={`Furniture-Sets (${this.props.furnitureEnsembles.length})`}>

          <ModalConsumer>
            {({ showModal }) => (
              <ButtonToolbar className={'button-container'}>
                <Button className={'button'} variant={'text'} icon={'import-furniture-set'} onClick={() => showModal(UploadDialog, {
                  title: 'Import applications file',
                  upload: this.props.importApplications,
                  replace: false,
                  fileEndings: '.coa',
                })}>
                  {t('import')}
                </Button>
                <Button className={'button'} variant={'text'} icon={'replace-furniture-sets'} onClick={() => showModal(UploadDialog, {
                  title: 'Replace applications file',
                  upload: this.props.importApplications,
                  replace: true,
                  fileEndings: '.coa',
                })}>
                  {t('replace')}
                </Button>
                <Button className={'button'} variant={'text'} icon={'export-furniture-sets'} onClick={() => this.props.exportApplications()}>
                  {t('export')}
                </Button>
                <Button className={'button'} variant={'text'} icon={'delete'} onClick={() => this.props.deleteApplications()}>
                  {t('delete_all')}
                </Button>
              </ButtonToolbar>
            )}
          </ModalConsumer>

        </BuilderOverviewNavBar>

        <div className={'content'}>
          <Inspector>
            <Scrollbars autoHide>
              <TreeList className={'furniture-sets-category-tree-list'}
                        nodes={this.mapCategoriesToTreeList(this.props.furnitureEnsembleCategories)}
                        onLevelItemClick={[(item) => this.setState({ selectedCategory: item })]}
                        selected={this.state.selectedCategory}
                        maxLevel={0}/>
            </Scrollbars>
          </Inspector>

          <BuilderOverviewGroup projectId={this.props.match.params.projectId}
                                title={this.getSelectedTitle()}
                                overviewContent={this.filterApplicationsByCategoryId(this.state.selectedCategory)}
                                onElementClicked={(applicationId) => this.setCurrentApplicationId(applicationId)}
                                onCreateClicked={() => this.createNewApplication()}
                                onDeleteClicked={(projectId, appId) => this.props.deleteApplication(projectId, appId)}
                                onDuplicateClicked={(appId) => this.handleDuplicateApplication(appId)}
                                renameContent={(ensemble) => this.handleRenameApplication(ensemble)}
                                imagesPolygons={this.props.furnitureEnsemblesPolygons}/>
        </div>
      </div>
    )
  }

  mapCategoriesToTreeList (categoryList) {
    let sorted = categoryList.length ? categoryList.slice(0) : []
    sorted.sort((a, b) => {
      const x = a.displayName.toLowerCase()
      const y = b.displayName.toLowerCase()
      return x < y ? -1 : x > y ? 1 : 0
    })

    sorted.unshift({
      id: 'all',
      name: 'all',
      displayName: 'All',
      color: {
        red: 255,
        green: 255,
        blue: 255,
        alpha: 1,
      },
    })

    return sorted.map(cat => {
      return {
        key: cat.id,
        label: cat.displayName + ' (' + this.filterApplicationsByCategoryId(cat.id).length + ')',
        level: 0,
        color: {
          red: cat.color.red,
          green: cat.color.green,
          blue: cat.color.blue,
          alpha: 1,
        },
      }
    })
  }

  getSelectedTitle () {
    let title

    if (this.state.selectedCategory === 'all')
      title = 'All ('
    else
      title = this.props.furnitureEnsembleCategories.find(cat => cat.id === this.state.selectedCategory).displayName + ' ('

    return title + this.filterApplicationsByCategoryId(this.state.selectedCategory).length + ')'
  }

  filterApplicationsByCategoryId (categoryId) {
    if (categoryId.toLowerCase() === 'all')
      return this.props.furnitureEnsembles

    return this.props.furnitureEnsembles.filter(ensemble => ensemble.categoryId === categoryId)
  }

  createNewApplication () {
    this.props.addNewApplication()
    this.props.push(viewNames.SETS + '/new' + this.props.location.search)
  }

  setCurrentApplicationId (applicationId) {
    this.props.push(viewNames.SETS + '/' + applicationId + this.props.location.search)
  }

  handleRenameApplication (ensemble) {
    this.props.renameApplication(ensemble.id, ensemble.displayName)
    this.props.patchFurnitureEnsemble(this.props.match.params.projectId, ensemble.id, ensemble)
  }

  handleDuplicateApplication (application) {
    this.props.duplicateApplication(this.props.match.params.projectId, application.id)
  }
}

let mapStateToProps = (state) => {
  return {
    furnitureEnsembles: state.furnitureEnsembles.furnitureEnsembles,
    furnitureEnsembleCategories: state.furnitureEnsembles.furnitureEnsembleCategories,
    furnitureEnsemblesPolygons: state.furnitureEnsemblesPolygons,
    location: state.router.location,
  }
}

let mapDispatchToProps = {
  addNewApplication: addNewApplication,
  renameApplication: renameApplication,
  duplicateApplication: duplicateApplication,
  patchFurnitureEnsemble: patchFurnitureEnsemble,
  deleteApplication: deleteFurnitureEnsemble,
  importApplications: importApplications,
  exportApplications: exportApplications,
  deleteApplications: deleteApplications,
  push: push,
}

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(ApplicationBuilderOverview)))