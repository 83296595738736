import React from 'react'
import PropTypes from 'prop-types'

import SvgSettingsIconActive from './SettingsIconActive'
import SvgSettingsIconInactive from './SettingsIconInactive'
import SvgFloorsIconActive from './FloorsIconActive'
import SvgFloorsIconInactive from './FloorsIconInactive'
import SvgCorporateDataIconActive from './CorporateDataIconActive'
import SvgCorporateDataIconInactive from './CorporateDataIconInactive'
import SvgBlockingIconActive from './BlockingIconActive'
import SvgBlockingIconInactive from './BlockingIconInactive'
import SvgCloseIcon from './CloseIcon'
import SvgDataIcon from './DataIcon'
import SvgListArrow from './ListArrow'
import SvgMinusButton from './MinusButton'
import SvgListOfAppsIcon from './ListOfAppsIcon'
import SvgTagsIcon from './TagsIcon'
import SvgLayoutStylesIcon from './LayoutStylesIcon'
import SvgPatternStylesIcon from './PatternStylesIcon'
import SvgApplicationProfilesIcon from './ApplicationProfilesIcon'
import SvgAppCreatorChairIconActive from './AppCreatorChairIconActive'
import SvgAppCreatorChairIconInactive from './AppCreatorChairIconInactive'
import SvgAppCreatorDeskIconActive from './AppCreatorDeskIconActive'
import SvgAppCreatorTablesIconActive from './AppCreatorTablesIconActive'
import SvgAppCreatorLoungesIconActive from './AppCreatorLoungesIconActive'
import SvgAppCreatorPlantsIconActive from './AppCreatorPlantsIconActive'
import SvgAppCreatorPlantsIconInactive from './AppCreatorPlantsIconInactive'
import SvgAppCreatorContainerIconInactive from './AppCreatorContainerIconInactive'
import SvgAppCreatorContainerIconActive from './AppCreatorContainerIconActive'
import SvgAppCreatorLoungesIconInactive from './AppCreatorLoungesIconInactive'
import SvgAppCreatorDeskIconInactive from './AppCreatorDeskIconInactive'
import SvgAppCreatorTablesIconInactive from './AppCreatorTablesIconInactive'
import SvgAppCreatorElectronicsIconActive from './AppCreatorElectronicsIconActive'
import SvgAppCreatorElectronicsIconInactive from './AppCreatorElectronicsIconInactive'
import SvgAppCreatorOthersIconActive from './AppCreatorOthersIconActive'
import SvgAppCreatorOthersIconInactive from './AppCreatorOthersIconInactive'
import SvgToolboxZoomIcon from './ToolboxZoomIcon'
import SvgRotateIcon from './RotateIcon'
import SvgBack from './Back'
import SvgFloorPlanIcon from './FloorplanIcon'
import SvgFloorplanIconActive from './FloorplanIconActive'
import SvgAddIcon from './AddIcon'
import SvgEditNameIcon from './EditNameIcon'
import SvgFolderIcon from './FolderIcon'
import SvgUndoIcon from './UndoIcon'
import SvgRedoIcon from './RedoIcon'
import SvgCheckIcon from './CheckIcon'
import SvgArrowSmall from './ArrowSmall'
import SvgArrowSmallDown from './ArrowSmallDown'
import SvgExpandedIcon from './ExpandedIcon'
import SvgCollapsedIcon from './CollapsedIcon'
import SvgDotsIcon from './SvgDotsIcon'
import SvgAdjacenciesIcon from './AdjacenciesIcon'
import SvgLensIcon from './LensIcon'
import SvgPlusButton from './PlusButton'
import SvgDeleteIcon from './DeleteIcon'
import SvgHomeIcon from './HomeIcon'
import SvgEditApplicationIcon from './EditApplicationIcon'
import SvgInfoIcon from './InfoIcon'
import SvgLockedIcon from './LockedIcon'
import SvgUnlockedIcon from './UnlockedIcon'
import SvgMarkerIcon from './MarkerIcon'
import SvgMoveAxisIcon from './MoveAxisIcon'
import SvgMoveIcon from './MoveIcon'
import SvgOptimizeIcon from './OptimizeIcon'
import SvgTransform from './Transform'
import SvgBathroomIndicator from './BathroomIndicator'
import SvgCafeIndicator from './CafeIndicator'
import SvgCustomIndicator from './CustomIndicator'
import SvgDoorIndicator from './DoorIndicator'
import SvgStaircaseIndicator from './StaircaseIndicator'
import SvgLow from './Low'
import SvgMid from './Mid'
import SvgHigh from './High'
import SvgLowActive from './LowActive'
import SvgMidActive from './MidActive'
import SvgHighActive from './HighActive'
import SvgZoomAllIcon from './ZoomAllIcon'
import SvgMirrorButton from './MirrorButton'
import SvgHeatmapTeamsActive from './HeatmapTeamsActive'
import SvgHeatmapTeamsInactive from './HeatmapTeamsInactive'
import SvgHeatmapLightActive from './HeatmapLightActive'
import SvgHeatmapLightInactive from './HeatmapLightInactive'
import SvgHeatmapNoiseActive from './HeatmapNoiseActive'
import SvgHeatmapNoiseInactive from './HeatmapNoiseInactive'
import SvgHeatmapActivityActive from './HeatmapActivityActive'
import SvgHeatmapActivityInactive from './HeatmapActivityInactive'
import SvgQuality from './Quality'
import SvgArea from './Area'
import SvgHeatmap from './Heatmap'
import SvgExpand from './Expand'
import SvgCollapse from './Collapse'
import SvgBlackWhiteInactive from './BlackWhiteInactive'
import SvgBlackWhiteActive from './BlackWhiteActive'
import SvgDuplicate from './Duplicate'
import SvgSizeIconActive from './SizeIcon'
import SvgBuildingIcon from './BuildingIcon'
import SvgFloorIcon from './FloorIcon'
import SvgTransformFurnitureIcon from './TransformFurnitureIcon'
import SvgOpenNewTabIcon from './OpenNewTabIcon'
import SvgSupportIcon from './SupportIcon'
import SvgAddUnit from './AddUnitIcon'
import SvgDrawPolygonActive from './DrawPolygonActive'
import SvgDrawPolygonInactive from './DrawPolygonInactive'
import SvgDrawRectangleInactive from './DrawRectangleInactive'
import SvgDrawSelectActive from './DrawSelectActive'
import SvgDrawSelectInactive from './DrawSelectInactive'
import SvgDrawScaleActive from './DrawScaleActive'
import SvgDrawScaleInactive from './DrawScaleInactive'
import SvgDrawRectangleActive from './DrawRectangleActive'
import SvgDrawDoorActive from './DrawDoorActive'
import SvgDrawDoorInactive from './DrawDoorInactive'
import SvgDrawWindowActive from './DrawWindowActive'
import SvgDrawWallInactive from './DrawWallInactive'
import SvgDrawWallActive from './DrawWallActive'
import SvgDrawZoneActive from './DrawZoneActive'
import SvgDrawZoneInactive from './DrawZoneInactive'
import SvgDrawServiceActive from './DrawServiceActive'
import SvgDrawServiceInactive from './DrawServiceInactive'
import SvgDrawAxesActive from './DrawAxesActive'
import SvgDrawAxesInactive from './DrawAxesInactive'
import SvgDrawWindowInactive from './DrawWindowInactive'
import SvgOpenFolderIcon from './OpenFolderIcon'
import SvgCreateNewIcon from './CreateNewIcon'
import SvgImportIcon from './ImportIcon'
import SvgOpenDemoIcon from './OpenDemoIcon'
import SvgTeam from './Team'
import SvgCleaning from './Cleaning'
import SvgDrawPillarActive from './DrawPillarActive'
import SvgDrawPillarInactive from './DrawPillarInactive'
import SvgDrawLinkActive from './DrawLinkActive'
import SvgDrawLinkInactive from './DrawLinkInactive'
import SvgHelpIcon from './HelpIcon'
import SvgDrawNavbarNumber1Active from './DrawNavbarNumber1Active'
import SvgDrawNavbarNumber2Active from './DrawNavbarNumber2Active'
import SvgDrawNavbarNumber3Active from './DrawNavbarNumber3Active'
import SvgDrawNavbarNumber4Active from './DrawNavbarNumber4Active'
import SvgDrawNavbarNumber5Active from './DrawNavbarNumber5Active'
import SvgDrawNavbarNumber6Active from './DrawNavbarNumber6Active'
import SvgDrawNavbarNumber7Active from './DrawNavbarNumber7Active'
import SvgDrawNavbarNumber1 from './DrawNavbarNumber1'
import SvgDrawNavbarNumber2 from './DrawNavbarNumber2'
import SvgDrawNavbarNumber3 from './DrawNavbarNumber3'
import SvgDrawNavbarNumber4 from './DrawNavbarNumber4'
import SvgDrawNavbarNumber5 from './DrawNavbarNumber5'
import SvgDrawNavbarNumber6 from './DrawNavbarNumber6'
import SvgDrawNavbarNumber7 from './DrawNavbarNumber7'
import SvgDrawNavbarNumber1Focus from './DrawNavbarNumber1Focus'
import SvgDrawNavbarNumber2Focus from './DrawNavbarNumber2Focus'
import SvgDrawNavbarNumber3Focus from './DrawNavbarNumber3Focus'
import SvgDrawNavbarNumber4Focus from './DrawNavbarNumber4Focus'
import SvgDrawNavbarNumber5Focus from './DrawNavbarNumber5Focus'
import SvgDrawNavbarNumber6Focus from './DrawNavbarNumber6Focus'
import SvgDrawNavbarNumber7Focus from './DrawNavbarNumber7Focus'
import SvgDrawNavbarCheck from './DrawNavbarCheck'
import SvgUndoActive from './UndoActive'
import SvgRedoActive from './RedoActive'
import SvgUndoInactive from './UndoInactive'
import SvgRedoInactive from './RedoInactive'
import SvgDrawAxesAutoGenerateActive from './DrawAxesAutoGenerateActive'
import SvgDrawAxesAutoGenerateInactive from './DrawAxesAutoGenerateInactive'
import SvgDrawDeleteActive from './DrawDeleteActive'
import SvgDrawDeleteInactive from './DrawDeleteInactive'
import SvgDrawOutlineActive from './DrawOutlineActive'
import SvgDrawOutlineInactive from './DrawOutlineInactive'
import SvgDrawInfrastructureActive from './DrawInfrastructureActive'
import SvgDrawInfrastructureInactive from './DrawInfrastructureInactive'
import SvgDrawBlockedActive from './DrawBlockedActive'
import SvgDrawBlockedInactive from './DrawBlockedInactive'
import SvgToggleIconPositive from './ToggleIconPositive'
import SvgToggleIconNegative from './ToggleIconNegative'
import SvgExpandedWhiteIcon from './ExpandedIconWhite'
import SvgAssistant from './Assistant'
import SvgMovingCar from './MovingCar'
import SvgSuperDepartment from './SuperDepartment'
import SvgDepartment from './Department'
import SvgEditOutline from './EditOutline'
import SvgRename from './Rename'
import SvgAddFloor from './AddFloor'
import SvgAddBuilding from './AddBuilding'
import SvgNext from './Next'
import SvgEditValues from './EditValues'
import SvgSuitcase from './Suitcase'
import SvgExportFile from './ExportFile'
import SvgAssignItem from './AssignItem'
import SvgPreview from './Preview'
import SvgDrawPanActive from './DrawPanActive'
import SvgDrawPanInactive from './DrawPanInactive'
import ReplaceFurnitureSetIcon from './ReplaceFurnitureSetIcon'
import ImportFurnitureSetIcon from './ImportFurnitureSetIcon'
import ExportFurnitureSetsIcon from './ExportFurnitureSetsIcon'
import FurnitureSetsIcon from './FurnitureSetsIcon'
import ReplacePatternIcon from './ReplacePatternIcon'
import AddPatternIcon from './AddPatternIcon'
import ArchTargetIcon from './ArchTargetIcon'
import ArchTargetIconActive from './ArchTargetIconActive'
import ArchTargetMultiIconActive from './ArchTargetMultiIconActive'
import ArchTargetMultiIcon from './ArchTargetMultiIcon'
import CollaborateIcon from './CollaborateIcon'
import CollaborateIconActive from './CollaborateIconActive'
import AgileIconActive from './AgileIconActive'
import AgileIcon from './AgileIcon'
import SocialiseIconActive from './SocialiseIconActive'
import SocialiseIcon from './SocialiseIcon'
import SvgScreenshot from './ScreenshotIcon'
import SvgDocumentation from './Documentation'
import SvgWebsite from './Website'
import SvgELearning from './ELearning'
import SvgInterfererCheck from './InterfererCheck'
import UploadPicture from './UploadPicture'
import DrawOuterWall from './DrawOuterWall'
import DrawInnerWall from './DrawInnerWall'
import DrawDoorNew from './DrawDoorNew'
import DrawWindowNew from './DrawWindowNew'
import DrawPillarNew from './DrawPillarNew'
import DrawSlice from './DrawSlice'
import DrawScale from './DrawScale'
import DrawZoneNew from './DrawZoneNew'
import DrawServiceNew from './DrawServiceNew'
import DrawBlockedNew from './DrawBlockedNew'
import DrawInfrastructureNew from './DrawInfrastructureNew'
import AxesEditNew from './AxesEditNew'
import AxesAutoGenerateNew from './AxesAutoGenerateNew'
import AxesDeleteNew from './AxesDeleteIconNew'

const defaultPrimaryColor = '#00293b'
const defaultInvertedColor = '#ffffff'
const defaultDisabledColor = '#c4c6d2'
const defaultHighlightColor = '#f5854e'

/**
 * Common component to integrate icons.
 * You can define the following props:
 *
 * name: the name of the icon
 * active: if the icon should be shown in active version. Note: not all icons have an active version
 * inverted: if the icon should be shown in inverted version. This will change the icon color to work on a dark background
 * color: define a color string to change the icon stroke color. This will override the above changes to the color of the icon stroke.
 *
 * @param props
 * @returns {*} the icon svg element
 * @constructor
 */
const Icon = props => {

  const { disabledColor = defaultDisabledColor, invertedColor = defaultInvertedColor, primaryColor = defaultPrimaryColor, highlightColor = defaultHighlightColor } = props

  let color = props.disabled ? disabledColor : props.color ? props.color : props.highlight ? highlightColor : props.inverted ? invertedColor : primaryColor

  return <SVG className={props.className}
              active={props.active}
              focus={props.focus}
              name={props.name}
              color={color}/>
}

export const iconTypes = {
  area: 'area',
  add: 'add',
  close: 'close',
  editLine: 'edit-line',
  folder: 'folder',
  undo: 'undo',
  redo: 'redo',
  undoActive: 'undo-active',
  redoActive: 'redo-active',
  check: 'check',
  undoDrawing: 'undo-drawing',
  redoDrawing: 'redo-drawing',
  arrow: 'arrow',
  arrowSmall: 'arrow-small',
  arrowDown: 'arrow-down',
  arrowLeft: 'arrowLeft',
  arrowDownSmall: 'arrow-down-small',
  arrowLeftSmall: 'arrow-left-small',
  triangle: 'triangle',
  triangleUp: 'triangle-up',
  triangleDown: 'triangle-down',
  triangleDownWhite: 'triangle-down-white',
  moreVertical: 'more-vertical',
  settings: 'settings',
  floors: 'floors',
  corporate: 'corporate',
  blocking: 'blocking',
  floorplan: 'floorplan',
  adjacency: 'adjacency',
  data: 'data',
  lensTools: 'lensTools',
  applications: 'applications',
  tags: 'tags',
  applicationProfile: 'application-profile',
  layout: 'layout',
  pattern: 'pattern',
  patternGreen: 'pattern-green',
  addPattern: 'add-pattern',
  replacePattern: 'replace-pattern',
  chair: 'chair',
  desk: 'desk',
  storage: 'storage',
  table: 'table',
  lounge: 'lounge',
  loungeBlue: 'loungeBlue',
  plant: 'plant',
  electronic: 'electronic',
  other: 'other',
  minus: 'minus',
  plus: 'plus',
  edit: 'edit',
  duplicate: 'duplicate',
  delete: 'delete',
  home: 'home',
  info: 'info',
  locked: 'locked',
  unlocked: 'unlocked',
  pin: 'pin',
  moveAxis: 'move-axis',
  move: 'move',
  optimize: 'optimize',
  transform: 'transform',
  transformFurniture: 'transform-furniture',
  rotate: 'rotate',
  zoomAll: 'zoom-all',
  bathroom: 'bathroom',
  cafe: 'cafe',
  custom: 'custom',
  door: 'door',
  stairs: 'stairs',
  low: 'low',
  middle: 'middle',
  high: 'high',
  search: 'search',
  back: 'back',
  next: 'next',
  mirror: 'mirror',
  teams: 'teams',
  light: 'light',
  noise: 'noise',
  blackWhite: 'black-white',
  activity: 'activity',
  size: 'size',
  quality: 'quality',
  heatmap: 'heatmap',
  dashboard: 'dashboard',
  sidebar: 'sidebar',
  building: 'building',
  floor: 'floor',
  openNewTab: 'open-new-tab',
  support: 'support',
  addUnit: 'addUnit',
  assignItem: 'assignItem',
  editValues: 'edit-values',
  suitcase: 'suitcase',
  exportFile: 'export-file',
  preview: 'preview',
  openDemo: 'open-demo',
  import: 'import',
  createNew: 'create-new',
  openFolder: 'open-folder',
  cleaning: 'cleaning',
  assistant: 'assistant',
  movingCar: 'moving-car',
  superDepartment: 'super-department',
  department: 'department',
  team: 'team',
  editOutline: 'edit-outline',
  rename: 'rename',
  addFloor: 'add-floor',
  addBuilding: 'add-building',
  togglePositive: 'toggle-positive',
  toggleNegative: 'toggle-negative',
  drawSelect: 'draw-select',
  drawSelectActive: 'draw-select-active',
  drawSelectInactive: 'draw-select-inactive',
  drawPan: 'draw-pan',
  drawPanActive: 'draw-pan-active',
  drawPanInactive: 'draw-pan-inactive',
  drawDelete: 'draw-delete',
  drawDeleteActive: 'draw-delete-active',
  drawDeleteInactive: 'draw-delete-inactive',
  drawScale: 'draw-scale',
  drawScaleActive: 'draw-scale-active',
  drawScaleInactive: 'draw-scale-inactive',
  drawOutline: 'draw-outline',
  drawOutlineActive: 'draw-outline-active',
  drawOutlineInactive: 'draw-outline-inactive',
  drawRectangle: 'draw-rectangle',
  drawRectangleActive: 'draw-rectangle-active',
  drawRectangleInactive: 'draw-rectangle-inactive',
  drawPolygon: 'draw-polygon',
  drawPolygonActive: 'draw-polygon-active',
  drawPolygonInactive: 'draw-polygon-inactive',
  drawDoorActive: 'draw-door-active',
  drawDoorInactive: 'draw-door-inactive',
  drawWindow: 'draw-window',
  drawWindowActive: 'draw-window-active',
  drawWindowInactive: 'draw-window-inactive',
  drawPillar: 'draw-pillar',
  drawPillarActive: 'draw-pillar-active',
  drawPillarInactive: 'draw-pillar-inactive',
  drawWall: 'draw-wall',
  drawWallActive: 'draw-wall-active',
  drawWallInactive: 'draw-wall-inactive',
  drawLink: 'draw-link',
  drawLinkActive: 'draw-link-active',
  drawLinkInactive: 'draw-link-inactive',
  drawZone: 'draw-zone',
  drawZoneActive: 'draw-zone-active',
  drawZoneNew: 'draw-zone-new',
  drawZoneInactive: 'draw-zone-inactive',
  drawService: 'draw-service',
  drawServiceActive: 'draw-service-active',
  drawServiceNew: 'draw-service-new',
  drawServiceInactive: 'draw-service-inactive',
  drawInfrastructure: 'draw-infrastructure',
  drawInfrastructureActive: 'draw-infrastructure-active',
  drawInfrastructureNew: 'draw-infrastructure-new',
  drawInfrastructureInactive: 'draw-infrastructure-inactive',
  drawBlocked: 'draw-blocked',
  drawBlockedActive: 'draw-blocked-active',
  drawBlockedNew: 'draw-blocked-new',
  drawBlockedInactive: 'draw-blocked-inactive',
  drawAxes: 'draw-axes',
  drawAxesActive: 'draw-axes-active',
  drawAxesInactive: 'draw-axes-inactive',
  drawAxesAutogenerate: 'draw-axes-autogenerate',
  drawAxesAutogenerateActive: 'draw-axes-autogenerate-active',
  drawAxesAutogenerateInactive: 'draw-axes-autogenerate-inactive',
  drawOuterWall: 'draw-outer-wall',
  drawInnerWall: 'draw-inner-wall',
  drawDoorNew: 'draw-door-new',
  drawWindowNew: 'draw-window-new',
  drawPillarNew: 'draw-pillar-new',
  drawSlice: 'draw-slice',
  drawNavbarCheck: 'draw-navbar-check',
  axesEditNew: 'axes-edit-new',
  axesAutoGenerateNew: 'axes-autogenerate-new',
  axesDeleteNew: 'axes-delete-new',
  rawNavbar: 'draw-navbar',
  drawNavbar1: 'draw-navbar-1',
  drawNavbar2: 'draw-navbar-2',
  drawNavbar3: 'draw-navbar-3',
  drawNavbar4: 'draw-navbar-4',
  drawNavbar5: 'draw-navbar-5',
  drawNavbar6: 'draw-navbar-6',
  drawNavbar7: 'draw-navbar-7',

  furnitureSets: 'furniture-sets',
  furnitureSetsBlue: 'furniture-sets-blue',
  replaceFurnitureSets: 'replace-furniture-sets',
  importFurnitureSet: 'import-furniture-set',
  exportFurnitureSets: 'export-furniture-sets',

  //presets
  focussed: 'preset-focussed',
  focussedUltra: 'preset-focussedUltra',
  collaborate: 'preset-collaborate',
  agile: 'preset-agile',
  socialise: 'preset-socialise',

  help: 'help',

  screenshot: 'screenshot',
  documentation: 'documentation',
  website: 'website',
  eLearning: 'eLearning',

  interfererCheck: 'interfererIcon',

  uploadPicture: 'upload-picture',
}

Icon.propTypes = {
  name: PropTypes.string,
  active: PropTypes.bool,
  inverted: PropTypes.bool,
  color: PropTypes.string,
}

const SVG = props => {

  const { active, focus, name } = props

  switch (name) {

    case iconTypes.add:
      return <SvgAddIcon {...props}/>
    case iconTypes.close:
      return <SvgCloseIcon {...props}/>
    case iconTypes.editLine:
      return <SvgEditNameIcon {...props}/>
    case iconTypes.folder:
      return <SvgFolderIcon {...props}/>

    case iconTypes.undo:
      return <SvgUndoIcon {...props}/>
    case iconTypes.redo:
      return <SvgRedoIcon {...props}/>

    case iconTypes.undoActive:
      return <SvgUndoActive {...props}/>
    case iconTypes.redoActive:
      return <SvgRedoActive {...props}/>

    case iconTypes.check:
      return <SvgCheckIcon {...props}/>

    case iconTypes.undoDrawing:
      return active ? <SvgUndoActive {...props}/> : <SvgUndoInactive {...props}/>
    case iconTypes.redoDrawing:
      return active ? <SvgRedoActive {...props}/> : <SvgRedoInactive {...props}/>

    case iconTypes.arrowSmall:
      return <SvgArrowSmall {...props}/>
    case iconTypes.arrowDownSmall:
      return <SvgArrowSmallDown {...props}/>
    case iconTypes.arrowLeftSmall:
      return <SvgArrowSmall {...props} className={'rotate-180 ' + props.className}/>

    case iconTypes.arrow:
      return <SvgListArrow {...props}/>
    case iconTypes.arrowDown:
      return <SvgListArrow {...props} className={'rotate-90 ' + props.className}/>
    case iconTypes.arrowLeft:
      return <SvgListArrow {...props} className={'rotate-180 ' + props.className}/>

    case iconTypes.triangle:
      return <SvgCollapsedIcon {...props}/>
    case iconTypes.triangleUp:
      return <SvgExpandedIcon {...props} className={'rotate-180 ' + props.className}/>
    case iconTypes.triangleDown:
      return <SvgExpandedIcon {...props}/>
    case iconTypes.triangleDownWhite:
      return <SvgExpandedWhiteIcon {...props}/>

    case iconTypes.moreVertical:
      return <SvgDotsIcon {...props}/>

    case iconTypes.settings:
      return active ? <SvgSettingsIconActive {...props}/> : <SvgSettingsIconInactive {...props}/>
    case iconTypes.floors:
      return active ? <SvgFloorsIconActive {...props}/> : <SvgFloorsIconInactive {...props}/>
    case iconTypes.corporate:
      return active ? <SvgCorporateDataIconActive {...props}/> : <SvgCorporateDataIconInactive {...props}/>
    case iconTypes.blocking:
      return active ? <SvgBlockingIconActive {...props}/> : <SvgBlockingIconInactive {...props}/>

    case iconTypes.floorplan:
      return active ? <SvgFloorplanIconActive {...props}/> : <SvgFloorPlanIcon {...props}/>

    case iconTypes.adjacency:
      return <SvgAdjacenciesIcon {...props}/>
    case iconTypes.data:
      return <SvgDataIcon {...props}/>
    case iconTypes.lensTools:
      return <SvgLensIcon {...props}/>
    case iconTypes.applications:
      return <SvgListOfAppsIcon {...props}/>
    case iconTypes.tags:
      return <SvgTagsIcon {...props}/>
    case iconTypes.applicationProfile:
      return <SvgApplicationProfilesIcon {...props}/>
    case iconTypes.layout:
      return <SvgLayoutStylesIcon {...props}/>
    case iconTypes.pattern:
      return <SvgPatternStylesIcon {...props}/>
    case iconTypes.patternGreen:
      return <SvgPatternStylesIcon {...props} color={'#65C296'}/>
    case iconTypes.addPattern:
      return <AddPatternIcon {...props} />
    case iconTypes.replacePattern:
      return <ReplacePatternIcon {...props} />
    case iconTypes.furnitureSets:
      return <FurnitureSetsIcon {...props} />
    case iconTypes.furnitureSetsBlue:
      return <FurnitureSetsIcon {...props} color={'#00A2E1'}/>
    case iconTypes.replaceFurnitureSets:
      return <ReplaceFurnitureSetIcon {...props} />
    case iconTypes.importFurnitureSet:
      return <ImportFurnitureSetIcon {...props} />
    case iconTypes.exportFurnitureSets:
      return <ExportFurnitureSetsIcon {...props} />
    case iconTypes.uploadPicture:
      return <UploadPicture {...props} />

    case iconTypes.chair:
      return active ? <SvgAppCreatorChairIconActive className={props.className}/> : <SvgAppCreatorChairIconInactive className={props.className}/>
    case iconTypes.desk:
      return active ? <SvgAppCreatorDeskIconActive className={props.className}/> : <SvgAppCreatorDeskIconInactive className={props.className}/>
    case iconTypes.storage:
      return active ? <SvgAppCreatorContainerIconActive className={props.className}/> : <SvgAppCreatorContainerIconInactive className={props.className}/>
    case iconTypes.table:
      return active ? <SvgAppCreatorTablesIconActive className={props.className}/> : <SvgAppCreatorTablesIconInactive className={props.className}/>
    case iconTypes.lounge:
      return active ? <SvgAppCreatorLoungesIconActive className={props.className}/> : <SvgAppCreatorLoungesIconInactive className={props.className}/>
    case iconTypes.plant:
      return active ? <SvgAppCreatorPlantsIconActive className={props.className}/> : <SvgAppCreatorPlantsIconInactive className={props.className}/>
    case iconTypes.electronic:
      return active ? <SvgAppCreatorElectronicsIconActive className={props.className}/> : <SvgAppCreatorElectronicsIconInactive className={props.className}/>
    case iconTypes.other:
      return active ? <SvgAppCreatorOthersIconActive className={props.className}/> : <SvgAppCreatorOthersIconInactive className={props.className}/>

    case iconTypes.minus:
      return <SvgMinusButton {...props}/>
    case iconTypes.plus:
      return <SvgPlusButton {...props}/>

    case iconTypes.edit:
      return <SvgEditApplicationIcon {...props}/>
    case iconTypes.duplicate:
      return <SvgDuplicate {...props}/>
    case iconTypes.delete:
      return <SvgDeleteIcon {...props}/>
    case iconTypes.home:
      return <SvgHomeIcon {...props}/>
    case iconTypes.info:
      return <SvgInfoIcon {...props}/>
    case iconTypes.locked:
      return <SvgLockedIcon {...props}/>
    case iconTypes.unlocked:
      return <SvgUnlockedIcon {...props}/>
    case iconTypes.pin:
      return <SvgMarkerIcon {...props}/>
    case iconTypes.moveAxis:
      return <SvgMoveAxisIcon {...props}/>
    case iconTypes.move:
      return <SvgMoveIcon {...props}/>
    case iconTypes.optimize:
      return <SvgOptimizeIcon {...props}/>
    case iconTypes.transform:
      return <SvgTransform {...props}/>
    case iconTypes.transformFurniture:
      return <SvgTransformFurnitureIcon {...props}/>
    case iconTypes.rotate:
      return <SvgRotateIcon {...props}/>

    case iconTypes.zoomAll:
      return <SvgZoomAllIcon {...props}/>

    case iconTypes.bathroom:
      return active ? <SvgBathroomIndicator  {...props} fillColor={'#9F8AE5'}/> : <SvgBathroomIndicator {...props} fillColor={'#FFF'}/>
    case iconTypes.cafe:
      return active ? <SvgCafeIndicator  {...props} fillColor={'#22A0DB'}/> : <SvgCafeIndicator {...props} fillColor={'#FFF'}/>
    case iconTypes.custom:
      return active ? <SvgCustomIndicator  {...props} fillColor={'#67D675'}/> : <SvgCustomIndicator {...props} fillColor={'#FFF'}/>
    case iconTypes.door:
      return active ? <SvgDoorIndicator  {...props} fillColor={'#FE5852'}/> : <SvgDoorIndicator {...props} fillColor={'#FFF'}/>
    case iconTypes.stairs:
      return active ? <SvgStaircaseIndicator  {...props} fillColor={'#5BC8E6'}/> : <SvgStaircaseIndicator {...props} fillColor={'#FFF'}/>

    case iconTypes.low:
      return active ? <SvgLowActive {...props} /> : <SvgLow {...props}/>
    case iconTypes.middle:
      return active ? <SvgMidActive {...props} /> : <SvgMid {...props}/>
    case iconTypes.high:
      return active ? <SvgHighActive {...props} /> : <SvgHigh {...props}/>

    case iconTypes.search:
      return <SvgToolboxZoomIcon className={props.className}/>
    case iconTypes.back:
      return <SvgBack className={props.className} {...props}/>
    case iconTypes.next:
      return <SvgNext {...props}/>

    case iconTypes.mirror:
      return active ? <SvgMirrorButton {...props} color={'#000'}/> : <SvgMirrorButton {...props} color={'#999'}/>

    case iconTypes.teams:
      return active ? <SvgHeatmapTeamsActive {...props}/> : <SvgHeatmapTeamsInactive {...props}/>
    case iconTypes.light:
      return active ? <SvgHeatmapLightActive {...props}/> : <SvgHeatmapLightInactive {...props}/>
    case iconTypes.noise:
      return active ? <SvgHeatmapNoiseActive {...props}/> : <SvgHeatmapNoiseInactive {...props}/>
    case iconTypes.blackWhite:
      return active ? <SvgBlackWhiteActive {...props}/> : <SvgBlackWhiteInactive {...props}/>
    case iconTypes.activity:
      return active ? <SvgHeatmapActivityActive {...props}/> : <SvgHeatmapActivityInactive {...props}/>

    case iconTypes.size:
      return <SvgSizeIconActive {...props}/>

    case iconTypes.quality:
      return <SvgQuality {...props} color={defaultPrimaryColor} fillColor={defaultPrimaryColor}/>
    case iconTypes.area:
      return <SvgArea {...props} color={defaultPrimaryColor} fillColor={defaultPrimaryColor}/>
    case iconTypes.heatmap:
      return <SvgHeatmap {...props} color={defaultPrimaryColor} fillColor={defaultPrimaryColor}/>

    case iconTypes.dashboard:
      return <SvgExpand {...props} color={'#393B53'} fillColor={defaultPrimaryColor}/>
    case iconTypes.sidebar:
      return <SvgCollapse {...props} color={'#393B53'} fillColor={defaultPrimaryColor}/>

    case iconTypes.building:
      return <SvgBuildingIcon {...props}/>
    case iconTypes.floor:
      return <SvgFloorIcon {...props}/>

    case iconTypes.openNewTab:
      return <SvgOpenNewTabIcon {...props}/>
    case iconTypes.support:
      return <SvgSupportIcon {...props}/>

    case iconTypes.addUnit:
      return <SvgAddUnit {...props}/>
    case iconTypes.assignItem:
      return <SvgAssignItem {...props}/>
    case iconTypes.editValues:
      return <SvgEditValues {...props}/>
    case iconTypes.suitcase:
      return <SvgSuitcase {...props}/>
    case iconTypes.exportFile:
      return <SvgExportFile {...props}/>
    case iconTypes.preview:
      return <SvgPreview {...props}/>

    case iconTypes.openDemo:
      return <SvgOpenDemoIcon {...props}/>
    case iconTypes.import:
      return <SvgImportIcon {...props}/>
    case iconTypes.createNew:
      return <SvgCreateNewIcon {...props}/>
    case iconTypes.openFolder:
      return <SvgOpenFolderIcon {...props}/>

    case iconTypes.cleaning:
      return <SvgCleaning {...props}/>
    case iconTypes.assistant:
      return <SvgAssistant {...props}/>
    case iconTypes.movingCar:
      return <SvgMovingCar {...props}/>
    case iconTypes.superDepartment:
      return <SvgSuperDepartment {...props}/>
    case iconTypes.department:
      return <SvgDepartment {...props}/>
    case iconTypes.team:
      return <SvgTeam {...props}/>
    case iconTypes.editOutline:
      return <SvgEditOutline {...props}/>
    case iconTypes.rename:
      return <SvgRename {...props}/>
    case iconTypes.addFloor:
      return <SvgAddFloor {...props}/>
    case iconTypes.addBuilding:
      return <SvgAddBuilding {...props}/>

    case iconTypes.togglePositive:
      return <SvgToggleIconPositive {...props}/>
    case iconTypes.toggleNegative:
      return <SvgToggleIconNegative {...props}/>

    case iconTypes.drawSelectActive:
      return <SvgDrawSelectActive {...props}/>
    case iconTypes.drawSelectInactive:
      return <SvgDrawSelectInactive {...props} color={'#001D34'}/>
    case iconTypes.drawPanActive:
      return <SvgDrawPanActive {...props}/>
    case iconTypes.drawPanInactive:
      return <SvgDrawPanInactive {...props} color={'#001D34'}/>
    case iconTypes.drawDeleteActive:
      return <SvgDrawDeleteActive {...props}/>
    case iconTypes.drawDeleteInactive:
      return <SvgDrawDeleteInactive {...props} color={'#001D34'}/>
    case iconTypes.drawScaleActive:
      return <SvgDrawScaleActive {...props}/>
    case iconTypes.drawScaleInactive:
      return <SvgDrawScaleInactive {...props}/>
    case iconTypes.drawOutlineActive:
      return <SvgDrawOutlineActive {...props}/>
    case iconTypes.drawOutlineInactive:
      return <SvgDrawOutlineInactive {...props}/>
    case iconTypes.drawRectangleActive:
      return <SvgDrawRectangleActive {...props}/>
    case iconTypes.drawRectangleInactive:
      return <SvgDrawRectangleInactive {...props}/>
    case iconTypes.drawPolygonActive:
      return <SvgDrawPolygonActive {...props}/>
    case iconTypes.drawPolygonInactive:
      return <SvgDrawPolygonInactive {...props}/>
    case iconTypes.drawDoorActive:
      return <SvgDrawDoorActive {...props}/>
    case iconTypes.drawDoorInactive:
      return <SvgDrawDoorInactive {...props}/>
    case iconTypes.drawWindowActive:
      return <SvgDrawWindowActive {...props}/>
    case iconTypes.drawWindowInactive:
      return <SvgDrawWindowInactive {...props}/>
    case iconTypes.drawPillarActive:
      return <SvgDrawPillarActive {...props}/>
    case iconTypes.drawPillarInactive:
      return <SvgDrawPillarInactive {...props}/>
    case iconTypes.drawWallActive:
      return <SvgDrawWallActive {...props}/>
    case iconTypes.drawWallInactive:
      return <SvgDrawWallInactive {...props}/>
    case iconTypes.drawLinkActive:
      return <SvgDrawLinkActive {...props}/>
    case iconTypes.drawLinkInactive:
      return <SvgDrawLinkInactive {...props}/>
    case iconTypes.drawZoneActive:
      return <SvgDrawZoneActive {...props}/>
    case iconTypes.drawZoneInactive:
      return <SvgDrawZoneInactive {...props}/>
    case iconTypes.drawZoneNew:
      return <DrawZoneNew {...props}/>
    case iconTypes.drawServiceActive:
      return <SvgDrawServiceActive {...props}/>
    case iconTypes.drawServiceNew:
      return <DrawServiceNew {...props}/>
    case iconTypes.drawServiceInactive:
      return <SvgDrawServiceInactive {...props}/>
    case iconTypes.drawInfrastructureActive:
      return <SvgDrawInfrastructureActive {...props}/>
    case iconTypes.drawInfrastructureNew:
      return <DrawInfrastructureNew {...props}/>
    case iconTypes.drawInfrastructureInactive:
      return <SvgDrawInfrastructureInactive {...props}/>
    case iconTypes.drawBlockedActive:
      return <SvgDrawBlockedActive {...props}/>
    case iconTypes.drawBlockedNew:
      return <DrawBlockedNew {...props}/>
    case iconTypes.drawBlockedInactive:
      return <SvgDrawBlockedInactive {...props}/>
    case iconTypes.drawAxesActive:
      return <SvgDrawAxesActive {...props}/>
    case iconTypes.drawAxesInactive:
      return <SvgDrawAxesInactive {...props} color={'#001D34'}/>

    case iconTypes.axesEditNew:
      return <AxesEditNew {...props} color={'#001D34'}/>
    case iconTypes.axesAutoGenerateNew:
      return <AxesAutoGenerateNew {...props} color={'#001D34'}/>
    case iconTypes.axesDeleteNew:
      return <AxesDeleteNew {...props} color={'#001D34'}/>

    case iconTypes.drawAxesAutogenerateActive:
      return <SvgDrawAxesAutoGenerateActive {...props}/>
    case iconTypes.drawAxesAutogenerateInactive:
      return <SvgDrawAxesAutoGenerateInactive {...props} color={'#001D34'}/>
    case iconTypes.drawOuterWall:
      return <DrawOuterWall {...props}/>
    case iconTypes.drawInnerWall:
      return <DrawInnerWall {...props} color={'#001D34'}/>
    case iconTypes.drawDoorNew:
      return <DrawDoorNew {...props}/>
    case iconTypes.drawWindowNew:
      return <DrawWindowNew {...props}/>
    case iconTypes.drawPillarNew:
      return <DrawPillarNew {...props}/>
    case iconTypes.drawSlice:
      return <DrawSlice {...props}/>
    case iconTypes.drawScale:
      return <DrawScale {...props}/>

    case iconTypes.help:
      return <SvgHelpIcon {...props}/>

    case iconTypes.drawNavbarCheck:
      return <SvgDrawNavbarCheck {...props}/>
    case iconTypes.drawNavbar1:
      return focus ? <SvgDrawNavbarNumber1Focus {...props}/> : active ? <SvgDrawNavbarNumber1Active {...props}/> : <SvgDrawNavbarNumber1 {...props}/>
    case iconTypes.drawNavbar2:
      return focus ? <SvgDrawNavbarNumber2Focus {...props}/> : active ? <SvgDrawNavbarNumber2Active {...props}/> : <SvgDrawNavbarNumber2 {...props}/>
    case iconTypes.drawNavbar3:
      return focus ? <SvgDrawNavbarNumber3Focus {...props}/> : active ? <SvgDrawNavbarNumber3Active {...props}/> : <SvgDrawNavbarNumber3 {...props}/>
    case iconTypes.drawNavbar4:
      return focus ? <SvgDrawNavbarNumber4Focus {...props}/> : active ? <SvgDrawNavbarNumber4Active {...props}/> : <SvgDrawNavbarNumber4 {...props}/>
    case iconTypes.drawNavbar5:
      return focus ? <SvgDrawNavbarNumber5Focus {...props}/> : active ? <SvgDrawNavbarNumber5Active {...props}/> : <SvgDrawNavbarNumber5 {...props}/>
    case iconTypes.drawNavbar6:
      return focus ? <SvgDrawNavbarNumber6Focus {...props}/> : active ? <SvgDrawNavbarNumber6Active {...props}/> : <SvgDrawNavbarNumber6 {...props}/>
    case iconTypes.drawNavbar7:
      return focus ? <SvgDrawNavbarNumber7Focus {...props}/> : active ? <SvgDrawNavbarNumber7Active {...props}/> : <SvgDrawNavbarNumber7 {...props}/>

    case iconTypes.focussed:
      return active ? <ArchTargetIconActive {...props}/> : <ArchTargetIcon {...props}/>
    case iconTypes.focussedUltra:
      return active ? <ArchTargetMultiIconActive {...props}/> : <ArchTargetMultiIcon {...props}/>
    case iconTypes.collaborate:
      return active ? <CollaborateIconActive {...props}/> : <CollaborateIcon {...props}/>
    case iconTypes.agile:
      return active ? <AgileIconActive {...props}/> : <AgileIcon {...props}/>
    case iconTypes.socialise:
      return active ? <SocialiseIconActive {...props}/> : <SocialiseIcon {...props}/>

    case iconTypes.screenshot:
      return <SvgScreenshot {...props}/>

    case iconTypes.documentation:
      return <SvgDocumentation {...props}/>
    case iconTypes.website:
      return <SvgWebsite {...props}/>
    case iconTypes.eLearning:
      return <SvgELearning {...props}/>

    case iconTypes.interfererCheck:
      return <SvgInterfererCheck {...props}/>

    default:
      //TODO: only to make sure we see missing icons
      return <SvgCloseIcon {...props} color={'#bc0005'}/>
  }
}

export default Icon