import React, { Component } from 'react'
import BuilderNavBar from '../../../common/components/builder/BuilderNavBar'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { discardNewPattern, fetchPattern, fetchPatternList, renamePattern, resetDirtyFlag, saveNewPattern, updatePattern } from '../../actions/actions'

class PatternNavigation extends Component {

  componentDidMount () {
    if (this.props.patterns.length < 1) {
      this.props.fetchPatternList(this.props.projectId)
    }
  }

  render () {

    // saveChanges & discardChanges
    let saveChanges
    let discardChanges

    const { projectId, currentPatternId } = this.props

    if (this.props.patternPlan.id) {
      saveChanges = () => this.handleSaveChanges()
      discardChanges = () => this.handleDiscardChanges(projectId, currentPatternId)
    } else {
      saveChanges = () => this.props.saveNewPattern(projectId, this.props.patternPlan)
      discardChanges = () => this.props.discardNewPattern()
    }

    return (
      <BuilderNavBar navItems={this.props.patterns}
                     current={this.props.patternPlan}
                     onNavigation={(patternId) => this.handleNavigation(patternId)}
                     backToOverview={() => this.handleBackToOverview()}
                     dirtyFlag={this.props.dirtyFlag}
                     resetDirtyFlag={this.props.resetDirtyFlag}
                     renameTitle={(pattern) => this.handleRenameTitle(pattern)}
                     discardChanges={discardChanges}
                     saveChanges={saveChanges}
                     dialogMessage={['Your Pattern have been modified.', 'Please confirm or discard your changes.', 'To continue editing close this window.']}
                     undoApplicationBuilder={() => {this.props.undoPatternBuilder()}}
                     redoApplicationBuilder={() => {this.props.redoPatternBuilder()}}
                     undoAvailable={this.props.undoAvailable}
                     redoAvailable={this.props.redoAvailable}
                     discardUndo={this.props.discardUndo}
      />
    )
  }

  handleNavigation (patternId) {
    this.props.push('./' + patternId + this.props.location.search)
  }

  handleDiscardChanges (projectId, patternId) {
    this.props.fetchPattern(projectId, patternId)
  }

  handleSaveChanges () {
    this.props.updatePattern(this.props.projectId, this.props.patternPlan)
  }


  handleBackToOverview () {
    this.props.push('../patterns' + this.props.location.search)
  }

  handleRenameTitle (pattern) {
    this.props.renamePattern(pattern.id, pattern.displayName)
  }
}

let mapStateToProps = (state) => {
  return {
    location: state.router.location,
    patterns: state.pattern.patterns,
    dirtyFlag: state.pattern.dirtyFlag,
    patternPlan: state.pattern.patternPlan,
  }
}

let mapDispatchToProps = {
  fetchPatternList: fetchPatternList,
  fetchPattern: fetchPattern,
  resetDirtyFlag: resetDirtyFlag,
  saveNewPattern: saveNewPattern,
  updatePattern: updatePattern,
  renamePattern: renamePattern,
  discardNewPattern: discardNewPattern,
  push: push,
}

export default connect(mapStateToProps, mapDispatchToProps)(PatternNavigation)