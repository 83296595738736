import React, { Component } from 'react'
import { connect } from 'react-redux'

import DrawingTool from './DrawingTool'
import DrawingToolMenuBar from './DrawingToolMenuBar'
import DrawingToolPlaceholder from './common/DrawingToolPlaceholder'
import DrawingHelp from './DrawingHelp'

import { getSelectedBuilding, getSelectedFloor } from '../../floorplan/reducers/buildings'

import { tools } from '../constants/Tools'

import './DrawingToolContent.scss'
import { getCurrentGeometryObjects } from '../reducer/drawingToolReducer'

export const endings = {
  dxf: '.dxf',
  ifc: '.ifc',
  jpg: '.jpg',
  jpeg: '.jpeg',
  png: '.png',
  pdf: '.pdf',
}

class DrawingToolContent extends Component {

  showPlaceholder () {
    const {currentTool, image, currentGeometries} = this.props
    return !image.base64Image && [tools.select, tools.pan].includes(currentTool) && !currentGeometries.geometries.length
  }

  renderContent () {
    const {currentBuilding} = this.props

    if (!currentBuilding || !currentBuilding.floors.length) {
      return null
    } else if (this.showPlaceholder()) {
      return <DrawingToolPlaceholder/>
    } else {
      return <DrawingTool/>
    }
  }

  renderDrawingHelp () {
      //console.log("helpProps:")
      //console.log(this.props)

      return (this.props.drawingHelp!==null
        ? <DrawingHelp 	onboarding={this.props.onboarding}
                        drawingHelp= {this.props.drawingHelp}

                />
          : null)


  }

  render () {
    return (
      <div className="edit-outline-content">
        <div className={'content-sidebar'}>
          <DrawingToolMenuBar onboarding={this.props.onboarding}/>
          {this.renderDrawingHelp()}

          <div className={'editor' + (this.props.onboarding ? ' onboarding' : '')}>
            {this.renderContent()}
          </div>

        </div>
      </div>
    )
  }
}

let mapStateToProps = (state) => {
  return {
    image: state.drawingTool.image,
    currentTool: state.drawingTool.currentTool,
    currentGeometries: getCurrentGeometryObjects(state),

    currentBuilding: getSelectedBuilding(state),
    currentFloor: getSelectedFloor(state),
    drawingHelp: state.drawingTool.drawingHelp,
  }
}

export default connect(mapStateToProps)(DrawingToolContent)