import { push } from 'connected-react-router'
import { FULFILLED, PENDING, REJECTED, viewNames } from '../../globalConstants'
import { httpClient } from '../../httpClient'
import { activateProject } from '../project-activation/project-activation-action'
import { setDataLoaded } from '../dataLoader/dataLoader-actions'
import { fetchFrontendLocation } from '../frontendLocation/frontend-location-action'

const CREATE_PROJECT = 'CREATE_PROJECT'
const UPDATE_PROJECT = 'UPDATE_PROJECT'
const FETCH_PROJECT = 'FETCH_PROJECT'
const FETCH_USER_PROJECTS_LIST = 'FETCH_USER_PROJECTS_LIST'
const SAVE_USER_PROJECT = 'SAVE_USER_PROJECT'
const DELETE_PROJECT = 'DELETE_PROJECT'

export const projectsActionTypes = {

  CREATE_PROJECT: CREATE_PROJECT,
  CREATE_PROJECT_PENDING: CREATE_PROJECT + PENDING,
  CREATE_PROJECT_FULFILLED: CREATE_PROJECT + FULFILLED,
  CREATE_PROJECT_REJECTED: CREATE_PROJECT + REJECTED,

  UPDATE_PROJECT: UPDATE_PROJECT,
  UPDATE_PROJECT_PENDING: UPDATE_PROJECT + PENDING,
  UPDATE_PROJECT_FULFILLED: UPDATE_PROJECT + FULFILLED,
  UPDATE_PROJECT_REJECTED: UPDATE_PROJECT + REJECTED,

  FETCH_PROJECT: FETCH_PROJECT,
  FETCH_PROJECT_PENDING: FETCH_PROJECT + PENDING,
  FETCH_PROJECT_FULFILLED: FETCH_PROJECT + FULFILLED,
  FETCH_PROJECT_REJECTED: FETCH_PROJECT + REJECTED,

  FETCH_USER_PROJECTS_LIST: FETCH_USER_PROJECTS_LIST,
  FETCH_USER_PROJECTS_LIST_PENDING: FETCH_USER_PROJECTS_LIST + PENDING,
  FETCH_USER_PROJECTS_LIST_FULFILLED: FETCH_USER_PROJECTS_LIST + FULFILLED,
  FETCH_USER_PROJECTS_LIST_REJECTED: FETCH_USER_PROJECTS_LIST + REJECTED,

  DELETE_PROJECT: DELETE_PROJECT,
  DELETE_PROJECT_PENDING: DELETE_PROJECT + PENDING,
  DELETE_PROJECT_FULFILLED: DELETE_PROJECT + FULFILLED,
  DELETE_PROJECT_REJECTED: DELETE_PROJECT + REJECTED,

  SAVE_USER_PROJECT: SAVE_USER_PROJECT,

}

export function activateAndLoadProject (projectId) {
  return (dispatch) => {
    dispatch(setDataLoaded(false))
    dispatch(activateProject(projectId))
      .then(() => {
        dispatch(fetchProject(projectId))
          .then(({ value }) => {
            const projectId = value.data.id
            dispatch(fetchFrontendLocation(projectId))
              .then(({ value }) => {
                const globalLocation = value.data.globalLocation
                const onboardingLocation = value.data.onboardingLocation

                dispatch(push(`/projects/${projectId}` + (globalLocation === 'ONBOARDING' ? '/' + viewNames.ONBOARDING + '/' + onboardingLocation.toLowerCase() : '')))
              })
          })

      })
  }
}

export function loadProject (projectId) {
  return (dispatch) => {
    dispatch(fetchProject(projectId))
      .then(({ value }) => {
        dispatch(push(`/projects/${value.data.id}`))
      })
  }
}

export function createDemoProject (demoIndex = 0) {

  return (dispatch) => {
    dispatch(setDataLoaded(false))
    dispatch(createProject({ id: 'demo', demoIndex: demoIndex }))
      .then(({ value }) => {
        dispatch(push(`/projects/${value.data.id}`))
      })
  }
}

export function createNewProject (advancedSettings = false) {
  return (dispatch) => {
    dispatch(setDataLoaded(false))
    dispatch(createProject({ advancedSettings: advancedSettings }))
      .then(({ value }) => {
        dispatch(push(`/projects/${value.data.id}`))
      })
  }
}

export function uploadProjectFile (file) {
  return (dispatch) => {
    dispatch(setDataLoaded(false))
    dispatch(uploadProject(file))
      .then(({ value }) => {
        dispatch(push(`/projects/${value.data.id}`))
      })

  }
}

export function updateProject (projectId, project) {
  return {
    type: projectsActionTypes.UPDATE_PROJECT,
    payload: httpClient.put(`/api/projects/${projectId}`, project),
  }
}

export function uploadProject (file) {

  let form = new FormData()
  form.append('file', file)

  return {
    type: projectsActionTypes.CREATE_PROJECT,
    payload: httpClient.post('/api/projects', form),
  }
}

export function createProject (props = {}) {
  const project = {
    id: props.id,
    demoIndex: props.demoIndex,
    advancedSettings: props.advancedSettings,
    creationDate: Date.now(),
  }

  return {
    type: projectsActionTypes.CREATE_PROJECT,
    payload: httpClient.post('/api/projects', project),
  }
}

export function fetchProject (projectId) {
  return {
    type: projectsActionTypes.FETCH_PROJECT,
    payload: httpClient.get(`/api/projects/${projectId}`),
  }
}

export function deleteProject (projectId) {
  return {
    type: projectsActionTypes.DELETE_PROJECT,
    payload: httpClient.delete(`/api/projects/${projectId}`),
  }
}

export function deleteProjectAndFetchProjects (projectId) {
  return (dispatch) => {
    dispatch(deleteProject(projectId))
      .then(() => {
        dispatch(fetchUserProjectsList())
      })
  }
}

export function fetchUserProjectsList () {
  return {
    type: projectsActionTypes.FETCH_USER_PROJECTS_LIST,
    payload: httpClient.get('/api/projects'),
  }
}

export function saveUserProject (projectId, projectName) {
  const data = {
    id: projectId,
    name: projectName,
  }

  return {
    type: projectsActionTypes.SAVE_USER_PROJECT,
    payload: httpClient.post(`/api/projects/${projectId}/disk`, data),
  }
}
