import React, { Component, Fragment } from 'react'
import { Route, Switch, withRouter } from 'react-router'

import ApplicationBuilderOverview from './overview/ApplicationBuilderOverview'

import ApplicationBuilderEditor from './editor/ApplicationBuilderEditor'
import { viewNames } from '../../common/globalConstants'

class ApplicationBuilderContent extends Component {

  render () {

    return (
      <Fragment>
        <Switch>
          <Route path={'/projects/:projectId/' + viewNames.SETS + '/new'} component={ApplicationBuilderEditor}/>
          <Route path={'/projects/:projectId/' + viewNames.SETS + '/:applicationId'} component={ApplicationBuilderEditor}/>
          <Route path={'/projects/:projectId/' + viewNames.SETS} component={ApplicationBuilderOverview}/>
        </Switch>
      </Fragment>
    )
  }
}

export default withRouter(ApplicationBuilderContent)