import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { fetchProjectData } from '../floorplan/actions/actions'

import ApplicationBuilderContent from './components/ApplicationBuilderContent'

class ApplicationBuilderApp extends Component {

  componentDidMount () {
    if (!this.props.dataLoaded) {
      this.props.fetchProjectData(this.props.match.params.projectId)
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.advancedSettings === undefined && this.props.advancedSettings === false) {
      this.props.push('./floorplan')
    }
  }

  render () {
    const projectId = this.props.match.params.projectId

    return (
      <div id={'application-builder'}>
        <ApplicationBuilderContent projectId={projectId}/>
      </div>
    )
  }
}

let mapStateToProps = (state) => {
  return {
    advancedSettings: state.settings.appSettings.advancedSettings,

    dataLoaded: state.dataLoaded.dataLoaded,
  }
}

let mapDispatchToProps = {
  fetchProjectData: fetchProjectData,

  push: push,
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationBuilderApp)