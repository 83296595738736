import React, { useEffect } from 'react'
import { useExpanded, useTable } from 'react-table'
import './Table.scss'
import { Scrollbars } from 'react-custom-scrollbars'

function Table (props) {

  const { columns, data, getSubRows, defaultColumn, onCellValueChange, getRowProps = () => ({}), getExpandedRows } = props

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    flatRows,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      getSubRows,
      onCellValueChange,
    },
    useExpanded, // Use the useExpanded plugin hook
  )

  useEffect(() => {

    if (getExpandedRows) {

      const expandedRows = getExpandedRows(flatRows) || []

      expandedRows.forEach((row) => {
        row.toggleExpanded(true)
      })
    }
  })

  const styleClasses = props.className ? props.className : ''

  return (
    <div {...getTableProps()} className={'ccd-table ' + styleClasses}>
      <div className='ccd-table-header'>
        <table border="0" cellPadding="0" cellSpacing="0">
          <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()} className={column.className}>
                  <span>{column.render('Header')}</span>
                </th>
              ))}
            </tr>
          ))}
          </thead>
        </table>
      </div>
      <div className='ccd-table-body'>
        <Scrollbars autoHide>
          <table border="0" cellPadding="0" cellSpacing="0">
            <tbody {...getTableBodyProps()}>
            {rows.map(
              (row, i) =>
                prepareRow(row) || (
                  <tr {...row.getRowProps(getRowProps(row))}>
                    {row.cells.map(cell => {
                      const isVisible = typeof cell.column.showCell === 'function' ? cell.column.showCell({ row, cell }) : true
                      return (
                        <td {...cell.getCellProps()} className={cell.column.className}>{isVisible ? cell.render('Cell') : null}</td>
                      )
                    })}
                  </tr>
                ),
            )}
            </tbody>
          </table>
        </Scrollbars>
      </div>
    </div>
  )
}

export default Table