import React, { Component } from 'react'

import { connect } from 'react-redux'
import { getCurrentProjectId } from '../common/utils'

import { fetchProjectData } from '../floorplan/actions/actions'
import { PeopleContent } from './PeopleContent'

class PeopleApp extends Component {

  componentDidMount () {
    if (!this.props.dataLoaded) {
      this.props.fetchProjectData(this.props.match.params.projectId)
    }
  }

  render () {
    return (
      <PeopleContent/>
    )
  }
}

let mapStateToProps = (state) => {
  return {
    dataLoaded: state.dataLoaded.dataLoaded,

    settings: state.settings.appSettings,
    projectId: getCurrentProjectId(state),
  }
}

let mapDispatchToProps = {
  fetchProjectData: fetchProjectData,
}

export default connect(mapStateToProps, mapDispatchToProps)(PeopleApp)