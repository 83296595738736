import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Dialog, { DialogButtonGroup, DialogContent } from '../../common/components/Dialog'
import Button from '../../common/components/Button'
import FileInput from '../../common/components/FileInput'

class UploadDialog extends Component {

  constructor (props) {
    super(props)
    this.state = {
      file: null,
    }
  }

  setFile = file => {
    this.setState({ file: file })
  }

  upload = () => {
    this.close()
    this.props.upload(this.state.file, this.props.replace)
  }

  close = () => {
    this.props.onClose()
  }

  render () {

    let acceptedEndings = this.props.fileEndings ? this.props.fileEndings : ''

    return (
      <Dialog onCancel={this.close}
              title={this.props.title}
              className={'uploader'}>
        <DialogContent>
          <FileInput name="Upload file" accept={acceptedEndings} onChange={file => this.setFile(file)}/>
        </DialogContent>
        <DialogButtonGroup>
          <Button onClick={this.close}>Cancel</Button>
          <Button onClick={this.upload} disabled={!this.state.file} variant={'contained'}>Confirm</Button>
        </DialogButtonGroup>
      </Dialog>
    )
  }
}

UploadDialog.propTypes = {
  upload: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  fileEndings: PropTypes.string,
}

export default UploadDialog