import React, { Component } from 'react'
import { connect } from 'react-redux'
import { deleteProject } from '../../../common/redux/projects/projects-actions'
import { push } from 'connected-react-router'
import { getCurrentProjectId } from '../../../common/utils'
import DeletionDialog from '../../../common/components/DeletionDialog'

class DeleteProjectsDialog extends Component {

  render () {
    return (
      <DeletionDialog className={'delete-project-dialog'}
                      title={'Delete Project'}
                      text={'Are you sure you want to delete the current project?'}
                      onConfirm={this.delete}
                      onCancel={this.close}/>
    )
  }

  delete = () => {
    this.props.push(`/`)
    this.props.deleteProject(this.props.projectId)
    this.close()
  }

  close = () => {
    this.props.onClose()
  }

}

const mapStateToProps = (state) => {
  return {
    projectId: getCurrentProjectId(state),
  }
}

export default connect(
  mapStateToProps,
  { deleteProject, push },
)(DeleteProjectsDialog)
