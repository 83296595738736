import React, { Component } from 'react'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'

import Text from '../../../common/components/Text'
import { IconWrapper } from '../../../common/components/IconWrapper'

import './Footer.scss'
import { updateFrontendLocation } from '../../../common/redux/frontendLocation/frontend-location-action'
import { viewNames } from '../../../common/globalConstants'

class Footer extends Component {
  render () {
    const { footerText, footerLinkText, footerLinkIcon, footerLink } = this.props

    return (
      <div className={'step-content-footer'}>
        <div className={'step-content-footer-container'}>
          {footerText && footerText.length ? <Text>{footerText}</Text> : null}
          <div className={'step-content-footer-link'} onClick={() => this.handleOpenLink(footerLink)}>
            <Text>{footerLinkText}</Text>
            {footerLinkIcon ? <IconWrapper name={footerLinkIcon}/> : null}
          </div>
        </div>
      </div>
    )
  }

  handleOpenLink (link) {
    this.props.push(link)

    if (link.includes(viewNames.FLOORPLAN)) {
      this.props.updateGlobalLocation('FLOORPLAN', 'TESTFIT')
    }
  }
}

let mapDispatchToProps = {
  updateGlobalLocation: updateFrontendLocation,

  push: push,
}

export default connect(null, mapDispatchToProps)(Footer)