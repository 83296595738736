import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Field, Form, Formik } from 'formik'
import { Scrollbars } from 'react-custom-scrollbars'
import AutoSave from '../AutoSave'

import { selectedElementType } from '../../../applicationbuilder/reducers/uiState'
import { furnitureAnchorSides } from '../../builderConstants'
import MenuSection from '../MenuSection'
import Button from '../Button'
import Text from '../Text'
import IconButton from '../IconButton'
import NumberInput from '../NumberInput'

import './BuilderFurnitureSettings.scss'
import { iconTypes } from '../icons/IconFactory'
import { withTranslation } from 'react-i18next'

class BuilderFurnitureSettings extends Component {

  render () {
    const {t} = this.props

    let unitOfMeasure = 'm'
    let furnName, furnSize, furnDesc
    let furnitureItem

    if (this.props.furnitureList && this.props.furnitureList.length > 0) {
      furnitureItem = this.getSelectedFurniture(this.props.selectedFurnitureId)

      if (furnitureItem) {
        furnName = furnitureItem.displayName
        if (furnitureItem.ofmlType && furnitureItem.ofmlType.productName) {
          furnDesc = furnitureItem.ofmlType.productName
        } else {
          furnDesc = furnitureItem.description
        }

        furnSize = this.getSizeOfFurniture(furnitureItem.type)
      } else {
        // this.props.onDeleteFurniture(null) //hotfix just to hide furnSettings
      }
    }

    return (
      <div className={'settings-furniture'}>
        <Scrollbars autoHide>
          <MenuSection className={'furniture-settings-upper-section'}>
            <MenuSection title={t('information')} icon={iconTypes.info}>
              <div className={'furniture-settings-section-1'}>
                {furnName && <Text outerClassName={'furniture-settings-text'} component={'h2'} multiline>{furnName}</Text>}

                {furnDesc && <Text outerClassName={'furniture-settings-text'} component={'label'} multiline>{furnDesc}</Text>}
                {furnSize && <Text outerClassName={'furniture-settings-text'} component={'label'}>{furnSize.width + ' x ' + furnSize.height + ' ' + unitOfMeasure}</Text>}
              </div>
            </MenuSection>

            <MenuSection className={'furniture-settings-section-2'} title={t('transformation')} icon={iconTypes.transformFurniture}>
              <Formik initialValues={furnitureItem} enableReinitialize onSubmit={(value) => this.rotateFurniture(furnitureItem, value.rotationRad)}>
                <Form>
                  <AutoSave delay={0} onSave={(value) => this.rotateFurniture(furnitureItem, value.rotationRad)}/>
                  <Field label={'Rotate'} name={'rotationRad'} component={NumberInput} step={1} digits={0} minimum={-10000}/>
                </Form>
              </Formik>
              <IconButton className={'rotate-button'} icon={iconTypes.rotate} type={'button'} onClick={() => this.handleAnchorButtonClicked(furnitureItem)}/>
            </MenuSection>
          </MenuSection>
        </Scrollbars>

        <MenuSection className={'furniture-settings-lower-section'}>
          <Button icon={iconTypes.delete} variant={'contained'}
                  className={'furniture-delete-button'} onClick={() => this.handleDeleteButtonClicked(furnitureItem)}>
            {t('delete_item')}
          </Button>
        </MenuSection>
      </div>
    )
  }

  getSelectedFurniture (id) {
    return this.props.furnitureList.find((furniture) =>
      furniture.uuid === id)
  }

  rotateFurniture (furniture, value) {
    if (!furniture || !this.props.onRotateFurniture)
      return

    this.props.storeApplicationBuilderUndo()
    this.props.getUndoAvailable()
    this.props.onRotateFurniture(furniture.uuid, value % 360)
  }

  handleAnchorButtonClicked (furniture) {
    if (!furniture || !this.props.onFurnitureChangeAnchorSide)
      return

    this.props.storeApplicationBuilderUndo()
    this.props.getUndoAvailable()
    let index = furnitureAnchorSides.indexOf(furniture.mySide)
    index = ++index % furnitureAnchorSides.length

    this.props.onFurnitureChangeAnchorSide(furniture.uuid, furnitureAnchorSides[index])
  }

  handleDeleteButtonClicked (furniture) {
    if (!furniture || !this.props.onDeleteFurniture)
      return

    this.props.storeApplicationBuilderUndo()
    this.props.onDeleteFurniture(furniture.uuid)
    this.props.getUndoAvailable()

    if (this.props.changeSelectedElement)
      this.props.changeSelectedElement(selectedElementType.FLOOR, {})
  }

  getSizeOfFurniture (typeId) {
    let size, furn
    furn = this.props.furnitureLibrary.find((furn) => furn.id === typeId)

    if (furn)
      size = furn.size
    else
      size = { width: 0, height: 0 }

    return size
  }
}

let mapStateToProps = (state) => {
  return {
    furnitureLibrary: state.furniture.furnitureLibrary,
  }
}

BuilderFurnitureSettings.propTypes = {
  furnitureList: PropTypes.array.isRequired,
  selectedFurnitureId: PropTypes.string.isRequired,
  onRotateFurniture: PropTypes.func,
  onFurnitureChangeAnchorSide: PropTypes.func,
  onDeleteFurniture: PropTypes.func,
  changeSelectedElement: PropTypes.func,
  getApp: PropTypes.func,
  getUndoAvailable: PropTypes.func,
  storeApplicationBuilderUndo: PropTypes.func,

}

export default withTranslation() (connect(mapStateToProps)(BuilderFurnitureSettings))
