import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import haworthLogo from '../../assets/images/svg/haworth-logo.svg'
import haworthLogoWhite from '../../assets/images/svg/haworth-logo-white.svg'

import Text from '../../common/components/Text'

import './TitleBar.scss'
import { clearProject } from '../../floorplan/actions/actions'

class TitleBar extends Component {
  render () {
    return (
      <div className={'title-bar' + (this.props.advancedSettings ? ' dark-mode' : '')}>
        <div className='application-header-content'>
          <div>
            <img className="application-header-content-logo" src={this.props.advancedSettings ? haworthLogoWhite : haworthLogo} alt="HAWORTH" onClick={() => this.handleHaworthClicked()}/>
          </div>

          <div className={'application-header-version-section'}>
            <Text outerClassName={'version-title' + (this.props.advancedSettings ? ' dark-mode' : '')} component={'runningTitle'}>beta</Text>
            <Text outerClassName={'version-number' + (this.props.advancedSettings ? ' dark-mode' : '')}>version 0.4.1</Text>
          </div>

        </div>
      </div>
    )
  }

  handleHaworthClicked () {
    this.props.clearProject()

    this.props.push('/projects')
  }
}

let mapStateToProps = (state) => {
  return {
    advancedSettings: state.settings.appSettings.advancedSettings,
  }
}

let mapDispatchToProps = {

  push: push,

  clearProject: clearProject,
}

export default connect(mapStateToProps, mapDispatchToProps)(TitleBar)
