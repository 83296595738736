import React, { Component, Fragment } from 'react'
import { Redirect } from 'react-router-dom'
import { Route, Switch } from 'react-router'

import { viewNames, viewNamesOnBoarding } from '../common/globalConstants'
import Step from './components/Step'

import './OnboardingApp.scss'
import { connect } from 'react-redux'
import { fetchProjectData } from '../floorplan/actions/actions'

class OnboardingApp extends Component {

  componentDidMount () {
    if (!this.props.dataLoaded) {
      this.props.fetchProjectData(this.props.match.params.projectId)
    }
  }

  render () {
    console.log("ONBOARDING!!")
    console.log(this.props)
    return (
      <div id={'onboarding-container'}>
        <Fragment>
          <Switch>
            <Route path={'/projects/:projectId/' + viewNames.ONBOARDING + '/' + viewNamesOnBoarding.START}
                   component={() => <Step step={viewNamesOnBoarding.START}/>}/>
            <Route path={'/projects/:projectId/' + viewNames.ONBOARDING + '/' + viewNamesOnBoarding.STEP_1}
                   component={() => <Step step={viewNamesOnBoarding.STEP_1}/>}/>
            <Route path={'/projects/:projectId/' + viewNames.ONBOARDING + '/' + viewNamesOnBoarding.STEP_2}
                   component={() => <Step step={viewNamesOnBoarding.STEP_2}/>}/>
            <Route path={'/projects/:projectId/' + viewNames.ONBOARDING + '/' + viewNamesOnBoarding.STEP_3}
                   component={() => <Step step={viewNamesOnBoarding.STEP_3}/>}/>
            <Route path={'/projects/:projectId/' + viewNames.ONBOARDING + '/' + viewNamesOnBoarding.PREVIEW}
                   component={() => <Step step={viewNamesOnBoarding.PREVIEW}/>}/>
            <Route path={'/projects/:projectId/' + viewNames.ONBOARDING + '/' + viewNamesOnBoarding.TESTFIT}
                   component={() => <Step step={viewNamesOnBoarding.TESTFIT}/>}/>

            <Redirect to={'/projects/:projectId/' + viewNames.ONBOARDING + '/' + viewNamesOnBoarding.START}/>
          </Switch>
        </Fragment>

      </div>
    )
  }
}

let mapStateToProps = (state) => {
  //console.log("STATE:")
  //console.log(state)
  return {
    dataLoaded: state.dataLoaded.dataLoaded,
  }
}

let mapDispatchToProps = {
  fetchProjectData: fetchProjectData,
}

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingApp)