import { setDataLoaded } from '../../common/redux/dataLoader/dataLoader-actions'
import { push } from 'connected-react-router'
import { createProject } from '../../common/redux/projects/projects-actions'

export function startOnBoarding (advancedSettings = false) {
  return (dispatch) => {
    dispatch(setDataLoaded(false))
    dispatch(createProject({ advancedSettings: advancedSettings }))
      .then(({ value }) => {
        dispatch(value.data ? push(`/projects/${value.data.id}/onBoarding`) : () => {})
      })
  }
}
