import React from 'react'
import Collapsible from 'react-collapsible'
import MenuSection from './MenuSection'
import Icon, { iconTypes } from './icons/IconFactory'
import './CollapsibleMenuSection.scss'
import Text from './Text'

function Trigger ({ title, icon }) {
  return (
    <div className={'trigger'}>
      <div className={'trigger-title'}>
        {icon && <Icon className='trigger-icon' name={icon}/>}
        <Text outerClassName={'trigger-title-text-wrapper'} className={'trigger-title-text'}>{title}</Text>
      </div>
      <Icon className='trigger-arrow' name={iconTypes.arrowSmall}/>

    </div>
  )
}

export default function CollapsibleMenuSection ({ children, Component, className, icon, title, disabled, open, lazyRender }) {

  const styleClass = className ? ' ' + className : ''

  return (
    <Collapsible className={'collapsible-menu-section' + styleClass}
                 openedClassName={'collapsible-menu-section open' + styleClass}
                 triggerClassName={'collapsible-menu-section-trigger-container'}
                 triggerOpenedClassName={'collapsible-menu-section-trigger-container open'}
                 contentOuterClassName={'collapsible-menu-section-content-container'}
                 contentInnerClassName={'collapsible-menu-section-content'}
                 triggerDisabled={!!disabled}
                 triggerTagName={'div'}
                 transitionTime={200}
                 trigger={<Trigger icon={icon} title={title}/>}
                 lazyRender={lazyRender}
                 open={open}>
      <MenuSection>
        {Component ? <Component/> : children}
      </MenuSection>
    </Collapsible>
  )
}