import { PreviewAxis } from '../helper/PreviewAxis'
import { disable } from '../helper/PreviewAxis'
import { SelectionArea } from '../geometries/SelectionArea'
import { representationTypes } from '../config/RepresentationTypes'
import { SelectionBoundingBox } from '../geometries/SelectionBoundingBox'
import { isVerticesContainsAllVertices, getOrtoProjectionOnSegment, getDistanceToEdge, getOrtoProjectionOnLine } from '../helper/GeometryHelper'
import { findSpaceAtPos, findAxes, getStartAndEndPosition, edgeToJSON, edgeAndVerticesToJSON } from '../helper/AxesHelper'

export class SelectController {
  #selectionArea
  #selectionBoundingBox
  #draggedSelectionArea = false
  startDragPosition

  previewAxis=null
  previewPositions=[]
  originalAxis=[]

  constructor (floorPlanerController, selectableRepresentationTypes = []) {
    this.floorPlanerController = floorPlanerController
    this.selectableRepresentationTypes = selectableRepresentationTypes
    this.#selectionArea = new SelectionArea(floorPlanerController.scene)
    this.#selectionBoundingBox = new SelectionBoundingBox(floorPlanerController.scene)
  }

  onLeftMouseDown (event, currentGeometries) {
    console.log("left mouse down")
    let startDrag = false
    console.log("visible: "+this.#selectionBoundingBox.visible)

    //let mode=this.floorPlanerController.oldTool
    let mode=this.floorPlanerController.toolGroup
    console.log("MODE: "+mode)
    if(mode==="axes"){
      this.onLeftMouseDownAxis (event, currentGeometries)
    }

    else if (this.#selectionBoundingBox.visible) {
      console.log("visible")
      const intersection = this.floorPlanerController.raycaster.intersectObject(this.#selectionBoundingBox)
      console.log("intersection: "+intersection)
      if (intersection.length > 0 && this.#selectionBoundingBox.getDraggedGeometries()) {
        console.log("startDrag:"+intersection.length);
        console.log("currentGeometries")
        console.log(currentGeometries)
        this.startDragGeometries(currentGeometries)

        startDrag = true
      }
    }else{
      if (!startDrag) {
        this.startSelectionArea()
      }
      console.log("nothing")
    }
  }


  onLeftMouseDownAxis (event, currentGeometries){
    console.log("leftMouseDownAxis")
    let position = this.floorPlanerController.currentPosition
    let spaceGeometries = this.floorPlanerController.getGeometriesByRepresentationType(representationTypes.space)
    console.log("SelectController Down: " + event + " n:" + spaceGeometries.length)
    let axesController=this.floorPlanerController.representationController.get(representationTypes.axis)
    this.previewAxis=this.floorPlanerController.previewAxis

    console.log("AX:")
    console.log(axesController)
    console.log(axesController.edgeGroup)
    console.log("nAxes: "+axesController.edgeGroup.children.length)
    let space = findSpaceAtPos(spaceGeometries, position)
    console.log("space: "+space)
    console.log("OA: "+this.originalAxis.length)
    if(this.originalAxis.length>0){
      console.log("previewAxisto:")
      //console.log(this.previewAxis.edge.getVertices().length)
      console.log(this.previewAxis.edge.getVertices())
      console.log(this.previewPositions)
      this.selectedAxisToBackend(space,this.previewAxis.edge,this.previewPositions)

    }
    else{
      let selectedAxis=[] //
      if(space!=null) {
        let axes = findAxes(space, axesController.edgeGroup)
        let nAxes = axes.length

        let closestAxisStart = null
        let closestAxisEnd = null
        let closestDistance = 1.0
        this.originalAxis = []

        console.log("space found by select. nAxes = " + nAxes)
        for (let iAxis = 0; iAxis < nAxes; iAxis++) {
          let axis = axes[iAxis]
          console.log("space" + iAxis + ":")
          console.log(axis)

          let se = getStartAndEndPosition(axis)
          let start = se[0]
          let end = se[1]
          console.log("position: " + position.x + ":" + position.y)
          let projection = getOrtoProjectionOnSegment(position, start, end)

          let distance = getDistanceToEdge(position, start, end)
          console.log("pr: " + projection + "  distance: " + distance)
          if (distance != null) {
            if (distance < closestDistance) {
              closestAxisStart = start
              closestAxisEnd = end
              closestDistance = distance
              this.previewPositions=this.previewAxis.updateAxis(position, start, end)

              this.originalAxis[0] = start
              this.originalAxis[1] = end

              console.log("previewVertices")
              console.log( this.previewAxis.edge.getVertices())
              console.log( this.previewPositions )
              //console.log(previeAxis.)
              //console.log("active: "+previewAxis.active)
              console.log("closestDistance: " + closestDistance)
            }
          }
        }
      }
    }
  }

  startSelectionArea () {
    console.log("startSelectionArea")
    const currentPosition = this.floorPlanerController.getCurrentPosition()
    this.#selectionArea.setStartPosition(currentPosition)
    console.log(this.#selectionArea)

    this.#draggedSelectionArea = false
  }

  startDragGeometries (currentGeometries) {
    this.startDragPosition = this.floorPlanerController.getCurrentPosition()
    currentGeometries.filter(geometry => geometry.actions['drag'])
      .forEach(geometry => geometry.setAction(geometry.actions.drag))
  }

  onMouseMove (event, currentGeometries) {
    console.log("select Mouse Move: "+this.#selectionArea.isActive()+":::"+this.startDragPosition+":::")

    //let mode=this.floorPlanerController.oldTool
    let mode=this.floorPlanerController.toolGroup

    if(mode==="axes"){
      this.onMouseMoveAxis()
      console.log("MODE: "+mode)
    }else{

      console.log("mode: "+mode)
      if (this.#selectionArea.isActive()) {
        console.log("select Area active")
        this.updateSelectionArea(currentGeometries)
      }

      if (this.startDragPosition) {
        console.log("select start drag")
        this.onMouseMoveDragGeometries(currentGeometries)
      }
    }
  }

  onMouseMoveAxis(event, currentGeometries) {
    //let mode=this.floorPlanerController.oldTool
    let mode=this.floorPlanerController.toolGroup

    this.previewAxis=this.floorPlanerController.previewAxis
    let position = this.floorPlanerController.currentPosition
    if(this.originalAxis.length===2){
      console.log("SController:")
      //let movePosition = getOrtoProjectionOnLine(position,this.originalAxis[0],this.originalAxis[1])
      this.previewPositions=this.previewAxis.updateAxis(position,this.originalAxis[0],this.originalAxis[1])
      console.log(this.previewAxis.edge.getVertices())
      console.log(this.previewPositions)
      console.log("orignal axis exists: ")
    }
    console.log("MODE: "+mode)
  }

  onMouseMoveDragGeometries (currentGeometries) {
    const deltaMovement = this.floorPlanerController.getMouseDeltaMovement()

    let isDraggable = false
    currentGeometries.forEach(geometry => isDraggable |= geometry.isSingleDraggable())
    console.log("ISDRAGABLE:"+isDraggable)
    if (!isDraggable) {

      return
    }

    currentGeometries.forEach(geometry => {
      switch (geometry.getAction()) {
        case geometry.actions.drag: {
          geometry.moveDelta(deltaMovement)
        }
        default:

      }
    })

    this.#selectionBoundingBox.moveDelta(deltaMovement)
  }

  onLeftMouseUpDragGeometries (currentGeometries) {
    currentGeometries.forEach(geometry => geometry.setAction(geometry.actions.select))
    const result = !!this.startDragPosition && !!this.startDragPosition.distanceToSquared(this.floorPlanerController.getCurrentPosition())
    this.startDragPosition = null

    if (result) {
      this.floorPlanerController.saveChanges()
      return true
    }

    return false
  }

  updateSelectionArea (currentGeometries) {
    console.log("update selection")
    this.unselectGeometriesByStartDraggingSelectionArea(currentGeometries)

    const currentPosition = this.floorPlanerController.getCurrentPosition()

    this.#selectionArea.updateArea(currentPosition)

    this.floorPlanerController.getGeometries()
      .forEach(geometry => {
          this.removeGeometryFromCurrentGeometries(geometry, currentGeometries)
          geometry.setActive(false)
        },
      )

    let geometriesToSelect = []
    this.#selectionArea.getContainsVertices(this.floorPlanerController.getAllVertices())
      .forEach(vertex => {
        const geometry = vertex.getGeometry()
        if (!geometriesToSelect.includes(geometry))
          geometriesToSelect.push(geometry)
      })

    console.log("N GEOMETRIES:"+geometriesToSelect.length)

    let geometriesToSelectReduced=[]
    for(let i=0;i<geometriesToSelect.length; i++){

      let vertices=geometriesToSelect[i].getVertices()

      console.log("N VERTICES: "+ geometriesToSelect[i].getVertices().length)
      console.log("TYPE: "+geometriesToSelect[i].representationType)
      if(this.#selectionArea.isContainingAllVertices(vertices)){
        console.log("contained:"+geometriesToSelect[i].representationType)
        geometriesToSelectReduced.push(geometriesToSelect[i])
      }
    }
    geometriesToSelect=geometriesToSelectReduced
    console.log("selected geometries: "+geometriesToSelect.length)
    const geometriesToUnselect = currentGeometries.filter(geometry => geometriesToSelect.includes(geometry))
    geometriesToSelect = geometriesToSelect.filter(geometry => !currentGeometries.includes(geometry))

    geometriesToSelect.forEach(geometry => {
      console.log("geometry name: "+geometry.representationType)
      geometry.setActive(true)
      currentGeometries.push(geometry)
    })
    geometriesToUnselect.forEach(geometry => {
      this.removeGeometryFromCurrentGeometries(geometry, currentGeometries)
    })

    this.updateCurrentGeometries(currentGeometries)
  }


  unselectGeometriesByStartDraggingSelectionArea (currentGeometries) {
    if (!this.#draggedSelectionArea && !this.#selectionArea.isDragged()) {
      for (let i = currentGeometries.length - 1; i >= 0; i--) {
        this.removeGeometryFromCurrentGeometries(currentGeometries[i], currentGeometries)
      }
      this.#draggedSelectionArea = true
    }
  }

  onLeftMouseUp (event, currentGeometries) {
    if (this.startDragPosition) {
      if (this.onLeftMouseUpDragGeometries(currentGeometries)) {
        return
      }
    }

    if (this.#selectionArea.isActive()) {
      this.#selectionArea.setActive(false)
      if (this.#selectionArea.isDragged()) {
        return
      }
    }

    const geometries = this.floorPlanerController.getRaycastHitObjectsByRepresentationTypes(this.selectableRepresentationTypes)
    let geometry = geometries.length ? geometries[0] : null

    if (!!currentGeometries.length && !!geometry && (event.ctrlKey || event.metaKey)) {
      if (!geometry.isActive()) {
        geometry.setActive(true)
        currentGeometries.push(geometry)
      } else {
        geometry.setActive(false)
        const index = currentGeometries.findIndex(geo => geo === geometry)
        currentGeometries.splice(index, 1)
      }
    } else if (!!currentGeometries.length && !!geometry && !(event.ctrlKey || event.metaKey)) {
      if (currentGeometries.length === 1) {
        const index = geometries.findIndex(geo => geo === currentGeometries[0])
        if (index >= 0)
          geometry = geometries[(index + 1) % geometries.length]
      }
      currentGeometries.forEach(geo => geo.setActive(false))
      currentGeometries.splice(0, currentGeometries.length)

      geometry.setActive(true)
      currentGeometries.push(geometry)
    }
    // Select only one
    else if (!currentGeometries.length && !!geometry) {
      geometry.setActive(true)
      currentGeometries.push(geometry)
    }
    // Deselect all
    else if (!!currentGeometries.length && !geometry) {
      currentGeometries.forEach(geometry => geometry.setActive(false))
      currentGeometries.splice(0, currentGeometries.length)
    }

    this.updateCurrentGeometries(currentGeometries)
  }

  onKeyADown (event, currentGeometries) {
    if (event.ctrlKey || event.metaKey) {
      const geometries = this.floorPlanerController.getGeometries()

      geometries.filter(geometry => this.selectableRepresentationTypes.includes(geometry.representationType))
        .forEach(geometry => {
          geometry.setActive(true)
          if (!currentGeometries.includes(geometry)) {
            currentGeometries.push(geometry)
          }
        })

      this.updateCurrentGeometries(currentGeometries)
    }
  }

  onEscapeDown (event, currentGeometries) {
    if (currentGeometries.length) {
      if (this.#selectionArea.isActive()) {
        for (let i = currentGeometries.length; i >= 0; i--) {
          this.removeGeometryFromCurrentGeometries(currentGeometries[i], currentGeometries)
        }
        this.#selectionArea.setActive(false)
      }
    } else {
      this.floorPlanerController.changeTool(representationTypes.select)
    }

    this.updateCurrentGeometries(currentGeometries)
  }

  cancelCurrentActions (currentGeometries) {
    currentGeometries.filter(geometry => this.selectableRepresentationTypes.includes(geometry.representationType))
      .forEach(geometry => {
        geometry.setActive(false)
      })

    currentGeometries.splice(0, currentGeometries.length)
  }

  removeGeometryFromCurrentGeometries (geometry, currentGeometries) {
    if (currentGeometries.includes(geometry)) {
      geometry.setActive(false)
      const index = currentGeometries.findIndex(geo => geo === geometry)
      currentGeometries.splice(index, 1)
    }
  }

  updateCurrentGeometries (currentGeometries) {
    this.#selectionBoundingBox.setSelectedGeometries(currentGeometries)
  }

  onEnterDown(event, currentGeometries){
    console.log("onEnterDown ABC")
  }

  deselectAxis(event, currentGeometries){

  }

  selectedAxisToBackend (space, edge, positions) {
    console.log("trySend:"+space+" "+edge)
    if(space===null) return
    if(edge===null) return
    if(edge.getVertices().length!==2) return

    //console.log("selectedAxisToBackend")
    //console.log(this.previewPositions)
    const axisController = this.floorPlanerController.representationController.get(representationTypes.axis);
    axisController.addAxesInSpace(space)


    space.setSelectedAxis(edgeAndVerticesToJSON(edge,this.previewPositions))


    //space.setSelectedAxis(edgeAndVerticesToJSON(edge,this.previewPositions))
    //console.log(edgeAndVerticesToJSON(edge))
    space.setByHand(true)

    //console.log("step2: update "+this.floorPlanerController.oldTool)
    //console.log("step2: update "+this.floorPlanerController.toolGroup)
    if(this.floorPlanerController.oldTool!=="axes"){

      this.floorPlanerController.updateSpaceGeometriesAndFetchAnalytics(axisController)

      //console.log("load AXES! "+this.currentSpace+"  showAxes: "+this.showAxis)
    }


    this.previewPositions=[]
    if(this.previewAxis!==null){
      this.previewAxis.disable()
    }
    this.originalAxis=[]

    //console.log("selected axis to backend")
  }
}