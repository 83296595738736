import React from 'react'

import EditableTable from '../../common/components/EditableTable'
import ToggleSwitch from '../../common/components/ToggleSwitch'

import IconButton from '../../common/components/IconButton'

import './PatternStylesTable.scss'
import TableUsedPatternSelectButton from './selects/TableUsedPatternSelectButton'
import NumberInput from '../../common/components/NumberInput'
import { iconTypes } from '../../common/components/icons/IconFactory'

function PatternStylesTable ({ data, onUnitChange }) {

  const columns = React.useMemo(
    () => [
      {
        // Expander column
        Header: () => null, // No header
        id: 'expander', // Expander Column ID
        className: 'pattern-styles-expander-column',
        Cell: ({ row }) =>
          // Use the row.canExpand and row.getExpandedToggleProps prop getter
          // to build the toggle for expanding a row
          row.canExpand ? (
            <div className='expander-icon' style={{
              // Use the row.depth property and paddingLeft to
              // indicate the depth of the row
              paddingLeft: `${row.depth * 0.9}rem`,
            }}>
              <IconButton icon={row.isExpanded ? iconTypes.triangleDown : iconTypes.triangle}
                          {...row.getExpandedToggleProps()}/>
            </div>
          ) : null,
      },
      {
        Header: 'Style Name',
        id: 'name',
        accessor: (row) => row.name,
        className: 'pattern-styles-name-column',
        inputType: 'text',
      },
      {
        Header: 'Used Pattern',
        id: 'usedPattern',
        className: 'pattern-styles-selection-column',
        inputType: 'select',
        accessor: (row) => row.patternType,
        Cell: ({ row, cell: { value }, onCellValueChange }) => <TableUsedPatternSelectButton value={value}
                                                                                             unitId={row.original.id}
                                                                                             onChange={(unitId, unit) => onCellValueChange(unitId, unit)}
                                                                                             usePattern={row.original.usePattern}/>,
      },

      {
        Header: 'Individual Storage',
        id: 'individualStorage',
        accessor: (row) => row.individualStorage,
        className: 'pattern-styles-toggle-column',
        Cell: ({ row, cell: { value }, onCellValueChange }) => <ToggleSwitch value={value}
                                                                             onChange={() => onCellValueChange(row.original.id, { individualStorage: !value })}/>,
      },
      {
        Header: 'Face Separators',
        id: 'faceSeparators',
        accessor: (row) => row.faceSeparator,
        className: 'pattern-styles-toggle-column',
        Cell: ({ row, cell: { value }, onCellValueChange }) => <ToggleSwitch value={value}
                                                                             onChange={() => onCellValueChange(row.original.id, { faceSeparator: !value })}/>,
      },
      {
        Header: 'Back Separators',
        id: 'backSeparators',
        accessor: (row) => row.backSeparator,
        className: 'pattern-styles-toggle-column',
        Cell: ({ row, cell: { value }, onCellValueChange }) => <ToggleSwitch value={value}
                                                                             onChange={() => onCellValueChange(row.original.id, { backSeparator: !value })}/>,
      },
      {
        Header: 'Side Separators',
        id: 'sideSeparators',
        accessor: (row) => row.sideSeparator,
        className: 'pattern-styles-toggle-column',
        Cell: ({ row, cell: { value }, onCellValueChange }) => <ToggleSwitch value={value}
                                                                             onChange={() => onCellValueChange(row.original.id, { sideSeparator: !value })}/>,
      },
      {
        Header: 'Workplaces per bench',
        id: 'maxWorkplacesPerBench',
        className: 'pattern-styles-number-column',
        accessor: (row) => row.maxWorkplacesPerBench,
        Cell: ({ row, cell: { value }, onCellValueChange }) => <NumberInput value={value}
                                                                            step={1}
                                                                            digits={0}
                                                                            onChange={(value) => onCellValueChange(row.original.id, { maxWorkplacesPerBench: value })}/>,
      },
      {
        Header: 'Workplaces per row',
        id: 'maxWorkplacesPerRow',
        className: 'pattern-styles-number-column',
        accessor: (row) => row.maxWorkplacesPerRow,
        Cell: ({ row, cell: { value }, onCellValueChange }) => <NumberInput value={value}
                                                                            step={1}
                                                                            digits={0}
                                                                            onChange={(value) => onCellValueChange(row.original.id, { maxWorkplacesPerRow: value })}/>,
      },
    ],
    [],
  )

  return (
    <EditableTable className='pattern-styles-units-table'
                   columns={columns}
                   data={data}
                   onCellValueChange={(unitId, unit) => onUnitChange(unitId, unit)}
    />
  )

}

export default PatternStylesTable