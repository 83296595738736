import { FULFILLED, PENDING, REJECTED } from '../../globalConstants'
import { httpClient } from '../../httpClient'
import { projectApi } from '../../utils'

const FETCH_APP_STATE = 'FETCH_APP_STATE'

export const appStateActionTypes = {

  SET_SELECTED_ORGANISATIONAL_UNIT_ID: 'SET_SELECTED_ORGANISATIONAL_UNIT_ID',
  SET_SELECTED_BUILDING_ID_WITHOUT_FLOORS: 'SET_SELECTED_BUILDING_ID_WITHOUT_FLOORS',
  SET_SELECTED_FLOOR: 'SET_SELECTED_FLOOR',

  FETCH_APP_STATE: FETCH_APP_STATE,
  FETCH_APP_STATE_PENDING: FETCH_APP_STATE + PENDING,
  FETCH_APP_STATE_FULFILLED: FETCH_APP_STATE + FULFILLED,
  FETCH_APP_STATE_REJECTED: FETCH_APP_STATE + REJECTED,
}

export function setSelectedOrganisationalUnit (unitId) {
  return {
    type: appStateActionTypes.SET_SELECTED_ORGANISATIONAL_UNIT_ID,
    id: unitId,
  }
}

export function fetchAppState () {
  return {
    type: appStateActionTypes.FETCH_APP_STATE,
    payload: httpClient.get(`${projectApi()}/appState`),
  }

}