import React, { Component } from 'react'
import { connect } from 'react-redux'
import Dialog, { DialogButtonGroup, DialogContent } from '../../../common/components/Dialog'
import SelectButton from '../../../common/components/SelectButton'
import { activateAndLoadProject, fetchUserProjectsList } from '../../../common/redux/projects/projects-actions'
import { push } from 'connected-react-router'
import Button from '../../../common/components/Button'

class LoadUserProjectsDialog extends Component {

  componentDidMount () {
    this.props.fetchUserProjectsList()
  }

  constructor (props) {
    super(props)
    this.state = {
      selectedProject: '',
    }
  }

  render () {
    return (
      <Dialog
        onCancel={this.close}
        title={'Open Project'} className={'load-project-dialog'}>
        <DialogContent>
          <SelectButton value={this.state.selectedProject}
                        options={this.props.projects}
                        onChange={event => this.selectProject(event)}
                        customSelectorText={'Select Project...'}
                        selectOption/>
        </DialogContent>
        <DialogButtonGroup>
          <Button onClick={this.close}>Cancel</Button>
          <Button className={'button'} onClick={this.load} disabled={!this.state.selectedProject} variant={'contained'}>Confirm</Button>
        </DialogButtonGroup>
      </Dialog>
    )
  }

  selectProject = event => {
      this.setState({ ...this.state, selectedProject: event.target.value })
  }

  load = () => {
    this.close()
    this.props.activateAndLoadProject(this.state.selectedProject)
  }

  close = () => {
    this.props.onClose()
  }

}

const mapStateToProps = state => {
  return {
    projects: state.projects.userProjects
      .map(project => ({
        label: project.name,
        value: project.id,
      }))
      .reverse(),
  }
}

const mapDispatchToProps = {
  fetchUserProjectsList: fetchUserProjectsList,
  activateAndLoadProject: activateAndLoadProject,
  push: push,
}

export default connect(mapStateToProps, mapDispatchToProps)(LoadUserProjectsDialog)
