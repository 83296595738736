import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'

import { viewNames } from '../../common/globalConstants'

import ProjectMenuItem from './ProjectMenuItem'
import FurnitureMenuItem from './FurnitureMenuItem'
import HelpMenuItem from './HelpMenuItem'

import { NavLinkMenuItem } from './NavLinkMenuItem'

import './NavigationMenu.scss'

function MenuItem (props) {

  const urlBase = props.projectId ? `/projects/${props.projectId}` : ''
  const styleClass = props.className ? ' ' + props.className : ''

  return (
    <li className={styleClass}>
      <NavLinkMenuItem className={props.className}
                       icon={props.icon}
                       urlBase={urlBase}
                       view={props.view}
                       title={props.title}
                       darkMode={props.darkMode}/>
    </li>)
}

class NavigationMenu extends Component {

  render () {

    const advancedSettings = this.props.advancedSettings

    return (
      <ul>
        <Route path="/projects/:projectId" render={({ match }) => (
          <div className={'navigation-menu' + (this.props.advancedSettings ? ' dark-mode' : '')}>
            <ProjectMenuItem/>
            <MenuItem projectId={match.params.projectId} title={'Floorplan'} view={viewNames.FLOORPLAN} icon={'floorplan'} darkMode={this.props.advancedSettings}/>
            <MenuItem projectId={match.params.projectId} title={'Floors'} view={viewNames.FLOORS} darkMode={this.props.advancedSettings}/>
            <MenuItem projectId={match.params.projectId} title={'People'} view={viewNames.PEOPLE} darkMode={this.props.advancedSettings}/>
            {advancedSettings ? <FurnitureMenuItem projectId={match.params.projectId} active={this.isFurnitureMenuActive(match)}/> : null}
            <MenuItem projectId={match.params.projectId} title={'Settings'} view={viewNames.SETTINGS} darkMode={this.props.advancedSettings}/>
            <HelpMenuItem/>
          </div>
        )}/>
      </ul>
    )
  }

  isFurnitureMenuActive (match) {
    const applicationURL = match.url + '/' + viewNames.SETS
    const patternURL = match.url + '/' + viewNames.PATTERNS

    const pathname = window.location.pathname

    return pathname &&
      (pathname.startsWith(applicationURL) ||
        pathname.startsWith(patternURL))

  }

}

let mapStateToProps = (state) => {
  return {
    advancedSettings: state.settings.appSettings.advancedSettings,
  }
}

export default connect(mapStateToProps)(NavigationMenu)


