import { Group } from 'three'
import { GeometryController } from './GeometryController'
import { representationTypes } from '../config/RepresentationTypes'
import { removeAllChildren } from '../../../common/three/drawing/drawConstants'
import { EdgeGroup } from '../geometries/EdgeGroup'
import { Edge } from '../geometries/EdgeFactory'
import { Vertex } from '../geometries/VertexFactory'
import { getMaterial } from '../common/Materials'
import { materialTypes } from '../common/MaterialTypes'
import { geometryToPositionArray,isVerticesContainsVertex,isVerticesContainsAllVertices } from '../helper/GeometryHelper'
import { jsonPositionToVertex, findAxes, findSpaceAtPos, edgeToJSON } from '../helper/AxesHelper'
import { PolylineController } from './PolylineController'

export class AxisController extends PolylineController {
  //mainAxesGeometries=[]
  //fineAxesGeometries=[]
  mode
  edgeGroup
  spaceEdgeGroup
  //currentGeometries = []
  activeSpace = null

  onLeftMouseDown (event, geometries, currentGeometries) {
    //currentGeometries=this.currentGeometries
    console.log("event: " + event + " geometries: " + geometries + " current:" + currentGeometries)

    let position = this.floorPlanerController.currentPosition

    let spaceGeometries = this.floorPlanerController.getGeometriesByRepresentationType(representationTypes.space)
    console.log("AxisController Down: " + event + " n:" + spaceGeometries.length)
    let space = findSpaceAtPos(spaceGeometries, position)

    if (space != null) {
      console.log("space Fund:" + space.getSpaceType())
      if (space.getSpaceType() === "zone") {
        if (this.mode === "axesdelete") {
          console.log("axesdelete")
          space.setByHand(true)
          this.removeAxes(space)
          this.updateResults()
        } else if (this.mode === "axesautogenerate") {
          console.log("axesautogenerate")
          space.setByHand(false)
          this.updateResults()
        } else if (this.mode === "axesedit") {
          space.setByHand(true)

          console.log("axesesdit: currentGeometries")
          console.log(currentGeometries)
          this.activeSpace = space
          super.onLeftMouseDown(event, geometries, currentGeometries)
        }
      }
    }
  }

  onClose (geometry, currentGeometries, minVerticesCount) {
    console.log("CLOSE")

    if (geometry.getVerticesCount() >= minVerticesCount) {//currentGeometries
      console.log("edge close: " + geometry.getVerticesCount())
      //check if both vertices are inside the polygon
      geometry.close()

      console.log("current 0: "+currentGeometries.length)
      this.removeGeometryFromCurrentGeometries(geometry, currentGeometries)

      console.log("current 1: "+currentGeometries.length)
      if (this.activeSpace != null) {
        console.log("geometry:"+geometry)
        this.addAxes(this.activeSpace, geometry)
      }
      this.updateResults()
      return true
    }
    return false
  }

  clear(){
    removeAllChildren(this.floorPlanerController.fineAxisPlane)
    removeAllChildren(this.group)

    /*
    this.fineEdgeGroup = new EdgeGroup(this.floorPlanerController.fineAxisPlane)
    this.fineEdgeGroup.z = 0.1
    this.fineEdgeGroup.visible = true

    this.edgeGroup = new EdgeGroup(this.group)
    this.edgeGroup.z = 0.1
    this.edgeGroup.visible = true
    */

    //this.fineEdgeGroup=null
    //this.edgeGroup=null

    console.log("edges cleared")
  }
  updateByAnalytics (result) {
    console.log("UPDATE BY ANALYTICS")
    console.log(result)

    //this.floorPlanerController.printSceneObjects()
    let axisMaterial = getMaterial(materialTypes.green)

    console.log("FAP: " + this.floorPlanerController.fineAxisPlane.children.length)
    removeAllChildren(this.floorPlanerController.fineAxisPlane)
    removeAllChildren(this.group)

    this.fineEdgeGroup = new EdgeGroup(this.floorPlanerController.fineAxisPlane)
    this.fineEdgeGroup.z = 0.1
    this.fineEdgeGroup.visible = true
    console.log("FAP: " + this.floorPlanerController.fineAxisPlane.children.length)

    this.edgeGroup = new EdgeGroup(this.group)
    this.edgeGroup.z = 0.1
    this.edgeGroup.visible = true

    //this.spaceEdgeGroup = new EdgeGroup(this.group)
    //this.spaceEdgeGroup.z = 0.1
    //this.spaceEdgeGroup.visible = true

    this.fineEdgeGroup.material = getMaterial(materialTypes.green)
    this.edgeGroup.material = getMaterial(materialTypes.green)
    console.log("MAT:" + this.edgeGroup.material)

    //let mainAxes=result.data.mainAxes
    this.createAxes(this.edgeGroup, result.mainAxes, this.edgeGroup.material)
    this.createFineAxes(this.fineEdgeGroup, result.fineAxes, this.edgeGroup.material)
    console.log("E:" + this.edgeGroup.getEdges().length)
    console.log("FE:" + this.fineEdgeGroup.getEdges().length)

    this.createAxesInSpaces()
  }

  createAxesInSpaces(){
    let spaceGeometries = this.floorPlanerController.getGeometriesByRepresentationType(representationTypes.space)
    for(let iSpace=0; iSpace<spaceGeometries.length; iSpace++){
      const space=spaceGeometries[iSpace]
      space.setUnknown(false)
      console.log(space)
      if(space.getByHand()){
        console.log("byHand: "+space)
        this.addAxesInSpace(space)
      }
    }
  }
  createAxes (edgeGroup, mainAxes, material) {

    let nAxes = mainAxes.length
    console.log("nAxes: " + nAxes)

    for (let iAxis = 0; iAxis < nAxes; iAxis++) {
      let axis = mainAxes[iAxis]
      let pointList = axis.pointList
      console.log(iAxis + ". " + pointList.length)
      let axisPosList = []

      for (let iPoint = 0; iPoint < pointList.length; iPoint++) {
        let point = pointList[iPoint]

        let x = point[0]
        let y = point[1]

        axisPosList.push(jsonPositionToVertex(x, y))
      }

      if (axisPosList.length == 2) {
        console.log("axisPosList:" + axisPosList.length + " 0:" + axisPosList[0] + " 1:" + axisPosList[1])
        edgeGroup.addEdge(axisPosList[0], axisPosList[1])
      }
    }
    console.log("mainEdges:" + edgeGroup.length)
  }

  createFineAxes (edgeGroup, fineAxesGroup, material) {
    //fineAxesGroup=result.data.fineAxes
    let nFineAxes = fineAxesGroup.length
    console.log("NFineAxes: " + nFineAxes)
    for (let iFine = 0; iFine < fineAxesGroup.length; iFine++) {
      //console.log("iFine:"+iFine+"of"+fineAxesGroup.length)
      //console.log("nAxes: "+fineAxesGroup[iFine].length)
      for (let iFineLine = 0; iFineLine < fineAxesGroup[iFine].length; iFineLine++) {

        let cFineAxis = fineAxesGroup[iFine][iFineLine]
        //let fineAxisPosList= []
        //console.log("fineaxisPoints:"+cFineAxis.length)

        for (let iFinePoint = 0; iFinePoint < cFineAxis.length; iFinePoint += 4) {
          let x1 = cFineAxis[iFinePoint]
          let y1 = cFineAxis[iFinePoint + 1]
          let x2 = cFineAxis[iFinePoint + 2]
          let y2 = cFineAxis[iFinePoint + 3]
          let pos1 = jsonPositionToVertex(x1, y1)
          let pos2 = jsonPositionToVertex(x2, y2)
          edgeGroup.addEdge(pos1, pos2)
        }
      }
    }
  }

  setVisibilities (visible) {
    if (this.edgeGroup)
      if (this.edgeGroup.visible)
        this.edgeGroup.visible = visible
    if (this.fineEdgeGroup)
      if (this.fineEdgeGroup.visible)
        this.fineEdgeGroup.visible = visible
  }

  addAxesInSpace (space) {
    console.log("addAxisinSpace")
    space.setUnknown(false)
    space.clearAxes()
    let axes = findAxes(space,this.edgeGroup)
    console.log("NAXES: "+axes.length)
    for (let i = 0; i < axes.length; i++) {
      let axis=axes[i]
      let axisJSON = edgeToJSON(axis)
      console.log(axisJSON)
      space.addAxis(axisJSON)
    }
  }

  addAxes (space, geometry) {
    space.setUnknown(false)
    console.log("addAxes: " + geometry)
    console.log("addAxes: " + geometry.getEdges().length)
    let edges = geometry.getEdges()

    for (let iEdge = 0; iEdge < edges.length; iEdge++) {
      let axis=edges[iEdge]
      let axisJSON = edgeToJSON(axis)

      console.log(axisJSON)
      space.addAxis(axisJSON)
      console.log("add edge: "+iEdge)
    }
    this.floorPlanerController.printSceneObjects()
  }

/*
  edgeToJSON(edge){
    let verts = edge.getVertices()
    let uuid = edge.uuid
    console.log("edge: "+uuid+": " + verts) //+" :: "+edge.startVertex+" :: "+edge.endVertex)
    let startVertexUUID = "" + verts[0].uuid
    let endVertexUUID = "" + verts[1].uuid
    let startPosition = verts[0].position
    let endPosition = verts[1].position
    console.log("S: " + startVertexUUID + " :: " + endVertexUUID)
    console.log("S: " + startPosition.x + ":" + startPosition.y + " :: " + endPosition.x + ":" + endPosition.y)
    console.log("uuid:" + uuid)

    console.log(verts[0].toJSON())
    console.log(verts[1].toJSON())


    let axisJSON = {
      uuid: uuid,
      startVertex: verts[0].toJSON(),
      endVertex: verts[1].toJSON(),
    }
    console.log("axisJSON:")
    console.log(axisJSON)

    return axisJSON
  }

 */
  removeAxes(space){
    let axes=findAxes(space,this.edgeGroup)
    for(let i=axes.length-1; i>=0; i--){
      this.edgeGroup.remove(axes[i])
    }
    space.clearAxes()

    console.log("N AXES FOUND: "+axes.length)
    //space.setAxes(axes)
    console.log("BY HAND: "+space.getByHand())
  }
/*
  findAxes(space){
    let spacePos=geometryToPositionArray(space)
    let foundAxes=[]
    let edgeNumber=this.edgeGroup.children.length;
    console.log("nEDGES: "+edgeNumber)
    for(let i=edgeNumber-1; i>=0; i--){
      console.log("iEdge: "+i)
      let edge=this.edgeGroup.children[i]
      let vertices=edge.getVertices()
      let positions=[]
      for(let iVertex=0; iVertex<vertices.length; iVertex++){
        let vertex=vertices[iVertex]
        let position=vertex.position
        positions.push(position)
        console.log(position.x+":::"+position.y)
      }

      if(isVerticesContainsAllVertices(spacePos,positions)) {
        foundAxes.push(edge)
        console.log("container found!!")
      }else{
        console.log("no container found!!")
      }
    }
    return foundAxes
  }
  */

  onLeftMouseUp(){
    console.log("AxisController Up: ")
  }
/*
  findSpaceAtPos(spaceGeometries,position){
    let nSpaces=spaceGeometries.length
    for(let iSpace=0; iSpace<nSpaces; iSpace++){
      let space=spaceGeometries[iSpace]
      let spacePos=geometryToPositionArray(space)
      if(isVerticesContainsVertex(spacePos,position)) {
        //console.log("inside")
        return space
      }
      else
        console.log("outside")
    }
    return null
  }
*/

  updateResults(){
    console.log("UPDATE RESULTS")
    this.floorPlanerController.updateSpaceGeometriesAndFetchAnalytics(this)
  }
}