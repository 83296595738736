import React, { Component } from 'react'
import { connect } from 'react-redux'
import ModalConsumer from '../../common/components/modal/ModalConsumer'
import { createOrganisationalUnitAssignment, deleteOrganisationalUnitAssignment, generateAll, updateOrganisationalUnitAssignment } from '../../floorplan/actions/actions'
import {
  addOrganisationalUnit,
  deleteAllOrganisationalUnits,
  deleteOrganisationalUnit,
  downloadCorporateDataAsXLSX,
  updateOrganisationalUnit,
  updateOrganisationalUnits,
  uploadCorporateSettingsFile,
} from '../../common/redux/organisationunits/organisational-units-actions'
import CorporateTable from './CorporateTable'
import XlsxUploadDialog from '../../settings/components/dialogs/XlsxUploadDialog'
import Button from '../../common/components/Button'
import './CorporateData.scss'
import ButtonGroup from '../../common/components/ButtonGroup'
import TablePageButtonToolBar from '../../settings/components/TablePageButtonToolBar'
import DeletionDialog from '../../common/components/DeletionDialog'
import { iconTypes } from '../../common/components/icons/IconFactory'
import equal from 'fast-deep-equal'
import { cloneDeep } from 'lodash'
import { recursiveFindFlatMap, recursiveFlatMap, updateObject } from '../../common/utils'
import ManualLockingDialog from './ManualLockingDialog'

const newOrganisationalUnit = {
  name: 'Untitled Group',
  shortName: 'GROU',
  organisationalUnits: [
    {
      name: 'Untitled Department',
      shortName: 'DEPA',
      organisationalUnits: [
        {
          name: 'Untitled Team',
          shortName: 'TEAM',
        },
      ],
    },
  ],
}

class CorporateData extends Component {

  constructor (props, context) {
    console.log("CONSTRUCTOR**")
    super(props, context)

    this.state = {
      selectedOrganisationalUnit: null,
      units: cloneDeep(this.props.units),
      unitsToUpdate: [],
      createFloorAssignments: [],
      updateFloorAssignments: [],
      deleteFloorAssignments: [],
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    console.log("UPDATE**")
    if (prevProps.lastAddedUnit !== this.props.lastAddedUnit) {
      console.log("lastAddedUnit")
      this.setSelectedOrganisationalUnit(this.props.lastAddedUnit)
    }

    if (!equal(this.props.units, prevProps.units)) {
      console.log("resetData")
      this.resetData()
    }
  }

  isChanged () {
    console.log("ISCHANGED**")


    return (this.state.unitsToUpdate && this.state.unitsToUpdate.length > 0) ||
      (this.state.createFloorAssignments && this.state.createFloorAssignments.length > 0) ||
      (this.state.updateFloorAssignments && this.state.updateFloorAssignments.length > 0) ||
      (this.state.deleteFloorAssignments && this.state.deleteFloorAssignments.length > 0)
  }

  resetData () {
    console.log("RESET**")
    this.setState({
      units: cloneDeep(this.props.units),
      unitsToUpdate: [],
      createFloorAssignments: [],
      updateFloorAssignments: [],
      deleteFloorAssignments: [],
    })
  }

  //region SEND TO BACKEND

  updateTable () {
    if (this.state.unitsToUpdate.length) {
      console.log("unitsforbackend**")
      console.log(this.getUnitsForBackend())

      this.props.updateOrganisationalUnits(this.getUnitsForBackend())
    }

    this.updateAssignments()
  }

  getUnitsForBackend () {
    const units = []

    this.state.units.forEach(group => {
      units.push(this.getUnitForBackend(group))
      console.log("group!")
      group.organisationalUnits.forEach(department => {
        units.push(this.getUnitForBackend(department, true, false, true, true))
        console.log("department")
        department.organisationalUnits.forEach(team => {
          units.push(this.getUnitForBackend(team, false, true, true, false, true))
        })
      })

    })

    return units
  }

  getUnitForBackend (updateUnit, department = false, team = false, color = false, applicationProfile = false, patternStyle = false) {
    console.log("getUnitForBackend**")
    const unit = {}

    unit.id = updateUnit.id
    unit.name = updateUnit.name
    unit.fourLetterName = updateUnit.fourLetterName
    if (color)
      unit.color = updateUnit.color
    if (team) {
      unit.requirement = {}
      unit.requirement.numberOfEmployees = updateUnit.requirement.numberOfEmployees
      unit.requirement.deskSharingRatio = updateUnit.requirement.deskSharingRatio
      unit.requirement.numberOfWorkplaces = updateUnit.requirement.numberOfWorkplaces
    }
    if (department) {
      unit.requirement = {}
      unit.requirement.areaForWorkplace = updateUnit.requirement.areaForWorkplace
      unit.requirement.area = updateUnit.requirement.area
    }
    if (applicationProfile) {
      console.log("applicationprofile")
      const find = this.state.unitsToUpdate.find(u => u.id === updateUnit.id)
      //if (find)
        //console.log(find.applicationProfile)
      if (find && find.applicationProfile){
        console.log(updateUnit.applicationProfile)
        unit.applicationProfile = updateUnit.applicationProfile
      }

    }
    if (patternStyle) {
      const find = this.state.unitsToUpdate.find(u => u.id === updateUnit.id)
      if (find && find.patternStyle)
        unit.patternStyle = updateUnit.patternStyle
    }

    console.log("unit")
    console.log(unit)
    return unit
  }

  updateAssignments () {
    console.log("updateAssignments**")
    this.state.createFloorAssignments.forEach(assignment => this.props.createOrganisationalUnitAssignment(assignment.id, assignment.assignment))
    this.state.updateFloorAssignments.forEach(assignment => this.props.updateOrganisationalUnitAssignment(assignment.buildingId, assignment.floorId, assignment.id, assignment.assignment))
    this.state.deleteFloorAssignments.forEach(assignment => this.props.deleteOrganisationalUnitAssignment(assignment.buildingId, assignment.floorId, assignment.id))
  }

  //endregion

  setFloorDataToUnits (superDepartments) {

    console.log("setFloorData** "+superDepartments.length)

    superDepartments.forEach(superDepartment => {
      superDepartment.organisationalUnits.forEach(unit => {
        const createAssignment = this.state.createFloorAssignments.find(assignment => assignment.id === unit.id)
        const updateAssignment = this.state.updateFloorAssignments.find(assignment => assignment.id === unit.id)
        const deleteAssignment = this.state.deleteFloorAssignments.find(assignment => assignment.id === unit.id)

        if (createAssignment) {
          unit.floorId = createAssignment.assignment.floorId
          unit.buildingId = createAssignment.assignment.buildingId
        } else if (updateAssignment) {
          unit.floorId = updateAssignment.assignment.floorId
          unit.buildingId = updateAssignment.assignment.buildingId
        } else if (deleteAssignment) {
          unit.floorId = 'Unassigned'
        } else {
          const department = this.props.placedDepartments.find(department => unit.id === department.id)

          if (department) {
            console.log("department: ")
            console.log(department)
            console.log(unit.applicationProfile)

            unit.floorId = department.floorId
            unit.buildingId = department.buildingId

          } else {
            unit.floorId = 'Unassigned'
          }
        }
      })
    })
  }

  //region Dynamic Locking

  dynamicLocking (units) {
    console.log("dynamicLocking**")
    const allUnits = recursiveFlatMap(units, 'organisationalUnits')

    const departments = allUnits.filter(unit => unit.type === 'DEPARTMENT')
    console.log("departments: "+departments)
    departments.forEach(department => {
      console.log(department)
      if (!department.requirement.manualLocking) {
        this.dynamicLockingOfDepartmentRequirements(department)
        this.dynamicLockingOfTeamsRequirements(department)
      }
    })
  }

  dynamicLockingOfDepartmentRequirements (department) {
    console.log("dynamiclockingDEpartment**" )

    const updatedDepartment = this.state.unitsToUpdate.find(d => d.id === department.id)

    const checkInTeams = updatedDepartment && updatedDepartment.requirement

    if (!checkInTeams)
      return

    const lockAreaForWorkplace = updatedDepartment.requirement.area && !updatedDepartment.requirement.areaForWorkplace
    const lockArea = !updatedDepartment.requirement.area && updatedDepartment.requirement.areaForWorkplace

    if (!((lockAreaForWorkplace || lockArea) && !(lockAreaForWorkplace && lockArea)))
      return

    let deskSharingChanged = false
    let headcountChanged = false

    department.organisationalUnits.forEach(team => {
      const updatedTeam = this.state.unitsToUpdate.find(u => u.id === team.id)

      if (updatedTeam && updatedTeam.requirement) {
        if (updatedTeam.requirement.numberOfEmployees) {
          headcountChanged = true
        }
        if (updatedTeam.requirement.deskSharingRatio) {
          deskSharingChanged = true
        }
      }
    })

    if (headcountChanged && deskSharingChanged) {
      if (lockArea) {
        department.requirement.areaLocked = true
      } else if (lockAreaForWorkplace) {
        department.requirement.areaForWorkplaceLocked = true
      }
    }
  }

  dynamicLockingOfTeamsRequirements (department) {
    const updatedDepartment = this.state.unitsToUpdate.find(u => u.id === department.id)

    if (updatedDepartment && updatedDepartment.requirement && updatedDepartment.requirement.areaForWorkplace && updatedDepartment.requirement.area) {

      let lockHeadcount = false
      let lockDeskSharing = false

      department.organisationalUnits.forEach(team => {
        const updatedTeam = this.state.unitsToUpdate.find(u => u.id === team.id)

        if (updatedTeam && updatedTeam.requirement) {
          if (updatedTeam.requirement.numberOfEmployees) {
            lockDeskSharing = true
          } else if (updatedTeam.requirement.deskSharingRatio) {
            lockHeadcount = true
          }
        }
      })

      department.organisationalUnits.forEach(team => {
        team.requirement.numberOfEmployeesLocked = lockHeadcount
        team.requirement.deskSharingRatioLocked = lockDeskSharing
      })

    }
  }

  changeLockDialog (showModal, unitId, columnLock) {
    console.log("changeLockDialog")
    showModal(ManualLockingDialog,
      {
        lockedColumn: columnLock,
        lockingButtons: this.getColumnNamesToLock(columnLock),
        onClick: (columnLock) => this.manualLocking(unitId, columnLock),
      })
  }

  getColumnNamesToLock (columnLock) {
    switch (columnLock) {
      case 'numberOfEmployees':
        return ['Desk Sharing Ratio', 'm2/Workplace', 'Usable net Area']
      case 'deskSharingRatio':
        return ['Headcount', 'm2/Workplace', 'Usable net Area']
      case 'areaForWorkplace':
        return ['Headcount', 'Desk Sharing Ratio', 'Usable net Area']
      case 'area':
        return ['Headcount', 'Desk Sharing Ratio', 'm2/Workplace']
      default:
        break
    }
  }

  manualLocking (unitId, columnLock) {
    const unit = recursiveFindFlatMap(this.state.units, 'organisationalUnits', unit => unit.id === unitId)

    let department

    if (unit.type === 'TEAM') {
      department = recursiveFindFlatMap(this.state.units, 'organisationalUnits', u => u.id === unit.parentId)
    } else if (unit.type === 'DEPARTMENT') {
      department = unit
    }

    if (!department)
      return

    department.requirement.manualLocking = true

    this.reopenRequirements(department)

    if (columnLock === 'm2/Workplace') {
      department.requirement.areaForWorkplaceLocked = true

      const updatedDepartment = this.state.unitsToUpdate.find(unit => unit.id === department.id)
      if (updatedDepartment) {
        updatedDepartment.requirement = {
          area: department.requirement.area,
        }
      }
    } else if (columnLock === 'Usable net Area') {
      department.requirement.areaLocked = true
      const updatedDepartment = this.state.unitsToUpdate.find(unit => unit.id === department.id)
      if (updatedDepartment) {
        updatedDepartment.requirement = {
          areaForWorkplace: department.requirement.areaForWorkplace,
        }
      }
    } else if (columnLock === 'Headcount') {
      department.organisationalUnits.forEach(team => {
        team.requirement.numberOfEmployeesLocked = true
        const updatedTeam = this.state.unitsToUpdate.find(unit => unit.id === team.id)
        if (updatedTeam) {
          updatedTeam.requirement = {
            deskSharingRatio: team.requirement.deskSharingRatio,
          }
        }
      })
    } else if (columnLock === 'Desk Sharing Ratio') {
      department.organisationalUnits.forEach(team => {
        team.requirement.deskSharingRatioLocked = true
        const updatedTeam = this.state.unitsToUpdate.find(unit => unit.id === team.id)
        if (updatedTeam) {
          updatedTeam.requirement = {
            numberOfEmployees: team.requirement.numberOfEmployees,
          }
        }
      })
    }

  }

  reopenRequirements (department) {
    department.requirement.areaForWorkplaceLocked = false
    department.requirement.areaLocked = false
    department.organisationalUnits.forEach(team => {
      team.requirement.numberOfEmployeesLocked = false
      team.requirement.deskSharingRatioLocked = false
    })
  }

  //endregion

  //region Dynamic Calculation

  dynamicCalculation (units) {
    console.log("dynamicCalculation**")
    const allUnits = recursiveFlatMap(units, 'organisationalUnits')

    const departments = allUnits.filter(unit => unit.type === 'DEPARTMENT')
    console.log("departmensts "+departments.length)

    departments.forEach(department => this.dynamicCalculationOfDepartmentRequirements(department))

    departments.forEach(department => this.updateDepartmentRequirements(department))

    units.forEach(superDepartment => this.updateSuperDepartmentRequirements(superDepartment))
  }

  dynamicCalculationOfDepartmentRequirements (department) {
    const updatedDepartment = this.state.unitsToUpdate.find(unit => unit.id === department.id)

    const teams = department.organisationalUnits

    const calculateDepartmentFirst = this.dynamicCalculationOfDepartmentRequirementsFirst(department, updatedDepartment)

    if (calculateDepartmentFirst) {
      this.dynamicCalculationOfTeamsRequirementsFirst(department)
    }

    let { workplaces, editWorkplaces } = this.dynamicCalculationOfTeamsRequirements(department, teams, calculateDepartmentFirst)

    if (!calculateDepartmentFirst) {
      if (editWorkplaces) {
        department.requirement.numberOfWorkplaces = workplaces
      }

      this.dynamicCalculationOfDepartmentRequirementsAfter(department, updatedDepartment, editWorkplaces)
    }

    if (!editWorkplaces) {
      this.dynamicCalculationOfTeamsRequirementsAfter(department)
    }
  }

  dynamicCalculationOfTeamsRequirements (department, teams, calculateDepartmentFirst) {
    let workplaces = 0
    let editWorkplaces = false

    teams.forEach(team => {
      const updatedTeam = this.state.unitsToUpdate.find(unit => unit.id === team.id)

      editWorkplaces |= this.dynamicCalculationOfTeamRequirements(department, team, updatedTeam, calculateDepartmentFirst)

      workplaces += team.requirement.numberOfWorkplaces
    })

    return { workplaces: workplaces, editWorkplaces: editWorkplaces }
  }

  dynamicCalculationOfTeamRequirements (department, team, updatedTeam, calculateDepartmentFirst) {
    if (updatedTeam && updatedTeam.requirement) {
      const requirement = updatedTeam.requirement

      if (calculateDepartmentFirst && requirement.numberOfEmployees) {
        team.requirement.deskSharingRatio = team.requirement.numberOfWorkplaces ? requirement.numberOfEmployees / team.requirement.numberOfWorkplaces : 1
      } else if (calculateDepartmentFirst && requirement.deskSharingRatio) {
        team.requirement.numberOfEmployees = Math.floor(department.requirement.numberOfWorkplaces * requirement.deskSharingRatio)
      } else {
        if (requirement.numberOfEmployees && requirement.deskSharingRatio) {
          team.requirement.numberOfWorkplaces = Math.ceil(requirement.numberOfEmployees / requirement.deskSharingRatio)
          return true
        } else if (requirement.numberOfEmployees) {
          team.requirement.numberOfWorkplaces = Math.ceil(requirement.numberOfEmployees / team.requirement.deskSharingRatio)
          return true
        } else if (requirement.deskSharingRatio) {
          team.requirement.numberOfWorkplaces = Math.ceil(team.requirement.numberOfEmployees / requirement.deskSharingRatio)
          return true
        }
      }
    }

    return false
  }

  dynamicCalculationOfDepartmentRequirementsFirst (department, updatedDepartment) {
    if (updatedDepartment &&
      updatedDepartment.requirement &&
      updatedDepartment.requirement.areaForWorkplace &&
      updatedDepartment.requirement.area) {
      department.requirement.numberOfWorkplaces = Math.floor(updatedDepartment.requirement.area / updatedDepartment.requirement.areaForWorkplace)
      return true
    }

    return false
  }

  dynamicCalculationOfDepartmentRequirementsAfter (department, updatedDepartment, editWorkplaces) {
    if (!updatedDepartment) {
      if (editWorkplaces) {
        department.requirement.area = department.requirement.numberOfWorkplaces * department.requirement.areaForWorkplace
      }
      return
    }

    const requirement = updatedDepartment.requirement

    if (requirement) {
      if (editWorkplaces) {
        if (requirement.areaForWorkplace) {
          department.requirement.area = department.requirement.numberOfWorkplaces * requirement.areaForWorkplace
        } else if (requirement.area) {
          department.requirement.areaForWorkplace = requirement.area / department.requirement.numberOfWorkplaces
        }
      } else {
        if (requirement.areaForWorkplace) {
          department.requirement.numberOfWorkplaces = Math.floor(department.requirement.area / requirement.areaForWorkplace)
        } else if (requirement.area) {
          department.requirement.numberOfWorkplaces = Math.floor(requirement.area / department.requirement.areaForWorkplace)
        }
      }
    }
  }

  dynamicCalculationOfTeamsRequirementsFirst (department) {
    const teams = department.organisationalUnits

    const workplacesPerTeams = Math.floor(department.requirement.numberOfWorkplaces / teams.length)

    let rest = department.requirement.numberOfWorkplaces - workplacesPerTeams * teams.length

    for (let i = 0; i < teams.length; i++) {
      let workplacesPerTeam = workplacesPerTeams
      if (rest > 0) {
        workplacesPerTeam += 1
        rest -= 1
      }

      this.updateNumberOfWorkplacesInTeam(teams[i], workplacesPerTeam)
    }
  }

  dynamicCalculationOfTeamsRequirementsAfter (department) {
    const teams = department.organisationalUnits

    const originalDepartment = this.props.allUnits.find(unit => unit.id === department.id)

    if (originalDepartment && originalDepartment.requirement.numberOfWorkplaces === department.requirement.numberOfWorkplaces)
      return

    const workplacesPerTeams = Math.floor(department.requirement.numberOfWorkplaces / teams.length)

    let rest = department.requirement.numberOfWorkplaces - workplacesPerTeams * teams.length

    for (let i = 0; i < teams.length; i++) {
      let workplacesPerTeam = workplacesPerTeams
      if (rest > 0) {
        workplacesPerTeam += 1
        rest -= 1
      }

      teams[i].requirement.numberOfWorkplaces = workplacesPerTeam
      teams[i].requirement.numberOfEmployees = Math.floor(teams[i].requirement.numberOfWorkplaces * teams[i].requirement.deskSharingRatio)
    }
  }

  updateNumberOfWorkplacesInTeam (team, workplaces) {
    const updatedTeam = this.state.unitsToUpdate.find(unit => unit.id === team.id)

    if (updatedTeam && updatedTeam.requirement) {

    } else {
      team.requirement.numberOfWorkplaces = workplaces

      team.requirement.numberOfEmployees = Math.floor(team.requirement.numberOfWorkplaces * team.requirement.deskSharingRatio)
    }
  }

  updateDepartmentRequirements (department) {

    const teams = department.organisationalUnits

    const numberOfEmployees = teams.map(team => team.requirement.numberOfEmployees)
      .reduce((a, b) => Number(a) + Number(b), 0)
    const deskSharingRatio = (teams.map(team => team.requirement.deskSharingRatio)
      .reduce((a, b) => Number(a) + Number(b), 0)) / teams.length

    department.requirement.numberOfEmployees = numberOfEmployees
    department.requirement.deskSharingRatio = deskSharingRatio.toFixed(2)
  }

  updateSuperDepartmentRequirements (superDepartment) {
    const departments = superDepartment.organisationalUnits

    const numberOfEmployees = departments.map(department => department.requirement.numberOfEmployees)
      .reduce((a, b) => Number(a) + Number(b), 0)
    const deskSharingRatio = departments.map(department => department.requirement.deskSharingRatio)
      .reduce((a, b) => Number(a) + Number(b), 0) / departments.length
    const numberOfWorkplaces = departments.map(department => department.requirement.numberOfWorkplaces)
      .reduce((a, b) => Number(a) + Number(b), 0)
    const areaPerWorkplace = departments.map(department => department.requirement.areaForWorkplace)
      .reduce((a, b) => Number(a) + Number(b), 0) / departments.length
    const area = departments.map(department => department.requirement.area)
      .reduce((a, b) => Number(a) + Number(b), 0)

    superDepartment.requirement.numberOfEmployees = numberOfEmployees
    superDepartment.requirement.deskSharingRatio = deskSharingRatio.toFixed(2)
    superDepartment.requirement.numberOfWorkplaces = numberOfWorkplaces
    superDepartment.requirement.areaForWorkplace = areaPerWorkplace.toFixed(2)
    superDepartment.requirement.area = Number(area)
      .toFixed(2)
  }

  //endregion

  render () {
    console.log("render")
    const units = this.state.units

    this.dynamicCalculation(units)

    this.setFloorDataToUnits(units)

    const showStyles = !this.props.useLens && this.props.advancedSettings

    return (
      <React.Fragment>
        <ModalConsumer>
          {({ showModal }) => (
            <React.Fragment>
              <TablePageButtonToolBar>
                <ButtonGroup className={'add-delete-buttons'}>
                  <Button variant={'text'}
                          icon={iconTypes.addUnit}
                          disabled={this.isChanged()}
                          onClick={() => this.addOrganisationalUnit(null)}>
                    Add Group
                  </Button>
                  <Button variant={'text'}
                          icon={iconTypes.plus}
                          onClick={() => this.addOrganisationalUnit(this.state.selectedOrganisationalUnit)}
                          disabled={!this.state.selectedOrganisationalUnit || this.state.selectedOrganisationalUnit.type === 'TEAM' || this.isChanged()}>
                    Add Item
                  </Button>
                  <Button variant={'text'}
                          icon={iconTypes.delete}
                          onClick={() => showModal(DeletionDialog, {
                            onConfirm: this.deleteOrganisationalUnit,
                            entity: 'Organisational Unit',
                            entityName: this.state.selectedOrganisationalUnit.name,
                          })}
                          disabled={!this.state.selectedOrganisationalUnit || this.isChanged()}>
                    Delete Item
                  </Button>
                </ButtonGroup>
                {this.props.advancedSettings ?
                  <ButtonGroup className={'import-export-buttons'}>
                    <Button variant={'contained'} onClick={() => showModal(XlsxUploadDialog, { upload: this.props.uploadCorporateSettingsFile, replace: false })}>
                      Add Data...
                    </Button>
                    <Button onClick={() => showModal(XlsxUploadDialog, { upload: this.props.uploadCorporateSettingsFile, replace: true })}>
                      Replace Data...
                    </Button>
                    <Button onClick={() => this.props.downloadCorporateDataAsXLSX()}>
                      Export XLSX
                    </Button>
                  </ButtonGroup>
                  : <div/>}
                <ButtonGroup className={'discard-confirm-buttons'}>
                  <Button onClick={() => this.resetData()} disabled={!this.isChanged()}>Discard</Button>
                  <Button variant={'contained'} onClick={() => this.updateTable()} disabled={!this.isChanged()}>Confirm</Button>
                </ButtonGroup>
              </TablePageButtonToolBar>
              <CorporateTable data={units}
                              showStyles={showStyles}
                              onUnitChange={(id, unit) => this.updateOrganisationalUnit(id, unit)}
                              assignUnitToFloor={(unit, floor) => this.updateAssignedFloor(unit, floor)}
                              selectedOrganisationalUnit={this.state.selectedOrganisationalUnit}
                              setSelectedOrganisationalUnit={(unit) => this.setSelectedOrganisationalUnit(unit)}
                              getExpandedRows={(flatRows) => this.getExpandedRows(flatRows)}
                              changeLockDialog={(unitId, columnLocked) => this.changeLockDialog(showModal, unitId, columnLocked)}
                              useEmployee={this.props.useEmployee}/>
            </React.Fragment>
          )}
        </ModalConsumer>
      </React.Fragment>
    )
  }

  updateOrganisationalUnit (id, unit) {
    console.log("updateOrganisationalUnit**")
    unit['id'] = id

    if (unit.name) {
      unit.fourLetterName = unit.name.substr(0, 4)
        .toUpperCase()
      console.log("ApplicationPRo")
      console.log(unit.applicationProfile)
    }



    const unitsToUpdate = this.state.unitsToUpdate

    let updatedMinUnit = unitsToUpdate.find(unit => unit.id === id)

    if (updatedMinUnit) {
      updateObject(updatedMinUnit, unit)
    } else {
      unitsToUpdate.push(unit)
    }

    const units = cloneDeep(this.state.units)

    let updatedUnit = recursiveFindFlatMap(units, 'organisationalUnits', unit => unit.id === id)

    if (updatedUnit) {
      updateObject(updatedUnit, unit)
    }

    this.dynamicLocking(units)

    this.dynamicCalculation(units)

    if (!equal(this.state.units, units)) {
      this.setState({ unitsToUpdate: unitsToUpdate, units: units })
    }
  }

  updateAssignedFloor (unit, floor) {
    this.removeOldUpdateAssignment(this.state.createFloorAssignments)
    this.removeOldUpdateAssignment(this.state.updateFloorAssignments)
    this.removeOldUpdateAssignment(this.state.deleteFloorAssignments)

    const currentAssignment = this.props.placedDepartments.find(department => unit.id === department.id)

    if (floor) {
      const assignment = {
        buildingId: floor.buildingId,
        floorId: floor.id,
        index: 0,
      }
      if (!currentAssignment) {
        const createFloorAssignments = this.state.createFloorAssignments
        createFloorAssignments.push({ id: unit.id, assignment: assignment })

        this.setState({ createFloorAssignments: createFloorAssignments })
      } else {
        const updateFloorAssignments = this.state.updateFloorAssignments
        updateFloorAssignments.push({ id: unit.id, assignment: assignment, buildingId: unit.buildingId, floorId: unit.floorId })

        this.setState({ updateFloorAssignments: updateFloorAssignments })
      }
    } else {
      const deleteFloorAssignments = this.state.deleteFloorAssignments
      deleteFloorAssignments.push({ id: unit.id, buildingId: unit.buildingId, floorId: unit.floorId })

      this.setState({ deleteFloorAssignments: deleteFloorAssignments })
    }
  }

  removeOldUpdateAssignment (assignments, id) {
    const assignmentIndex = assignments.findIndex(assignment => assignment.id === id)
    if (0 <= assignmentIndex) {
      assignments.splice(assignmentIndex, 1)
    }
  }

  getExpandedRows (flatRows) {

    if (this.state.selectedOrganisationalUnit) {
      return flatRows.filter(row => row.original.id === this.state.selectedOrganisationalUnit.parentId)
    }
    return []
  }

  setSelectedOrganisationalUnit (unit) {
    this.setState({ selectedOrganisationalUnit: unit })
  }

  getRandomColorValue () {
    return (Math.random() * (7 - 2) + 2) / 10
  }

  addOrganisationalUnit (unit) {
    let newOrgUnit = null

    if (!unit) {
      newOrgUnit = newOrganisationalUnit
      newOrgUnit.organisationalUnits[0].color = { red: this.getRandomColorValue(), green: this.getRandomColorValue(), blue: this.getRandomColorValue(), alpha: .8 }
      newOrgUnit.organisationalUnits[0].organisationalUnits[0].color = {
        red: newOrgUnit.organisationalUnits[0].color.red * 1.3,
        green: newOrgUnit.organisationalUnits[0].color.green * 1.3,
        blue: newOrgUnit.organisationalUnits[0].color.blue * 1.3, alpha: .1,
      }
    } else if (unit.type === 'SUPER_DEPARTMENT') {
      newOrgUnit = newOrganisationalUnit.organisationalUnits[0]
      newOrgUnit['parentId'] = unit.id
      newOrgUnit['color'] = { red: this.getRandomColorValue(), green: this.getRandomColorValue(), blue: this.getRandomColorValue(), alpha: .8 }
      newOrgUnit.organisationalUnits[0].color = {
        red: newOrgUnit.color.red * 1.3,
        green: newOrgUnit.color.green * 1.3,
        blue: newOrgUnit.color.blue * 1.3, alpha: .1,
      }
    } else if (unit.type === 'DEPARTMENT') {
      newOrgUnit = newOrganisationalUnit.organisationalUnits[0].organisationalUnits[0]
      newOrgUnit['parentId'] = unit.id
      newOrgUnit['color'] = { red: unit.color.red * 1.3, green: unit.color.green * 1.3, blue: unit.color.blue * 1.3, alpha: .4 }
    }

    this.props.addOrganisationalUnit(newOrgUnit)
  }

  deleteOrganisationalUnit = () => {
    this.props.deleteOrganisationalUnit(this.state.selectedOrganisationalUnit.id)
    this.setSelectedOrganisationalUnit(null)
  }

  handleFloorAssignment (unit, floor) {
    if (floor) {
      const assignment = {
        buildingId: floor.buildingId,
        floorId: floor.id,
        index: 0,
      }
      if (!unit.floorId) {
        this.props.createOrganisationalUnitAssignment(unit.id, assignment)
      } else {
        this.props.updateOrganisationalUnitAssignment(unit.buildingId, unit.floorId, unit.id, assignment)
      }
    } else {
      this.props.deleteOrganisationalUnitAssignment(unit.buildingId, unit.floorId, unit.id)
    }
  }
}

let mapStateToProps = (state) => {
  return {
    units: state.organisationalUnits.unitsTree,
    allUnits: state.organisationalUnits.units,
    lastAddedUnit: state.organisationalUnits.lastAddedUnit,
    projectId: state.appState.projectId,
    useEmployee: state.settings.appSettings.employeeBasedCalculation,
    advancedSettings: state.settings.appSettings.advancedSettings,
    useLens: state.settings.appSettings.useLens,

    placedDepartments: state.placedOrganisationalUnits.placedDepartments,
  }
}

let mapDispatchToProps = {
  generateAll: generateAll,

  updateOrganisationalUnit: updateOrganisationalUnit,
  updateOrganisationalUnits: updateOrganisationalUnits,
  addOrganisationalUnit: addOrganisationalUnit,
  deleteOrganisationalUnit: deleteOrganisationalUnit,
  downloadCorporateDataAsXLSX: downloadCorporateDataAsXLSX,
  uploadCorporateSettingsFile: uploadCorporateSettingsFile,

  deleteAllOrganisationalUnits: deleteAllOrganisationalUnits,

  createOrganisationalUnitAssignment: createOrganisationalUnitAssignment,
  updateOrganisationalUnitAssignment: updateOrganisationalUnitAssignment,
  deleteOrganisationalUnitAssignment: deleteOrganisationalUnitAssignment,
}

export default connect(mapStateToProps, mapDispatchToProps)(CorporateData)