import { Group, Vector2, Vector3 } from 'three'
import { addMouseEvents, removeMouseEvents } from '../common/MouseEvents'
import { addKeyEvents, keyCodes, removeKeyEvents } from '../common/KeyEvents'
import { representationTypes } from '../config/RepresentationTypes'
import { controllerActions } from './GeometryController'

import { MagicGuideLine } from '../helper/MagicGuideLine'
import { PreviewVertex } from '../helper/PreviewVertex'

import { PreviewStamp } from '../helper/PreviewStamp'
import { PreviewReferenceEdge } from '../helper/PreviewReferenceEdge'
import { PreviewAxis } from '../helper/PreviewAxis'

import { SelectController } from './SelectController'
import { createControllerByRepresentationType } from '../factories/ControllerFactory'
import { createGeometryByRepresentationType } from '../factories/GeometryFactory'
import { removeAllChildren } from '../../../common/three/drawing/drawConstants'
import { addAfterUpdate } from '../common/RenderLoop'
import { ImageController } from './ImageController'
import { getArea, getOrtoProjectionOnSegment, getWithOffset, isVerticesContainsAllVertices, isVerticesContainsVertex } from '../helper/GeometryHelper'
import { fetchFloorPlanAnalyticsWithResponse, updateGeometriesWithPromise, updateSpaceGeometriesWithPromise } from '../../actions/Backend/drawingToolActions'
import store from '../../../store'
//import { checkStep, getGeometriesOfRepresentationType } from '../../components/DrawingToolMenuBar'
import { setImageScaleWithPromise } from '../../../buildings/actions/actions'



export class DrawingToolController {
  representationGroups = new Map()
  representationController = new Map()

  currentPosition = new Vector3()
  lastPosition = new Vector3()
  currentCameraPosition = new Vector2()
  cameraFactor= new Vector2()
  catchPlane = null
  fineAxisPlane = null

  currentTool = representationTypes.select
  currentGeometries = []
  lastCameraZoom = null

  currentSpace = undefined   //infrastructure, blocked, service
  changesPerFrame = false

  showAxis = true
  geometryChanged=false
  oldScale=1.0
  oldTool=""
  toolGroup=""
  constructor (mount, scene, camera, raycaster, orbitControls, renderer, repTypes = []) {
    console.log("CONSTRUCT DRAWINGTOOLCONTROLLER")
    this.mount = mount
    this.scene = scene
    this.camera = camera
    this.raycaster = raycaster
    this.orbitControls = orbitControls
    this.renderer = renderer

    this.fineAxisPlane=new Group()
    this.fineAxisPlane.name="fineAxisPlane"
    this.scene.add(this.fineAxisPlane)

    //this.scene.children.add()
    addAfterUpdate(() => {
      if (this.changesPerFrame)
        this.saveChangesPerFrame()
    })

    this.selectController = new SelectController(this,
      [
        representationTypes.scale,
        representationTypes.outline,
        representationTypes.wall,
        representationTypes.space,
        representationTypes.axis,
        representationTypes.door,
        representationTypes.window,
        representationTypes.pillar,
        ])
    this.imageController = new ImageController(this)

    addMouseEvents(mount.current,
      event => this.onMouseDown(event),
      event => this.onMouseMove(event),
      null,
      event => this.onMouseWheel(event))
    addMouseEvents(document,
      null,
      null,
      event => this.onMouseUp(event))
    addKeyEvents(event => this.onKeyDown(event),
      event => this.onKeyUp(event))

    this.catchPlane = this.scene.children.find(child => child.name === 'catchPlane')
    //let fineAxisPlane = this.scene.children.find(child => child.name === 'fineAxisPlane')
    //if(this.fineAxisPlane==null) this.fineAxisPlane =

    this.magicGuideLine = new MagicGuideLine(this.scene)
    //this.previewEdge = new PreviewEdge(this.scene)
    this.previewVertex = new PreviewVertex(this.scene)
    //this.previewOtherVertex = new PreviewOtherVertex(this.scene)
    this.previewStamp = new PreviewStamp(this.scene)
    this.previewReferenceEdge = new PreviewReferenceEdge(this.scene)
    this.previewAxis=new PreviewAxis(this.scene)

    this.orbitControls.addOnZoomObserver((zoom) => this.updateGeometryScale(zoom)) //addEventListener(orbitControlActions.change, () => this.updateCamera())

    this.setUpRepresentationTypeGroup(repTypes)
    this.setUpRepresentationTypeController(repTypes)
  }

  setUpRepresentationTypeGroup (representationTypes) {
    console.log("SETUP REPRESENTATION GROUPS")
    representationTypes.forEach(representationType => {
      const group = new Group()
      group.name = representationType + 'Group'
      this.representationGroups.set(representationType, group)
      this.scene.add(group)
      console.log(group.name+"  "+group.visible)
    })
  }

  setUpRepresentationTypeController (representTypes) {
    representTypes.forEach(representationType => {
      const controller = createControllerByRepresentationType(this, representationType, this.representationGroups.get(representationType))
      console.log("repType: "+representationType+" controller:"+controller)
      if (controller) {
        this.representationController.set(representationType, controller)
      }
    })
  }

  deconstructor () {
    console.log("deconstruct")
    removeMouseEvents(this.mount.current,
      event => this.onMouseDown(event),
      event => this.onMouseMove(event),
      event => this.onMouseUp(event),
      event => this.onMouseWheel(event))
    removeKeyEvents(event => this.onKeyDown(event),
      event => this.onKeyUp(event))
  }

  // Private
  onMouseDown (event) {
    console.log("geometryChanged:"+this.geometryChanged)
    //this.printObject(event)
    console.log("CP: "+this.currentPosition.x+" : " +this.currentPosition.y)
    // Left Click
    if (event.button === 0) {

      // Single Click
      if (event.detail === 1) {
        console.log("single click")
        if (!this.callFunctionPrimaryForCurrentGeometries(controllerActions.onLeftMouseDown, event)) {
          this.callFunctionForSelection(controllerActions.onLeftMouseDown, event)
        }
      }
      // Double Click
      else if (event.detail === 2) {
        if (!this.callFunctionPrimaryForCurrentGeometries(controllerActions.onLeftMouseDoubleClick, event))
          this.callFunctionForSelection(controllerActions.onLeftMouseDown, event)
      }
    }
    // Right Click
    else if (event.button === 2) {
      console.log("Detail: "+event.detail)
      if (event.detail === 1) {

        if (!this.callFunctionPrimaryForCurrentGeometries(controllerActions.onRightMouseDown, event))
          this.callFunctionForSelection(controllerActions.onRightMouseDown, event)
      }
      // Double Click
      else if (event.detail === 2) {
        if (!this.callFunctionPrimaryForCurrentGeometries(controllerActions.onRightMouseDoubleClick, event))
          this.callFunctionForSelection(controllerActions.onRightMouseDoubleClick, event)
      }
    }
  }

  // Private
  onMouseMove (event) {
    //this.updateSpaces()
    //console.log("mousemove: "+this.test)
    //console.log("toolgroup: "+this.oldTool)
    this.storeCurrentPosition(event)

    if (!this.callFunctionPrimaryForCurrentGeometries(controllerActions.onMouseMove, event))//
      if (!this.callFunctionForAllRepresentationTypes(controllerActions.onMouseMove, event))//
        this.callFunctionForSelection(controllerActions.onMouseMove, event)//
  }

  // Private
  onMouseUp (event) {
    if (event.button === 0) {
      // Single Click
      if (event.detail === 1) {
        if (!this.callFunctionPrimaryForCurrentGeometries(controllerActions.onLeftMouseUp, event)) {
          this.callFunctionForSelection(controllerActions.onLeftMouseUp, event)
        }
      }
    } else if (event.button === 2) {
      if (this.currentPosition.distanceToSquared(this.lastPosition) === 0) {
        this.callFunctionForSelection(controllerActions.onRightMouseUp, event)
      }
    }
  }

  onMouseWheel (event) {
    console.log("wheel"+event)
    this.callFunctionPrimaryForCurrentGeometries(controllerActions.onMouseWheel, event)
  }

  // Private
  onKeyDown (event) {
      console.log(event)

    switch (event.code) {
      case keyCodes.digit1:
        const aController = this.representationController.get(representationTypes.axis);
        this.updateSpaceGeometriesAndFetchAnalytics(aController)
        break
      case keyCodes.backspace:
        console.log("BACKSPACE check")
        this.callFunctionForCurrentGeometries(controllerActions.onBackspaceDown, event)
        break
      case keyCodes.escape:
        if (!this.callFunctionForCurrentGeometries(controllerActions.onEscapeDown, event))
          this.callFunctionForSelection(controllerActions.onEscapeDown, event, false)
        break
      case keyCodes.enter:
        console.log("ENTER")
        this.callFunctionForCurrentGeometries(controllerActions.onEnterDown, event)
        break
      case keyCodes.space:
        this.resetCamera()
        break
      case keyCodes.arrowLeft:
        this.callFunctionPrimaryForCurrentGeometries(controllerActions.onArrowLeftDown, event)
        break
      case keyCodes.arrowRight:
        this.callFunctionPrimaryForCurrentGeometries(controllerActions.onArrowRightDown, event)
        break
      case keyCodes.arrowDown:
        this.callFunctionPrimaryForCurrentGeometries(controllerActions.onArrowDownDown, event)
        break
      case keyCodes.arrowUp:
        this.callFunctionPrimaryForCurrentGeometries(controllerActions.onArrowUpDown, event)
        break
      case keyCodes.keyA:
        if (!this.callFunctionPrimaryForCurrentGeometries(controllerActions.onKeyADown, event))
          this.callFunctionForSelection(controllerActions.onKeyADown, event)
        break
      case keyCodes.keyI:
        console.log(this.renderer.info.memory)
        break
      case keyCodes.keyS:
        if (event.ctrlKey || event.metaKey) {
          if (event.altLeft) {
            this.downloadGeometries()
          } else {
            this.updateGeometries()
          }
          event.preventDefault()
        }
        break
      case keyCodes.keyY:
        if (event.ctrlKey || event.metaKey) {
          this.undoGeometries()
          event.preventDefault()
        }else{
          this.zoomOnBoundingBox()
        }
        break
      case keyCodes.keyZ:
        console.log("ZZZ")
        if (event.ctrlKey || event.metaKey) {
          this.redoGeometries()
          event.preventDefault()
        }else{
          this.zoomOnBoundingBox()
        }
        break
      case keyCodes.f12:
        console.log("121212")
      case keyCodes.metaLeft:
        break
      case keyCodes.altLeft:
        this.callFunctionPrimaryForCurrentGeometries(controllerActions.onAltDown, event)
        event.preventDefault()
        break
      case keyCodes.keyG:
        this.updateGuideLineDirection()
        console.log("gggg")
        break
      case keyCodes.keyV:
        const controller=this.representationController.get(representationTypes.outline)
        controller.toggleVertexMode()
        console.log("vvvv")
        break
      default:
        console.log(event.code)
        break
    }
  }

  // Private
  onKeyUp (event) {
    switch (event.code) {
      case keyCodes.altLeft:
        this.callFunctionForAllController(controllerActions.onAltUp, event)
        event.preventDefault()
        break
    }
  }

  // Private
  changeTool (representationType) {
    console.log("changeTool: "+representationType+" toolGroup: "+this.toolGroup)

    for( const [key,value] of this.representationController.entries()){
      let controller=value
      controller.setActive(false)
    }

    const sController = this.representationController.get(representationTypes.scale);
    if(sController.group){
      removeAllChildren(sController.group)
    }

    let newTool=""
    this.selectController.cancelCurrentActions(this.currentGeometries)
    const axisController = this.representationController.get(representationTypes.axis)
    let changed=false
    if(representationType.startsWith("mark")){
      this.toolGroup="mark"
      this.geometryChanged=true;
      axisController.setVisibility(false)
      this.currentSpace=representationType.slice(4)
      console.log("MARK: "+this.currentSpace)
      //representationType=representationTypes.space
      this.updateSpaces(1)
      newTool=representationType

    }else if(representationType.startsWith("axes")){
      const aController = this.representationController.get(representationTypes.axis);
      aController.mode=representationType
      console.log("toolGroup: "+this.toolGroup)
      if(this.toolGroup!="axes"){
        console.log("load AXES! "+this.currentSpace+"  showAxes: "+this.showAxis)
        this.updateSpaceGeometriesAndFetchAnalytics(aController)

      }

      this.toolGroup="axes"
      this.printSceneObjects()
      let controller = this.representationController.get(this.currentTool)
      if (controller) {
        controller.setActive(false)
      }
      aController.setVisibilities(true)
      //else console.log("show axes: false")
      this.updateSpaces(2)
      newTool=representationType
      console.log("REP TYPE: "+representationType)

    }else if ((representationType==="wall")
      ||(representationType==="outline")
      ||(representationType==="pillar")
      ||(representationType==="window")
      ||(representationType==="door")){

        this.toolGroup="edit"
        axisController.setVisibilities(false)
        console.log("EDIT")
        //this.currentSpace=representationType.slice(4)
        //newTool=representationType.slice(4)
        newTool=representationType
        console.log("currentTool:"+this.currentTool)
        this.updateSpaces(0)
    }else{
      newTool = representationType
      if(newTool==="scale"){
        this.toolGroup="walls"


        axisController.setVisibilities(false)
        this.updateSpaces(0)
      }
      console.log("repptype: "+representationType)
      console.log("toolGroup:"+this.toolGroup)
    }
    this.oldTool=this.currentTool

    this.setCurrentTool(newTool)
    this.currentTool=newTool
    this.magicGuideLine.setOrientation()
    if(this.currentTool==="outline"){
      this.zoomOnBoundingBox()
    }

    console.log("current Tool: "+this.currentTool+"  group: "+this.toolGroup+"  new:" +newTool)
  }

  updateNothingAndFetchAnalytics(aController){
    console.log("update nothing Fetching analytics")

    store.dispatch(fetchFloorPlanAnalyticsWithResponse()).then((result) => {
      console.log("--------");
      console.log(result);
      console.log("********");
      aController.updateByAnalytics(result);
      this.showAxis=false;
    }).catch((error) => {
      console.error("Failed in the process", error);
    });
  }

  updateGeometriesOnly(){
    console.log("update geometries")
    const geometriesJSON = this.getGeometriesAsJSON()
    console.log("geometries JSON")
    console.log(geometriesJSON)

    console.log("Fetching analytics")
    store.dispatch(updateGeometriesWithPromise())

    this.showAxis=false;
  }

  updateScaleWithPromise(scale){
    console.log("updateScale: "+scale)
    if(scale>0)
      store.dispatch(setImageScaleWithPromise(scale))

  }

  updateGeometriesAndFetchAnalytics(aController){
    //updateSpaceGeometriesWithPromise()
    //store.dispatch(updateSpaceGeometriesWithPromise())
    console.log("update spaces")

    console.log("Fetching analytics")
    store.dispatch(updateGeometriesWithPromise()).then(() => {
      console.log("UPDATE SPACE GEO");
      return store.dispatch(fetchFloorPlanAnalyticsWithResponse());
    }).then((result) => {
      console.log("--------");
      console.log(result);
      console.log("********");
      aController.updateByAnalytics(result);
      aController.setVisibility(true);
    }).catch((error) => {
      console.error("Failed in the process", error);
    });


    this.showAxis=false;
  }
  updateSpaceGeometriesAndFetchAnalytics(aController){

    console.log("update spaces")

    console.log("Fetching analytics")
    store.dispatch(updateSpaceGeometriesWithPromise()).then(() => {
      console.log("UPDATE SPACE GEO");
      return store.dispatch(fetchFloorPlanAnalyticsWithResponse());
    }).then((result) => {
      console.log("--------");
      console.log(result);
      console.log("********");
      aController.updateByAnalytics(result);
      aController.setVisibility(true);
    }).catch((error) => {
      console.error("Failed in the process", error);
    });


    this.showAxis=false;
  }

  // Private
  callFunctionForSelection (controllerFunction, event, onlyBySelectedTool = true) {
    //console.log("SELECTIONA: "+this.currentTool)
    //console.log("sel: "+controllerFunction)

    if ((this.currentTool === representationTypes.select || !onlyBySelectedTool) &&
      this.selectController[controllerFunction]) {
      this.selectController[controllerFunction](event, this.currentGeometries)
    }else if (this.currentTool.startsWith("mark")) {
      let cSpaceGeometry = this.getRaycastHitObjectByRepresentationTypes([representationTypes.space], [])
      if (cSpaceGeometry != null) {
        console.log("Type under Mouse: " + cSpaceGeometry.getSpaceType())
      }
      console.log("currentTool: " + this.currentTool + "   controllerFunction:  " + controllerFunction)
      if (controllerFunction === "onLeftMouseDown") {
        let spaceGeometry = this.getRaycastHitObjectByRepresentationTypes([representationTypes.space], [])
        if (spaceGeometry != null) {
          let pos = this.currentPosition
          let type = this.currentTool.slice(4)
          console.log("Mark: " + type + "   at: " +
            pos.x + ":" + pos.y)
          if (spaceGeometry.getSpaceType() != type) {
            spaceGeometry.setSpaceType(pos, type)
            this.updateSpaces(1)
            this.showAxis = true
            //console.log("spaceGeometry: "+spaceGeometry.representationType)
            console.log("spaceType: " + spaceGeometry.getSpaceType())
            console.log("MarkerPos: " + spaceGeometry.markerPos)
          }
        } else {
          console.log("no spaceGeometry!")
        }
      }
    }else if (this.currentTool.startsWith("axes")) {
      console.log("AXES AXES");

    }
  }

  // Private
  callFunctionPrimaryForCurrentGeometries (controllerFunction, event) {

    //console.log(event+" --- "+controllerFunction+"  currentLength: "+this.currentGeometries.length)
    if (this.currentGeometries.length) {

      console.log("click: geometries :")
      return this.callFunctionForCurrentGeometries(controllerFunction, event)
    } else {
      console.log("click: no geometries :")
      this.callFunctionForCurrentRepresentationType(controllerFunction, event)
    }
  }

  // Private
  callFunctionForCurrentGeometries (controllerFunction, event) {
    let executeAction = false

    console.log("controllerFunction:  "+controllerFunction+"   cclength:"+this.currentGeometries.length)

    for (let i = this.currentGeometries.length - 1; i >= 0; i--) {
      const geometry = this.currentGeometries[i]
      const representationController = this.representationController.get(geometry.representationType)

      if (representationController[controllerFunction]) {

        executeAction |= representationController[controllerFunction](event, geometry, this.currentGeometries)
      }
    }
    return executeAction
  }

  // Private
  callFunctionForCurrentRepresentationType (controllerFunction, event) {
    //console.log("tool:"+this.currentTool)

    let tool=this.currentTool
    if(tool.startsWith("axes")){
      tool="axis"
    }

    const representationController = this.representationController.get(tool)
    //console.log("controllerFunction: "+ controllerFunction + "  tool: "+tool+"  rep:"+representationController )

    if (representationController && representationController[controllerFunction]){

      //console.log("LEFT LEFT "+event+":::"+this.currentGeometries.length)
      //console.log(representationController+"  function:"+controllerFunction)

      representationController[controllerFunction](event, null, this.currentGeometries)
    }
    if(controllerFunction === "onRightMouseDown"){
      //wird nicht aufgerufen weil kein representationcontroller
      //console.log("RIGHT")
      //console.log(representationController+"  function:"+controllerFunction)
      //console.log(representationController[controllerFunction])
    }
    if(controllerFunction === "onMouseMove"){
      //wird nicht aufgerufen weil kein representationcontroller
      //console.log("MOVE MOVE")
      //console.log(representationController+"  function:"+controllerFunction)
      //console.log(representationController[controllerFunction])
    }
  }

  callFunctionForAllController (controllerFunction, event) {

    const controllers = [...this.representationController.values()]

    controllers.filter(controller =>
      controller[controllerFunction])
      .forEach(controller => controller[controllerFunction](event, null, this.currentGeometries))
  }

  // Private
  callFunctionForAllRepresentationTypes (controllerFunction, event) {
    //console.log("REP TYPE")

    this.representationController.forEach((representationType, controller) => {
      //if (controller && controller[controllerFunction])
        //console.log("Contr: "+controller+"  Contr[FN]: "+controller[controllerFunction])
      if (controller && controller[controllerFunction] && this.currentGeometries.includes(representationType))
        controller[controllerFunction](event, null, this.currentGeometries)
    })

    return false
  }

  // Private
  updateCamera () {
    if (this.camera.zoom !== this.lastCameraZoom) {
      this.updateGeometryScale(this.camera.zoom)

      this.lastCameraZoom = this.camera.zoom
    }
  }

  // Private
  updateGeometryScale (currentCameraZoom) {

    let pos=this.camera.position

    let scale = 3 / (currentCameraZoom < geometryScale.minZoomLevel ?
      (currentCameraZoom > geometryScale.maxZoomLevel ? currentCameraZoom : geometryScale.maxZoomLevel) :
      geometryScale.minZoomLevel)

    console.log("CAMERA: pos:"+pos.x+","+pos.y+"  zoom:"+this.camera.zoom+"  SCALE:"+scale);

    this.getGeometries()
      .forEach(geometry => {
        geometry.updateScale(scale)
      })
    //this.imageController.updateScale(scale)

    this.magicGuideLine.updateScale(scale)
    this.previewVertex.updateScale(scale)
    this.previewStamp.updateScale(scale)

    console.log(this.getGeometries())
  }

  getCurrentPosition () {
    return this.currentPosition.clone()
  }

  getImageScale(){
    this.imageController.getScale()
  }
  // Movement between to updates
  getMouseDeltaMovement () {
    return new Vector2(this.currentPosition.x - this.lastPosition.x, this.currentPosition.y - this.lastPosition.y)
  }

  storeCurrentPosition (event) {
    const { clientWidth: width, clientHeight: height, offsetTop: top, offsetLeft: left } = this.mount.current
    const { clientX: mousePositionX, clientY: mousePositionY } = event
    //console.log("ShowAxes: "+this.showAxis)
    //console.log("scale: "+this.imageController.getImageScale())


    let realPosition = this.screenToReal(new Vector2(mousePositionX, mousePositionY))
    //console.log("screenToReal: "+realPosition.x+" :: "+realPosition.y)

    let mousePosition = this.realToScreen(realPosition)
    //console.log("realToScreen: "+mousePosition.x+" :: "+mousePosition.y)

    const x = ((mousePositionX - left) / width) * 2 - 1     // - 1.0 bis 1.0
    const y = -(((mousePositionY - top) / height) * 2 - 1)  // - 1.0 bis 1.0
    let lastCurrentPosition = new Vector2(x,y)
    //console.log("mouse xy:  "+x+"::"+y)
    this.raycaster.setFromCamera(new Vector2(x, y), this.camera)

    //GlobalZoomCenter = mouse
    //localZoomCenter = camera
    let campos=this.camera.position
    //console.log("cam pos: "+campos.x+" .. "+campos.y+" .. "+campos.z)
    //console.log("camscreenpos: "+pos.x+" .. "+pos.y+" .. "+pos.z)
    let mouseXOnScreen = (mousePositionX - left) - 0.5*(width);
    let mouseYOnScreen = (top - mousePositionY) + 0.5*(height);


    //let mouseXOnScreenScaled=(mouseXOnScreen)*this.camera.zoom;
    //let mouseYOnScreenScaled=(mouseYOnScreen)*this.camera.zoom;

    //console.log("MOUSE: "+mouseXOnScreen+"::"+mouseYOnScreen)
    //console.log("MOUSE scaled: "+mouseXOnScreenScaled+"::"+mouseYOnScreenScaled)

    const intersect = this.raycaster.intersectObject(this.catchPlane)


    if (intersect.length) {
      this.lastPosition = this.currentPosition.clone()

      this.currentPosition.x = intersect[0].point.x
      this.currentPosition.y = intersect[0].point.y

      let cameraFactorX=x/this.currentPosition.x
      let cameraFactorY=y/this.currentPosition.y


      this.cameraFactor=new Vector2(cameraFactorX,cameraFactorY)
      let zoom=this.camera.zoom;
      //console.log("factor xy:  "+this.cameraFactor.x+"::"+this.cameraFactor.y)
      //console.log("zoom: "+zoom)


      for(let i=0; i<intersect.length; i++){
        let x=intersect[i].point.x
        let y=intersect[i].point.y
        let z=intersect[i].point.z

        //console.log("RAY"+i+": "+x+"::"+y+"::"+z)


        //console.log("mouseOnScreen: "+mouseXOnScreen +" :: "+mouseYOnScreen)
        //console.log("pannedMouseOnScreen: "+mouseXOnScreen+campos.x +" :: "+mouseYOnScreen+campos.y)
        //console.log("factorX: "+(mouseXOnScreen/x) +" :: "+(mouseYOnScreen/y) )

        let zoomfactor=zoom*8.0
        //console.log("factor: "+zoomfactor)

        //console.log("posOnScreen: "+(mouseXOnScreen/zoomfactor)+" :: "+(mouseYOnScreen/zoomfactor))

        //console.log(campos.x+" :: "+campos.y)
        //console.log("pannedPosOnScreen: "+((mouseXOnScreen+campos.x)/zoomfactor)
        //  +" :: "+((mouseYOnScreen+campos.y)/zoomfactor))

      }


      console.log("current  "+this.currentPosition.x+"::"+this.currentPosition.y)

    }


  }

  getRaycastHitObjectForGeometryByGeometryTypes (geometry, geometryTypes, ignoreGeometryTypes = []) {
    return this.getRaycastHitObjectByAttribute('geometryType', geometryTypes, [geometry], ignoreGeometryTypes)
  }

  getRaycastHitObjectByGeometryTypes (geometryTypes, ignoreGeometryTypes = []) {
    return this.getRaycastHitObjectByAttribute('geometryType', geometryTypes, this.getGeometries(), ignoreGeometryTypes)
  }

  getRaycastHitObjectByRepresentationTypes (representationTypes, ignoreGeometryTypes = []) {
    return this.getRaycastHitObjectByAttribute('representationType', representationTypes, this.getGeometries(), ignoreGeometryTypes)
  }

  getRaycastHitObjectsByRepresentationTypes (representationTypes, ignoreGeometryTypes) {
    return this.getRaycastHitObjectByAttribute('representationType', representationTypes, this.getGeometries(), ignoreGeometryTypes, true)
  }

  getRaycastHitObjectByRepresentationTypesAndGeometryType (representationTypes, geometryTypes, ignoreGeometryTypes = [], ignoreGeometries = []) {
    return this.getRaycastHitObjectByAttribute('geometryType', geometryTypes,
      this.getGeometriesByRepresentationTypes(representationTypes), ignoreGeometryTypes, false, ignoreGeometries)
  }

  // Private
  getRaycastHitObjectByAttribute (
    attribute,
    types,
    geometries = this.getGeometries(),
    ignoreGeometryTypes = [],
    multiple = false,
    ignoreGeometries = []) {

    const intersections = this.raycaster.intersectObjects(geometries, true)

    let raycastHitObject = null
    let raycastHitObjects = []

    if (!!intersections.length) {
      for (let i = 0; i < intersections.length; i++) {
        raycastHitObject = intersections[i].object

        while (raycastHitObject && !types.includes(raycastHitObject[attribute])) {
          raycastHitObject = ignoreGeometryTypes.includes(raycastHitObject.geometryType) ?
            null :
            raycastHitObject.parent
        }

        if (raycastHitObject &&
          !ignoreGeometries.includes(raycastHitObject) &&
          !(raycastHitObject['getGeometry'] && ignoreGeometries.includes(raycastHitObject.getGeometry()))) {
          if (!multiple)
            return raycastHitObject
          else if (!raycastHitObjects.includes(raycastHitObject))
            raycastHitObjects.push(raycastHitObject)
        }
      }
    }

    return multiple ? raycastHitObjects : null
  }

  getGeometries () {
    //console.log("get Geometries:")


    for(let i=0; i<this.representationGroups.values().length; i++){
      let representationGroup=this.representationGroups.values[i]
      let children = []
      for (let j=0; j<representationGroup.children.length; j++) {
        let cChild = representationGroup.children[j];
        let canAdd = true;
        if (cChild === null) {
          canAdd = false;
        } else if (cChild.representationType === null) {
          canAdd = false
        }
        if (canAdd){
          children.push(cChild)
        }
      }
      representationGroup.children=children

      //console.log(i+": "+representationGroup.name+"  n: "+representationGroup.children.length);
    }

    return Array.from(this.representationGroups.values())
      .map(group => group.children)
      .flat()
  }

  getGeometriesAsJSON () {
    console.log("GEOMETRIES AS Json: ")
    //console.log(this.getGeometries())

    return this.getGeometries()
      .map(geometry => geometry.toJSON())
  }

  getGeometryByUuid (geometryUuid) {
    return this.getGeometries()
      .find(geometry => geometry.uuid === geometryUuid)
  }

  getEdgeByUuid (edgeUuid, geometries = this.getGeometries()) {
    return geometries.map(geometry => geometry.edgeGroup.findEdgeByUuid(edgeUuid))
      .find(edge => edge)
  }

  getVertexByUuid (vertexUuid, geometries = this.getGeometries()) {
    return geometries.map(geometry => geometry.vertexGroup.findVertexByUuid(vertexUuid))
      .find(edge => edge)
  }


  getGeometriesByRepresentationType (representationType) {
    return this.getGeometriesByRepresentationTypes([representationType])
  }
  getGeometriesByRepresentationTypes (representationTypes = []) {
    let geometries = []
    representationTypes.forEach(representationType => {
      geometries = geometries.concat(this.representationGroups.get(representationType).children)
    })
    return geometries
  }

  getAllVertices () {
    console.log("getAllVertices")
    //console.log(this.getGeometries().length)
    const geometries=[]
    for(let i=0; i<this.getGeometries().length; i++){
      let geometry=this.getGeometries()[i]
      if(geometry!=null){
        //console.log(geometry)
        if(geometry.getVertices){
          //console.log(geometry.getVertices())
          geometries.push(geometry)
        }
      }else{
        console.log("NULL geometry")
      }
    }
    //console.log(ge)
    return geometries
      .map(geometry => geometry.getVertices())
      .flat()
    /*
    return this.getGeometries()
      .map(geometry => geometry.getVertices())
      .flat()

     */
  }

  getWallEdges () {
    const representationTypeArray = []
    representationTypeArray[0] = representationTypes.wall;
    representationTypeArray[1] = representationTypes.outline;

    return this.getGeometriesByRepresentationTypes(representationTypeArray)
      .map(geometry => geometry.getEdges())
      .flat()
  }

  getWallVertices () {
    const representationTypeArray = []
    representationTypeArray[0] = representationTypes.wall;
    representationTypeArray[1] = representationTypes.outline;

    return this.getGeometriesByRepresentationTypes(representationTypeArray)
      .map(geometry => geometry.getVertices())
      .flat()
  }

  getWallEdgePositions () {

    console.log("getWallEdgePositions")
    const representationTypeArray = []
    representationTypeArray[0] = representationTypes.wall;
    representationTypeArray[1] = representationTypes.outline;
    console.log(this.getGeometriesByRepresentationTypes(representationTypeArray))

    const edges=this.getGeometriesByRepresentationTypes(representationTypeArray)
      .map(geometry => geometry.getEdges()).flat()

    const posPairs=[]
    for(let iEdge=0; iEdge<edges.length; iEdge++){
      let posPair=[]
      let edge=edges[iEdge]
      let vertices=edge.getVertices()
      for(let iVertex=0; iVertex<vertices.length; iVertex++){
        let cPos3= vertices[iVertex].position
        let cPos2= new Vector2(cPos3.x,cPos3.y)
        posPair.push(cPos2)
      }
      posPairs.push(posPair)
    }
    console.log(posPairs)

    return posPairs

  }

  getWallAndOutlineGeommetries () {
    const representationTypeArray = []
    representationTypeArray[0] = representationTypes.wall;
    representationTypeArray[1] = representationTypes.outline;

    return this.getGeometriesByRepresentationTypes(representationTypeArray)
      .flat()
  }

  getOutlineGeometries () {
    const representationTypeArray = []
    representationTypeArray[0] = representationTypes.outline;

    return this.getGeometriesByRepresentationTypes(representationTypeArray)
      .flat()
  }

  getAllVerticesPositions () {
    console.log(this.getGeometries().length)

    return this.getGeometries()
      .map(geometry => geometry.getVerticesPositions())
      .flat()
  }

  getAllEdges () {
    return this.getGeometries()
      .map(geometry => geometry.getEdges())
      .flat()
  }

  setEnableZoom (enable) {
    this.orbitControls.enableZoom = enable
  }

  setGeometries (geometries, selectedGeometriesUuid, currentTool) {
    console.log("setGeometriessss: "+geometries.length)
    Array.from(this.representationGroups.values())
      .forEach(group => removeAllChildren(group))
    this.currentGeometries = []
    this.changeTool(currentTool)
    geometries.forEach(geometryJSON => {
      const representationType = geometryJSON.representationType
      console.log("REP drawingtoolController:"+representationType)
      let group = this.representationGroups.get(geometryJSON.representationType)
      if (!group) {
        console.warn('RepresentationType: ' + representationType + ' is not supported in this configuration\n' +
          'Add RepresentationType to the constructor')
        return
      }
      //console.log(group)

      const properties = geometryJSON.properties
      const geometryInstance = createGeometryByRepresentationType(representationType, group, geometryJSON.properties)

      if (!geometryInstance) {
        console.warn('Can\'t create instance of representation type ' + representationType)
        return
      }

      if (properties) {
        console.log("prop UUID:"+properties.uuid)
        if (properties.referenceEdge && geometryInstance['setReferenceEdge']) {
          console.log("refEdge: "+properties.referenceEdge)
          const referenceEdge = this.getEdgeByUuid(properties.referenceEdge)
          geometryInstance.setReferenceEdge(referenceEdge)
        }
        if (properties.referenceVertex && geometryInstance['setReferenceVertex']) {
          console.log("reference vertex")
          const referenceVertex = this.getVertexByUuid(properties.referenceVertex)
          geometryInstance.setReferenceVertex(referenceVertex)
        }
      }
      geometryInstance.fromJSON(geometryJSON)
      if (selectedGeometriesUuid &&
        selectedGeometriesUuid.includes(geometryInstance.uuid)) {
        geometryInstance.setActive(true)
        this.currentGeometries.push(geometryInstance)
      }
    })

    this.updateGeometryScale(this.camera.zoom)

    let nGeometries=this.currentGeometries.length+this.getGeometries().length
    console.log(nGeometries)
    console.log("ZOOM ON BBOX: "+this.currentTool)
    if(geometries.length<=1){
      this.zoomOnBoundingBox()
    }

    //geometries to backend
    this.updateGeometriesOnly()

    //let aController=this.representationController.get(representationTypes.axis);
    //this.updateGeometriesAndFetchAnalytics(aController)
  }

  downloadGeometries () {
    console.log("downloadGeometries")
    const geometriesJSON = this.getGeometriesAsJSON()
    const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(geometriesJSON))
    const downloadAnchorNode = document.createElement('a')
    downloadAnchorNode.setAttribute('href', dataStr)
    downloadAnchorNode.setAttribute('download', 'geometries.json')
    document.body.appendChild(downloadAnchorNode) // required for firefox
    downloadAnchorNode.click()
    downloadAnchorNode.remove()
  }

  saveChanges () {
    console.log("saveChanges")
    this.changesPerFrame = true
  }

  geometryToPositionArray(geometry){
    let posArray=[]
    for(let i=0; i<geometry.getVertices().length; i++){
      posArray.push(geometry.getVertices()[i].position)
    }
    return posArray;
  }

  prepareChanges(){
    console.log("prepare")
    const representationGroupScale= this.representationGroups.get(representationTypes.scale)
    const tapeMeasure=representationGroupScale.children
    if(tapeMeasure!=null){
      console.log("scaleExists: "+tapeMeasure.length)

      if(tapeMeasure.length>0){
        console.log(tapeMeasure[0])
        const tapeVertices=tapeMeasure[0].children[0].children
        console.log(tapeVertices)

      }

    }

    const representationGroupAxes= this.representationGroups.get(representationTypes.axis)
    const geometriesAxes=representationGroupAxes.children
    console.log("n Axes:" +geometriesAxes.length)
    console.log(geometriesAxes)
    const polylineGeometries=[]

    const represetationGroupDoor = this.representationGroups.get(representationTypes.door)
    const geometriesDoor = represetationGroupDoor.children

    const representationGroupWall = this.representationGroups.get(representationTypes.wall)
    const geometriesWall=representationGroupWall.children

    const representationGroupOutline = this.representationGroups.get(representationTypes.outline)
    const geometriesOutline=representationGroupOutline.children
    console.log("RPWALL: "+geometriesWall.length+"  RPOUTLINE: "+geometriesOutline.length)

    //todo: outlines in holes und outlines aufteilen
    const geometriesOutlineHoles = []
    const geometriesOutlineOut = []

    for(let iOutline=0; iOutline<geometriesOutline.length; iOutline++){
      let cGeometry=geometriesOutline[iOutline]
      let cPositions = this.geometryToPositionArray(cGeometry)
      console.log("area"+iOutline+":"+getArea(cPositions))
      for(let i=iOutline+1; i<geometriesOutline.length; i++){
        let nGeometry=geometriesOutline[i]
        let nPositions = this.geometryToPositionArray(nGeometry)
        if(isVerticesContainsAllVertices(cPositions,nPositions)){
          if(! geometriesOutlineHoles.includes(nGeometry)){
            geometriesOutlineHoles.push(nGeometry)
          }
        }
      }
    }

    for(let i=0; i<geometriesOutline.length; i++){
      let nGeometry=geometriesOutline[i]
      if(! geometriesOutlineHoles.includes(nGeometry)){
        geometriesOutlineOut.push(nGeometry)
      }
    }

    console.log("holes: "+geometriesOutlineHoles.length+"  outlines: "+geometriesOutlineOut.length)
    const wallsToDeleteArray = []
    let offset=0.1

    if(geometriesWall.length>0 & geometriesOutlineOut.length>0){
      for(let iWall=0; iWall<geometriesWall.length; iWall++){
        let insideCount = 0
        let outsideCount = 0
        let wallPositions = this.geometryToPositionArray(geometriesWall[iWall])

        for(let i=0; i<wallPositions.length; i++){
          let pos=wallPositions[i]
          //console.log("wall"+i+": x:"+pos.x+" y:"+pos.y)
          for(let iOutline=0; iOutline<geometriesOutlineOut.length; iOutline++){

            let outlinePositions = this.geometryToPositionArray(geometriesOutline[iOutline])


            let outlineOffsetPosition=getWithOffset(outlinePositions,offset)

            if(isVerticesContainsVertex(outlineOffsetPosition,pos)){
              //console.log("inside")
              insideCount++
            }else{
              console.log("outside")
            }
            for(let iInline=0; iInline<geometriesOutlineHoles.length; iInline++){
              let outlineInPositions =this.geometryToPositionArray(geometriesOutlineHoles[iInline])
              let outlineInOffPositions= getWithOffset(outlineInPositions,-offset)
              if(isVerticesContainsVertex(outlineInOffPositions,pos)){
                //console.log("inside")
                outsideCount++
              }else{
                //console.log("outside")
              }
            }
          }
        }
        //console.log("out:"+outsideCount+" in:"+insideCount)

        if(outsideCount>0 || insideCount<2){
          if(!wallsToDeleteArray.includes(geometriesWall[iWall])){
            wallsToDeleteArray.push(geometriesWall[iWall])
          }
        }
      }

      //console.log("delete: " + wallsToDeleteArray.length)

      for(let iToDelete=0; iToDelete<wallsToDeleteArray.length; iToDelete++){
        let deleteIndex=geometriesWall.indexOf(wallsToDeleteArray[iToDelete])
        geometriesWall.splice(deleteIndex, 1);
      }
    }
    return false
  }

  saveChangesPerFrame (dontZoom) {
    console.log("save changes")

    let doorGeometries=this.getGeometriesByRepresentationType(representationTypes.door)
    console.log("nDoors:"+doorGeometries.length)

    let axesInGeometries=this.prepareChanges()
    if(axesInGeometries===true){
      console.log("axesInGeometries")
      return
    }


    const spaceController = this.representationController.get(representationTypes.space)
    spaceController.createSpaces(this.getGeometries())

    let nSpaces=this.getGeometriesByRepresentationType(representationTypes.space).length

    //console.log(spaceController)
    this.updateSpaces(0)
    this.showAxis=true
    this.geometryChanged=true

    //scaleGeometries löschen
    const representationGroupScale = this.representationGroups.get(representationTypes.scale)
    let geometriesScale=representationGroupScale.children
    console.log("N SCALE geoms: "+geometriesScale.length)
    if(this.currentTool!=="scale" ){
      if(geometriesScale.length>0){
        geometriesScale.splice(0,geometriesScale.length)
        //representationGroupScale.children
      }
    }

    const geometriesJSON = this.getGeometriesAsJSON()
    console.log("geometries JSON")
    console.log(geometriesJSON)
    this.printGeometries(geometriesJSON)

    const geometries = {
      geometries: geometriesJSON,
      selectedGeometriesUuid: this.currentGeometries.map(geometry => geometry.uuid),
      currentTool: this.currentTool
    }
    doorGeometries=this.getGeometriesByRepresentationType(representationTypes.door)
    console.log("nDoors:"+doorGeometries.length)


    this.storeGeometries(geometries)
    //console.log("checkstepP")
    // props??
    //checkStep()

    console.log("IMAGESCALE: "+this.imageController.getImageScale())

    this.updateScaleWithPromise(this.imageController.getImageScale())
    this.updateGeometriesOnly()



    if(this.currentTool==="outline"){
      this.zoomOnBoundingBox()
    }


    //const aController = this.representationController.get(representationTypes.axis);
    //this.updateGeometriesAndFetchAnalytics(aController)
    this.changesPerFrame = false
  }

  printGeometries (geometriesJSON){
    console.log("geometries::")
    for(let index=0; index<geometriesJSON.length; index++){
      let geometry=geometriesJSON[index]
      console.log("Geom"+index+": "+geometry.uuid+" "+geometry.geometryType+" "+geometry.representationType)
      let properties=geometry.properties
      console.log("properties:"+properties);
    }
  }
  setStoreGeometries (store) {
    this.storeGeometries = store
  }

  setUndoGeometries (undo) {
    this.undoGeometries = undo
  }

  setRedoGeometries (redo) {
    this.redoGeometries = redo
  }

  setUpdateGeometries(update) {
    this.updateGeometries = update
    console.log("setUpdateGeometries:")
    console.log(update)
  }

  setTool (tool) {
    this.setCurrentTool = tool
    console.log("setCurrentTool:")
    console.log(tool)
  }

  setImage (image) {
    this.imageController.setImage(image)
  }

  // Private
  resetCamera () {
    this.camera.position.set(0, 0)
    this.camera.zoom = geometryScale.resetZoomLevel
    this.orbitControls.target.x = 0
    this.orbitControls.target.y = 0

    this.orbitControls.update()
    this.camera.updateProjectionMatrix()
  }

  realToScreen(realPosition) {
    const { clientWidth: width, clientHeight: height, offsetTop: top, offsetLeft: left } = this.mount.current

    const realX = realPosition.x
    const realY = realPosition.y

    let zoomfactor = this.camera.zoom * 8.0

    let canvasCenterX = left + (width * 0.5)
    let canvasCenterY = top + (height * 0.5)

    let realScreenCenterX = this.camera.position.x / zoomfactor
    let realScreenCenterY = this.camera.position.y / zoomfactor

    let screenX = ((realX - realScreenCenterX)  * zoomfactor) + canvasCenterX
    let screenY = ((realY - realScreenCenterY)  * (-zoomfactor)) + canvasCenterY

    return  new Vector2(screenX,screenY)
  }

  realToCam(realPosition) {
    const { clientWidth: width, clientHeight: height, offsetTop: top, offsetLeft: left } = this.mount.current

    const realX = realPosition.x
    const realY = realPosition.y

    let zoomfactor = this.camera.zoom * 8.0

    let canvasCenterX = left + (width * 0.5)
    let canvasCenterY = top + (height * 0.5)

    let realScreenCenterX = this.camera.position.x / zoomfactor
    let realScreenCenterY = this.camera.position.y / zoomfactor

    let screenX = ((realX - realScreenCenterX)  * zoomfactor) + canvasCenterX
    let screenY = ((realY - realScreenCenterY)  * (-zoomfactor)) + canvasCenterY

    return  new Vector2(screenX,screenY)
  }

  screenToReal(screenPosition){
    const { clientWidth: width, clientHeight: height, offsetTop: top, offsetLeft: left } = this.mount.current

    let globalScreenCenter=this.camera.position;

    let xOnScreen = (screenPosition.x - left) - 0.5*(width);
    let yOnScreen = (top - screenPosition.y) + 0.5*(height);

    let zoomfactor=this.camera.zoom*8.0
    let realX=((xOnScreen+globalScreenCenter.x)/zoomfactor)
    let realY=((yOnScreen+globalScreenCenter.y)/zoomfactor)

    return new Vector2(realX,realY)
  }

  zoomOnRect(minPosition, maxPosition){
    if((minPosition===null)||(maxPosition===null)) return
    console.log(minPosition+"::"+maxPosition+"::")
    console.log("X: "+minPosition.x+" :: "+maxPosition.x)
    console.log("Y: "+minPosition.y+" :: "+maxPosition.y)
    let minX=minPosition.x
    let maxX=maxPosition.x
    let minY=minPosition.y
    let maxY=maxPosition.y


    //console.log("X: "+minX+" :: "+maxX)
    //console.log("Y: "+minY+" :: "+maxY)
    let realCenterX=0.5*(maxX+minX)
    let realCenterY=0.5*(maxY+minY)

    let realPos=new Vector2(realCenterX, realCenterY)
    console.log("realPos: "+realPos.x+" :: "+realPos.y)


    let deltaX=realCenterX    //*zoomfactor
    let deltaY=realCenterY    //*zoomfactor
    //deltaX=-deltaX
    //deltaY=deltaY

    this.camera.position.x = deltaX
    this.camera.position.y = deltaY

    console.log("cam: "+this.camera.position.x+" :: "+this.camera.position.y+" :: "+this.camera.position.z);
    this.orbitControls.target.x = this.camera.position.x
    this.orbitControls.target.y = this.camera.position.y
    console.log("orb: "+this.orbitControls.target.x+" :: "+this.orbitControls.target.y+" :: "+this.orbitControls.target.z);

    const { clientWidth: width, clientHeight: height, offsetTop: top, offsetLeft: left } = this.mount.current
    console.log("width: " + width + "  height: " + height + "  left:" + left + "  top: " + top)

    let zoom=this.camera.zoom
    console.log("zoom: "+zoom)

    //let width=

    //let zoomfactor = this.camera.zoom * 8.0 * 1.1
    let rectWidth=(maxX-minX)*1.05
    let rectHeight=(maxY-minY)*1.05
    let zoomX = (width / rectWidth)
    let zoomY = (height / rectHeight)

    console.log("zx: "+zoomX+" zy"+zoomY+" cz: "+this.camera.zoom)
    if(zoomX < zoomY){
      this.camera.zoom=zoomX/8.0
    }else{
      this.camera.zoom=zoomY/8.0
    }

    this.orbitControls.update()
    this.camera.updateProjectionMatrix()
  }

  zoomOnBoundingBox() {
    console.log("zoomOnBBox")
    let geometriesOutline = this.getOutlineGeometries()
    console.log("OUTLINES:"+geometriesOutline)
    if(!geometriesOutline) return
    if(geometriesOutline.length===0) return

    //const screenCenterX = ((mousePositionX - left) / width) * 2 - 1
    //const screenCenterY = -(((mousePositionY - top) / height) * 2 - 1)
    let maxY = Number.NEGATIVE_INFINITY
    let minY = Number.POSITIVE_INFINITY
    let maxX = Number.NEGATIVE_INFINITY
    let minX = Number.POSITIVE_INFINITY

    for (let iOutline = 0; iOutline < geometriesOutline.length; iOutline++) {
      let cGeometry = geometriesOutline[iOutline]
      let cPositions = this.geometryToPositionArray(cGeometry)
      console.log("nVerts: " + cPositions.length)
      for (let iPos = 0; iPos < cPositions.length; iPos++) {
        //console.log(iPos+": ")
        if (cPositions[iPos].x < minX) minX = cPositions[iPos].x
        if (cPositions[iPos].x > maxX) maxX = cPositions[iPos].x
        if (cPositions[iPos].y < minY) minY = cPositions[iPos].y
        if (cPositions[iPos].y > maxY) maxY = cPositions[iPos].y
      }
    }
    console.log("min: " + minX + "::" + minY + "  max: " + maxX + "::" + maxY)
    let minPos = new Vector2(minX, minY)
    let maxPos = new Vector2(maxX, maxY)

    this.zoomOnRect(minPos, maxPos)
  }

  updateSpaces(spaceTextureIndex){
    console.log("update SpaceType: "+this.currentSpace+"; "+spaceTextureIndex)
    //entweder
    const spaceGeoms = this.getGeometriesByRepresentationType(representationTypes.space)
    console.log(spaceGeoms.length)

    for(let i=0; i<spaceGeoms.length; i++){
      let spaceGeom=spaceGeoms[i]
      spaceGeom.useSpaceType=spaceTextureIndex    //0:random 1:texture 2:white
      spaceGeom.updateSpaceTextures()
      spaceGeom.setActive(true)
    }
  }

  updateGuideLineDirection(){
    console.log("updateGuideLine")
    let position=this.currentPosition

    let currentVertexGroup=[]
    if(this.currentGeometries.length >= 1){
      if(this.currentGeometries.vertexGroup)
        currentVertexGroup=this.currentGeometries.vertexGroup.children
    }

    const representationGroupWall = this.representationGroups.get(representationTypes.wall)
    const geometriesWall=representationGroupWall.children

    const representationGroupOutline = this.representationGroups.get(representationTypes.outline)
    const geometriesOutline=representationGroupOutline.children
    console.log("RPWALL: "+geometriesWall.length+"  RPOUTLINE: "+geometriesOutline.length+"   CURRENT: "+currentVertexGroup.length)



    const wallPositions=[]
    for(let iOutline=0; iOutline<geometriesOutline.length; iOutline++){
      let cGeometry=geometriesOutline[iOutline]
      let cPositions = this.geometryToPositionArray(cGeometry)
      if(cPositions.length>0)
        cPositions.push(cPositions[0])

      wallPositions.push(cPositions)
    }
    for(let iWall=0; iWall<geometriesWall.length; iWall++){
      let cGeometry=geometriesWall[iWall]
      let cPositions = this.geometryToPositionArray(cGeometry)
      wallPositions.push(cPositions)
    }

    if(currentVertexGroup.length >= 2){
      console.log("currentgeometries")
      console.log(this.currentGeometries[0])

      let vertices=this.currentGeometries[0].vertexGroup.children
      console.log("n Children: "+vertices.length)
      //let positions=[]
      let dirPositions=[]
      if (vertices.length>=2){
        let cIndex=vertices.length-1
        let ccIndex=vertices.length-2
        dirPositions.push(vertices[ccIndex].position)
        dirPositions.push(vertices[cIndex].position)

        let posA=new Vector2(dirPositions[0].x,dirPositions[0].y)
        let posB=new Vector2(dirPositions[1].x,dirPositions[1].y)
        this.magicGuideLine.updateDirectionBy2Position(posA,posB)
      }

      console.log("Positions: ")
      console.log(dirPositions)

    }else{
      let nearestDistance=Number.MAX_VALUE
      let nearestPosPair=[]

      for(let iWall=0; iWall<wallPositions.length; iWall++){
        let positions=wallPositions[iWall]
        if (positions.length>1){
          for( let i=0; i<(positions.length-1); i++){
            let posA=positions[i]
            let posB=positions[i+1]
            let projection=getOrtoProjectionOnSegment(position,posA,posB)
            let distance=projection.distanceTo(position)
            if(distance<nearestDistance ) {
              nearestDistance=distance
              nearestPosPair=[]
              nearestPosPair.push(posA)
              nearestPosPair.push(posB)
            }
          }
        }
      }

      if(nearestDistance<4) {
        let posA=new Vector2(nearestPosPair[0].x,nearestPosPair[0].y)
        let posB=new Vector2(nearestPosPair[1].x,nearestPosPair[1].y)
        this.magicGuideLine.updateDirectionBy2Position(posA,posB)
      }else{
        let posA=new Vector2(0,0)
        let posB=new Vector2(1,0)
        this.magicGuideLine.updateDirectionBy2Position(posA,posB)
      }
      console.log("wallPositions")
      console.log(wallPositions)
      console.log("currentGeometries")
      console.log(this.currentGeometries)
      if(this.currentGeometries.length>0)
        console.log(this.currentGeometries[0].vertexGroup.children)

      console.log("nearest: "+nearestDistance)
      console.log(nearestPosPair)
    }
  }
  toggleVertexMode(){
    this.vertexMode=!this.vertexMode
  }
  printSceneObjects(){
    console.log("NChildren: "+this.scene.children.length)
    for(let i=0; i<this.scene.children.length; i++){
      console.log(this.scene.children[i])
    }
    console.log("FINISHED")
  }
}



const geometryScale = {
  minZoomLevel: 3,
  resetZoomLevel: 1.5,
  maxZoomLevel: 1,
}
