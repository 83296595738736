import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import StepSidebar from './StepSidebar'

import { advancedSteps, assistantSteps } from '../constants/onboarding-constants'

import './Step.scss'
import { getCurrentGeometryObjects } from '../../DrawingTool/reducer/drawingToolReducer'
import { setCurrentTool } from '../../buildings/actions/drawingActions'
//import { svgToolsListNew } from '../constants/SvgTools'

class Step extends Component {

  render () {

    //console.log("STEPS!!")
    //console.log(this.props)
    const steps = this.props.advancedSettings ? advancedSteps : assistantSteps
    //console.log(this.props.advancedSettings)
    //console.log(steps)

    //console.log("STEP!!")
    const step = steps.find(step => step.viewName === this.props.step)
    //console.log(step)

    //console.log("currentTool:")
    //console.log(this.props.currentTool)


    if (!step)
      return null

    const currentIndex = steps.indexOf(step)

    const previousView = currentIndex - 1 >= 0 ? steps[currentIndex - 1].viewName : null
    const nextView = currentIndex + 1 < steps.length ? steps[currentIndex + 1].viewName : null
    const beforeNextViewAction = currentIndex + 1 < steps.length ? steps[currentIndex + 1].beforeViewAction : null

    const { component, childComponents, currentTool } = step

    //console.log("component")
    //console.log(component)
    //console.log(childComponents)
    //console.log("currentTool:::")
    //console.log(currentTool)
    //{currentTool}

    return (
      <div className={'step-container'}>
        <StepSidebar step={step} nextView={nextView} previousView={previousView} beforeNextViewAction={beforeNextViewAction}/>
        <div className={'step-content'}>
          {component}

        </div>
        {childComponents ? childComponents.map((component, index) => React.cloneElement(component, { key: index })) : null}
      </div>
    )
  }
}



let mapStateToProps = state => {
  return {
    currentTool: state.drawingTool.currentTool,
    advancedSettings: state.settings.appSettings.advancedSettings,
  }
}

let mapDispatchToProps = {
  setCurrentTool: setCurrentTool,
  push: push,
}

export default connect(mapStateToProps, mapDispatchToProps)(Step)