import { getVerticesByWidthAndHeight } from '../helper/GeometryHelper'
import { createFaceBufferGeometry, Face } from './FaceFactory'
import { representationTypes } from '../config/RepresentationTypes'
import { geometryTypes } from '../config/GeometryTypes'
import { Geometry } from './Geometry'
import { Vector2 } from 'three'

const referenceEdgeActions = {
  resize: 'resize',
}

export class ReferenceEdgeGeometry extends Geometry {
  referenceEdge
  referenceVertex

  reconnect = true

  constructor (group, representationType, width = 1) {
    super(group, representationType, referenceEdgeActions)

    this.geometryType = geometryTypes.stamp

    this.properties = {
      width: width,
    }
  }

  setReferenceEdge (referenceEdge) {
    console.log("setting reference egde")
    if (this.referenceEdge) {
      this.referenceEdge.unsubscribe(this.updateEdgeCallback)
    }
    this.referenceEdge = referenceEdge
    if (this.referenceEdge) {
      console.log("subscribing")
      this.referenceEdge.subscribe(this.updateEdgeCallback)
    }

    this.properties.referenceEdge = this.referenceEdge ? this.referenceEdge.uuid : this.referenceEdge

    if (this.referenceEdge && (!this.referenceVertex || !this.referenceEdge.isConnectToVertex(this.referenceVertex))) {
      const vertices = this.referenceEdge.getVertices()

      this.setReferenceVertex(vertices[0])
    }
  }

  setReferenceVertex (referenceVertex) {
    this.referenceVertex = referenceVertex
    this.properties.referenceVertex = this.referenceVertex ? this.referenceVertex.uuid : this.referenceVertex
  }

  updateReferenceEdge (referenceEdge, position) {
    this.setReferenceEdge(referenceEdge)

    if (referenceEdge) {
      this.properties.absolute = position.distanceTo(this.referenceVertex.position)
      this.properties.relative = this.properties.absolute / this.referenceEdge.getLength()
      this.updateGeometry()
    } else {
      this.updateGeometry(position)
    }
  }

  addWidth (width) {
    this.properties.width = this.properties.width + width > 0.5 ? this.properties.width + width : .5
    if (this.referenceEdge) {
      this.properties.width = this.properties.width < this.referenceEdge.getLength() ? this.properties.width : this.referenceEdge.getLength()
    }

    this.updateGeometry()
  }

  move (movement) {
    if (this.properties.relative) {
      let absolute = (this.properties.relative * this.referenceEdge.getLength()) + movement
      absolute = absolute > 0 ? absolute : 0
      absolute = absolute < this.referenceEdge.getLength() ? absolute : this.referenceEdge.getLength()
      this.properties.relative = absolute / this.referenceEdge.getLength()
    } else if (this.properties.absolute) {
      this.properties.absolute += movement
      this.properties.absolute = this.properties.absolute > 0 ? this.properties.absolute : 0
      this.properties.absolute = this.properties.absolute < this.referenceEdge.getLength() ? this.properties.absolute : this.referenceEdge.getLength()
    }

    this.updateGeometry()
  }

  setActive (active) {
    if (this.face) {
      this.face.setActive(active)
    }

    this.setAction(active ? this.actions.select : this.actions.idle)
  }

  updateEdgeCallback = (edge) => {
    if (!this.reconnect) {
      return
    }

    if (!edge) {
      this.tryReconnectToParentGeometry()
    } else if (edge === this.referenceEdge) {
      if (this.referenceEdge && this.referenceVertex)
        this.updateGeometry()
    }
  }

  tryReconnectToParentGeometry () {
    let minDistance = Number.MAX_VALUE
    let nearestEdge = null

    this.referenceEdge.parent.children
      .filter(edge => edge !== this.referenceEdge)
      .forEach(edge => {
        const distance = edge.getDistanceToEdge(this.position)
        if (distance < minDistance) {
          minDistance = distance
          nearestEdge = edge
        }
      })

    if (nearestEdge && minDistance < 1) {
      console.log("reconnect")
      this.setReferenceEdge(nearestEdge)
      this.updateReferenceEdge(nearestEdge, this.position)
    } else {
      this.parent.remove(this)
    }
  }

  dispose () {
    super.dispose()

    if (this.face) {
      this.face.geometry.dispose()
    }

    if (this.referenceEdge) {
      this.referenceEdge.unsubscribe(this.updateEdgeCallback)
    }
  }

  fromJSON (json) {
    super.fromJSON(json)

    this.updateGeometry()
  }

  updateGeometry (position = null) {
    let depth = this.properties.depth
    let angle = 0

    if (this.referenceEdge && this.referenceVertex) {
      const direction = this.referenceEdge.getDirectionFromVertex(this.referenceVertex)

      if (this.properties.absolute) {
        direction.normalize()
          .multiplyScalar(this.properties.absolute)
      } else {
        direction.multiplyScalar(this.properties.relative ? this.properties.relative : 0.5)
      }

      position = this.referenceVertex.position.clone()
        .add(direction)
      position = this.referenceEdge.getNearestPositionOnEdge(position)
      //console.log("position"+position)
      angle = this.referenceEdge.getAngle()
      depth = this.referenceEdge.getGeometry()
        .getOffset()

      direction.normalize()

      const orthogonal = new Vector2(-direction.y, direction.x)

      this.properties.vertices = [
        {
          x: position.x - (this.properties.width / 2) * direction.x + (depth / 2) * orthogonal.x,
          y: position.y - (this.properties.width / 2) * direction.y + (depth / 2) * orthogonal.y,
        },
        {
          x: position.x + (this.properties.width / 2) * direction.x + (depth / 2) * orthogonal.x,
          y: position.y + (this.properties.width / 2) * direction.y + (depth / 2) * orthogonal.y,
        },
        {
          x: position.x - (this.properties.width / 2) * direction.x - (depth / 2) * orthogonal.x,
          y: position.y - (this.properties.width / 2) * direction.y - (depth / 2) * orthogonal.y,
        },
        {
          x: position.x + (this.properties.width / 2) * direction.x - (depth / 2) * orthogonal.x,
          y: position.y + (this.properties.width / 2) * direction.y - (depth / 2) * orthogonal.y,
        }
      ]
    }

    const vertices = getVerticesByWidthAndHeight(this.properties.width, depth)

    if (this.face) {
      this.face.geometry.dispose()
      this.face.geometry = createFaceBufferGeometry(vertices)
    } else {
      this.face = new Face(this.representationType, vertices)
      this.add(this.face)
    }

    this.face.rotation.z = 0
    this.face.rotateZ(angle)

    if (position)
      this.position.set(position.x, position.y, 0)
  }
}

export function getReferenceEdgePropertiesByRepresentationType (representationType) {
  switch (representationType) {
    case representationTypes.door:
      return {
        width: .9,
        depth: .2,
      }
    case representationTypes.window:
      return {
        width: 1,
        depth: .2,
      }
    default:
      return {
        width: 1,
        depth: .2,
      }
  }
}