import { getReferenceEdgePropertiesByRepresentationType, ReferenceEdgeGeometry } from '../geometries/ReferenceEdgeGeometry'
import { representationTypes } from '../config/RepresentationTypes'

let previewReferenceEdge = null

export class PreviewReferenceEdge extends ReferenceEdgeGeometry {
  constructor (scene) {
    super(scene)

    previewReferenceEdge = this
    this.reconnect = false

    const properties = getReferenceEdgePropertiesByRepresentationType(representationTypes.door)

    this.properties = {
      width: properties.width,
      depth: properties.depth,
    }
  }

}

export function setPreviewReferenceEdge (position, edge) {
  if (!position) {
    previewReferenceEdge.setActive(false)
    return
  }

  previewReferenceEdge.setActive(true)
  previewReferenceEdge.updateReferenceEdge(edge, position)
}

export function addWidthPreviewReferenceEdge (width) {
  previewReferenceEdge.addWidth(width)
}

export function getWidthPreviewReferenceEdge () {
  return previewReferenceEdge.properties.width
}
