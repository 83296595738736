import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { push } from 'connected-react-router'

import InspectorContainer from '../InspectorContainer'
import { viewNames } from '../../globalConstants'

import { closeFlyOut, openFlyOut } from '../../../floorplan/actions/ui-actions'
import { lockDepartment, setSelectedFloor } from '../../../floorplan/actions/actions'
import { setSelectedOrganisationalUnit } from '../../redux/appstate/appstate-actions'

import TreeList from '../TreeList'
import Dropdown from '../Dropdown'

import BuildingMenu from './BuildingMenu'
import VerticalMenu from '../VerticalMenu'

import { FloorListItem } from './FloorListItem'

import { addBuilding } from '../../../floorplanner/actions/actions'
import { parseToTreeListNodes } from './parseTreeList'

import './BuildingsFloorsInspector.scss'
import { iconTypes } from '../icons/IconFactory'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { updateDrawingAndFetch } from '../../../buildings/actions/actions'
import { updateGeometries } from '../../../DrawingTool/actions/Backend/drawingToolActions'

const initState = {
  selectedLevel0: null,
  selectedLevel1: null,
  selectedLevel2: null,
}

class BuildingsFloorsInspector extends Component {

  constructor (props) {
    super(props)

    this.state = initState
  }

  componentDidMount () {
    this.props.closeFlyOut()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.selected !== this.props.selected && this.props.selected !== null) {
      this.props.openFlyOut()
    } else if (this.props.selected === null) {
      this.props.closeFlyOut()
    }

    if (prevProps.selectedObjectId !== this.props.selectedObjectId) {
      this.updateHierarchicalSelection()
    }
  }

  updateHierarchicalSelection () {
    let l0 = null
    let l1 = null
    let l2 = null

    if (this.props.selectedObjectId !== null) {
      let unit = this.getOrganisationalUnit(this.props.selectedObjectId)
      if (unit) {
        switch (unit.hierarchicalLevel) {
          case 0:
          default:
            l0 = unit.id
            break
          case 1:
            l1 = unit.id
            l0 = unit.parentId
            break
          case 3:
            l2 = unit.id
            l1 = unit.parentId
            l0 = this.getOrganisationalUnit(l1).parentId
            break
        }
      }
    }

    this.setState({
      selectedLevel0: l0,
      selectedLevel1: l1,
      selectedLevel2: l2,
    })

  }

  getOrganisationalUnit (uuid) {
    return this.props.organisationalUnits.find(oo => oo.id === uuid)
  }

  getActiveBuildingName () {
    const building = this.props.buildings.find((building) => (building.id === this.props.selectedBuildingId))

    return building ? building.name : ''
  }

  render () {
    const { t } = this.props

    const activeBuildingName = this.getActiveBuildingName()

    const isEditOutline = this.props.location.pathname.endsWith(viewNames.EDIT)

    const buttonText = isEditOutline ? t('finish_editing') : t('edit_outlines')
    const buttonIcon = isEditOutline ? iconTypes.floorplan : iconTypes.editOutline
    const onButtonClick = isEditOutline ? () => {
      this.props.push('./' + viewNames.FLOORPLAN)
      console.log('finish editing')
      this.props.updateGeometries()
      console.log('finished editing')
    } : () => this.props.push('./' + viewNames.EDIT)

    return (
      <React.Fragment>
        <InspectorContainer scrollable headerTitle={this.props.title}
                            buttonText={buttonText}
                            buttonIcon={buttonIcon}
                            onButtonClick={onButtonClick}>
          <div className={'buildings-drop-down'}>
            <Dropdown title={activeBuildingName}
                      icon={iconTypes.building}
                      items={this.getBuildingItems()}
                      onClick={(buildingId) => this.handleBuildingClick(buildingId)}
                      onClickAddItem={() => this.props.addBuilding()}
                      addIcon={iconTypes.addBuilding}
                      addItemText={t('add_new_building')}
                      menuStyleClass={'buildings-drop-down-menu'}/>
            <VerticalMenu>
              <BuildingMenu/>
            </VerticalMenu>
          </div>
          {this.getFloorListItem()}
        </InspectorContainer>
      </React.Fragment>
    )
  }

  getBuildingItems () {
    return this.props.buildings.map((building) => {
      return {
        id: building.id,
        name: building.name,
        icon: 'building',
      }
    })
  }

  getFloorListItem () {
    let floors = [...this.props.assignedFloors].sort((a, b) => a.index < b.index ? 1 : -1)

    const isFloorplanView = this.props.location.pathname.endsWith(viewNames.FLOORPLAN)
    const isFloorsView = this.props.location.pathname.endsWith(viewNames.FLOORS)
    const isDrawingView = this.props.location.pathname.endsWith(viewNames.EDIT)

    const handleLevelItemClick = isFloorplanView ? [
      null,
      (id) => this.handleListItemClick(id),
      (id) => this.handleListItemClick(id),
    ] : [null, null, null]

    return floors.map((floor, index) => {

      const organisationalUnits = floor.id === this.props.selectedFloorId && isFloorplanView ? this.props.placedUnitsTree : floor.organisationalUnits

      const nodes = !isDrawingView ? parseToTreeListNodes(organisationalUnits, true, this.props.shortVersion, (unit) => this.handleLockDepartment(unit)) : []

      return (
        <FloorListItem key={index}
                       floor={floor}
                       selectedFloorId={(!isFloorsView ? this.props.selectedFloorId : null)}
                       openFloor={!isFloorsView ? (id) => this.handleFloorClick(id) : null}>
          {nodes.length ?
            <TreeList className={'building-tree-list-container'}
                      nodes={nodes}
                      activeLevel0={this.state.selectedLevel0}
                      activeLevel1={this.state.selectedLevel1}
                      activeLevel2={this.state.selectedLevel2}
                      selected={floor.id === this.props.selectedFloorId ? this.props.selectedObjectId : null}
                      onLevelItemClick={this.props.selectedFloorId === floor.id ? handleLevelItemClick : null}
            /> : null}
        </FloorListItem>)
    })
  }

  handleLockDepartment (unit) {
    this.props.setSelectedOrganisationalUnit(unit.id)
    this.props.lockDepartment(unit.id, !unit.locked)
  }

  handleListItemClick (id) {
    this.props.setSelectedOrganisationalUnit(id)
    this.props.openFlyOut()
  }

  handleFloorClick (floorId) {
    if (floorId !== this.props.selectedFloorId)
      this.props.setSelectedFloor(this.props.selectedBuildingId, floorId)
  }

  handleBuildingClick (buildingId) {
    let floorId = null

    this.props.buildings.forEach((building) => {
      if (building.id === buildingId && building.floors.length > 0) {
        floorId = building.floors[0].id
      }
    })

    this.props.setSelectedFloor(buildingId, floorId)

  }
}

let mapStateToProps = (state) => {
  return {
    placedUnitsTree: state.placedOrganisationalUnits.placedUnitsTree,

    assignedFloors: state.placedOrganisationalUnits.assignedFloors,

    organisationalUnits: state.organisationalUnits.units,

    selectedObjectId: state.appState.selectedOrganisationalUnitId,

    buildings: state.buildings.buildings,

    selectedBuildingId: state.appState.selectedBuildingId,
    selectedFloorId: state.appState.selectedFloorId,

    location: state.router.location,
  }
}

let mapDispatchToProps = {
  setSelectedOrganisationalUnit: setSelectedOrganisationalUnit,
  openFlyOut: openFlyOut,
  closeFlyOut: closeFlyOut,
  setSelectedFloor: setSelectedFloor,
  lockDepartment: lockDepartment,

  addBuilding: addBuilding,
  updateDrawingAndFetch: updateDrawingAndFetch,
  updateGeometries: updateGeometries,
  push: push,
}

export default compose(withTranslation(), withRouter)(connect(mapStateToProps, mapDispatchToProps)(BuildingsFloorsInspector))