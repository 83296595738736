import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { Scrollbars } from 'react-custom-scrollbars'

import Text, { textTypes } from '../../common/components/Text'
import Button from '../../common/components/Button'
import { AssistantDialog } from './AssistantDialog'

import { viewNames } from '../../common/globalConstants'
import ModalConsumer from '../../common/components/modal/ModalConsumer'

import { autoGenerateAssigmentInFloor, fetchFloorPlanPdf, generateAll, optimiseFloorPlacing } from '../../floorplan/actions/actions'
import { getCurrentVariationId } from '../../common/utils'
import { createRandomOrganisationalUnits } from '../../common/redux/organisationunits/organisational-units-actions'
import { assistantActions, stepsNames } from '../constants/onboarding-constants'
import TemplateDialog from '../../common/components/TemplateDialog'
import { loadDemoFloor, setCurrentDrawingTab, updateDrawingAndFetch } from '../../buildings/actions/actions'
import { iconTypes } from '../../common/components/icons/IconFactory'
import AssistantButton from './StepSidebarComponents/AssistantButton'
import Description from './StepSidebarComponents/Description'
import TestFit from './StepSidebarComponents/TestFit'

import EditOutlineDescription from './StepSidebarComponents/EditOutlineDescription'
import OpenDemoButton from './StepSidebarComponents/OpenDemoButton'

import './StepSidebar.scss'
import { updateFrontendLocation } from '../../common/redux/frontendLocation/frontend-location-action'
import WrongAxesDialog from './WrongAxesDialog'
import { getCurrentGeometryObjects } from '../../DrawingTool/reducer/drawingToolReducer'
import { updateGeometries } from '../../DrawingTool/actions/Backend/drawingToolActions'
import { representationTypes } from '../../DrawingTool/three/config/RepresentationTypes'


class StepSidebar extends Component {

  constructor (props, context) {
    super(props, context)
    this.state = {
      waitForAxes: false,
      axesDialog: null,
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.placedDepartments !== this.props.placedDepartments) {
      if (this.state.waitForAxes === true) {
        if (this.checkPlacedUnitsAllZero()) {

          this.setState({
            waitForAxes: false,
            axesDialog: <WrongAxesDialog onCancel={() => this.closeAxesDialog()}
                                         onConfirm={() => this.closeAxesDialog()}
                                         title={'Something went wrong.'}
                                         text={'Unfortunately, we were not able to place the automatic axes correctly. Please place them manually'}
            />,
          })
        } else {
          this.goToNextView(this.props.nextView)
        }
      }
    }
  }

  render () {
    const {
      image,
      stepTitle,
      title,
      description,
      advancedDescription,
      editOutlineDescription,
      uploadDemo,
      testFit,
      assistant,
      process,
      nextStepButton,
    } = this.props.step
    //console.log("STEPTITLE: " +stepTitle)
    return (
      <ModalConsumer>
        {({ showModal }) => (
          <div className={'step-sidebar'}>
            <img className={'step-sidebar-image'} src={image} alt={'something'}/>
            <div className={'step-sidebar-description' + (assistant || uploadDemo ? '' : ' expand')}>
              <div className={'step-sidebar-step'}>
                <Text component={textTypes.runningTitle}>{stepTitle}</Text>
              </div>
              <Scrollbars className={'step-sidebar-scrollbar'}>
                <div className={'step-sidebar-scrollbar-container'} id={"onbo"}>
                  {title ? <Text outerClassName={'step-sidebar-title'} component={textTypes.title}>{title}</Text> : null}
                  {editOutlineDescription ? <EditOutlineDescription description={editOutlineDescription}/> : null}
                  {description ? <Description description={this.props.advancedSettings && advancedDescription ? advancedDescription : description}/> : null}
                  {testFit ? <TestFit/> : null}
                </div>
              </Scrollbars>
            </div>

            {assistant ? <AssistantButton onClick={() => this.handleOpenAssistant(showModal, assistant)}/> : null}
            {uploadDemo ? <OpenDemoButton onClick={() => this.handleOpenAssistant(showModal, uploadDemo)}/> : null}
            {this.state.axesDialog ? this.state.axesDialog : null}

            <div className={'step-sidebar-process-bar'}>
              {process ? <div style={{ height: '100%', width: process.percent + '%', backgroundColor: process.color }}/> : null}
            </div>
            <div className={'step-sidebar-next-step'}>
              <Button icon={iconTypes.back} variant={'inverted'}
                      onClick={() => this.handleBackStepClick(stepTitle)}>Back</Button> : <div/>
              <Button className={'step-sidebar-next-step-button' + (this.props.nextView ? '' : ' finish')}
                      variant={'filled'}
                      disabled={!this.isNextStepAllowed(stepTitle)}
                      onDisabledClick={() => assistant ? this.handleAssistantForNextStep(showModal, assistant) : {}}
                      secondIcon={nextStepButton.icon}
                      onClick={() => this.handleNextStepClick(nextStepButton)}>{nextStepButton.text}</Button>
            </div>
          </div>)}
      </ModalConsumer>
    )
  }

  handleNextStepClick (nextStepButton) {
    this.handleChangeStepView(this.props.nextView, [nextStepButton.action, this.props.beforeNextViewAction])
  }


  /*
  handleNextStepDrawing (nextStepButton) {
    switch (this.props.currentDrawingTab) {
      case tools.SPACES:
        this.props.updateDrawingAndFetch()
        this.props.setCurrentDrawingTab(tabsNames.DETAILS)
        break
      case tools.DETAILS:
        this.props.setCurrentDrawingTab(tabsNames.AREAS)
        break
      case tools.AREAS:
        if (this.props.advancedSettings) {
          this.props.setCurrentDrawingTab(tabsNames.AXES)
        } else {
          this.handleChangeStepView(this.props.nextView, [nextStepButton.action, this.props.beforeNextViewAction])
        }
        break
      case tabsNames.AXES:
        this.handleChangeStepView(this.props.nextView, [nextStepButton.action, this.props.beforeNextViewAction], true)
        break
      default:
        break
    }
  }
*/
  /*
  export function handleDrawingStep(){

  }

   */

  handleChangeStepView (changedStepView, actions = [], skipAxesCheck = false) {
    let skipChange = false
    //console.log("console actions: "+actions.length)
    //console.log(actions)
    //console.log("next View:"+this.props.nextView)
    if(this.props.nextView === "step_2"){
      this.props.updateGeometries()
      //console.log("S2")
    }
    actions.forEach(action => {
      //console.log("console action: "+action+" propsaction: "+this.props[action])

      if (action && this.props[action]) {
        switch (action) {
          case 'optimiseFloorPlacing':
            this.props.optimiseFloorPlacing()
            break
          case 'generateAll':
            this.props.generateAll(0)
            if (!skipAxesCheck) {
              skipChange = true
              this.setState({
                waitForAxes: true,
              })
            }
            break
          case 'downloadFloorplanPdf':
            this.props.downloadFloorplanPdf()
            return
          default:
            break
        }
      }
    })

    if (skipChange)
      return

    this.goToNextView(changedStepView)
  }

  goToNextView (changedStepView) {
    //console.log("changedStepViev: "+changedStepView)

    const nextView = (changedStepView ? './' + changedStepView : '../' + viewNames.FLOORPLAN) + '?variation=' + this.props.variationId

    this.props.push(nextView)

    this.props.updateFrontendLocation(changedStepView ? 'ONBOARDING' : 'FLOORPLAN', changedStepView ? changedStepView.toUpperCase() : '')
  }

  handleBackStepClick (stepTitle) {
    switch (stepTitle) {
      case stepsNames.START:
        this.handleBackToStart()
        break
      default:
        this.handleChangeStepView(this.props.previousView)
        break
    }
  }

  handleBackToStart () {
    this.props.push('../../../projects')
  }

  handleAssistantForNextStep (showModal, assistant) {
    const { title, notFulfilledText, buttonText, action } = assistant

    const assistantAction = this.getAssistantAction(action)

    if (action) {
      showModal(TemplateDialog,
        {
          title: title,
          text: notFulfilledText,
          rightButtonText: buttonText,
          rightButtonSecondIcon: 'assistant',
          rightButtonVariant: 'orange-filled',
          onRightButtonClicked: assistantAction,
        },
      )
    }
  }
/*
  isNextStepAllowedEditOutline () {
    switch (this.props.currentDrawingTab) {
      case tabsNames.SPACES:
        return this.props.geometryObjects && (this.props.geometryObjects.drawRectangles.length > 0 || this.props.geometryObjects.drawPolygons.length > 0)
      case tabsNames.DETAILS:
      case tabsNames.AREAS:
      case tabsNames.AXES:
        return true
      default:
        return false
    }
  }
  */
  isNextStepAllowed (stepTitle) {
    //console.log("test next STEP Allowed: "+stepTitle)
    switch (stepTitle) {
      case stepsNames.START:
        //console.log("onboarding-project:")
        //console.log(this.props.project && this.props.project.name !== '')
        return this.props.project && this.props.project.name !== ''
      case stepsNames.STEP_1:

        //console.log(this.props)
        //console.log("ONBOARDING-GEOMETRIES:")
        if(this.props.geometries.geometries){
          //console.log("STEP2: "+(this.props.geometries.geometries.filter(geometry => geometry.representationType === representationTypes.outline).length !== 0))
          return this.props.geometries.geometries.filter(geometry => geometry.representationType === representationTypes.outline).length !== 0
        }else{
          //console.log("not allowed")
          return false
        }

      case stepsNames.STEP_2:
        return this.props.units.find(unit => unit.type === 'TEAM')
      case stepsNames.STEP_3:
        return this.props.placedDepartments.length > 0
      default:
        return true
    }
  }

  handleOpenAssistant (showModal, assistant) {
    const { text, buttonText, action } = assistant

    const assistantAction = this.getAssistantAction(action)

    if (action) {
      showModal(AssistantDialog,
        {
          text: text,
          assistantButtonText: buttonText,
          onCreate: assistantAction,
        },
      )
    }
  }

  getAssistantAction (assistantAction) {
    let action = null

    switch (assistantAction) {
      case assistantActions.CREATEFLOORPLAN:
        action = () => this.props.loadDemoFloor()
        break
      case assistantActions.CREATEPEOPLE:
        action = () => this.props.createRandomOrganisationalUnits()
        break
      case assistantActions.CREATEASSIGNMENTS:
        action = () => this.props.autoGenerateAssigmentInFloor()
        break
      default:
        return
    }

    return action
  }

  closeAxesDialog () {
    this.setState({
      axesDialog: null,
    })
  }

  checkPlacedUnitsAllZero () {
    return 0 === this.props.placedDepartments.map(dep => dep.area)
      .reduce((a, b) => a + b)
  }
}

let mapStateToProps = state => {
  return {
    advancedSettings: state.settings.appSettings.advancedSettings,

    variationId: getCurrentVariationId(state),

    project: state.projects.project,
    units: state.organisationalUnits.units,
    placedDepartments: state.placedOrganisationalUnits.placedDepartments,

    geometries: getCurrentGeometryObjects(state),
  }
}

let mapDispatchToProps = {
  optimiseFloorPlacing: optimiseFloorPlacing,
  generateAll: generateAll,
  downloadFloorplanPdf: fetchFloorPlanPdf,
  createRandomOrganisationalUnits: createRandomOrganisationalUnits,
  autoGenerateAssigmentInFloor: autoGenerateAssigmentInFloor,

  loadDemoFloor: loadDemoFloor,
  updateDrawingAndFetch: updateDrawingAndFetch,

  updateFrontendLocation: updateFrontendLocation,
  updateGeometries: updateGeometries,
  setCurrentDrawingTab: setCurrentDrawingTab,
  push: push,
}

export default connect(mapStateToProps, mapDispatchToProps)(StepSidebar)