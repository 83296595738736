import { Mesh, Path, Shape, ShapeBufferGeometry } from 'three'
import { representationTypes } from '../config/RepresentationTypes'
import { geometryTypes } from '../config/GeometryTypes'
import { getMaterial as getMaterial } from '../common/Materials'
import { materialTypes as materialTypes } from '../common/MaterialTypes'
import { getMaterial as getMaterialClassic } from '../../../common/three/common/materials'
import { materialTypes as materialTypesClassic } from '../../../common/three/common/materials'
export function createFaceBufferGeometry (vertices, holes = []) {
  const face = new Shape()

  if (!vertices || !vertices.length)
    return

  face.moveTo(vertices[0].x, vertices[0].y)

  for (let i = 1; i < vertices.length; i++) {
    face.lineTo(vertices[i].x, vertices[i].y)
  }

  if (holes && holes.length) {
    holes.forEach(hole => {
      const holePath = new Path()

      holePath.moveTo(hole[0].x, hole[0].y)

      for (let i = 1; i < hole.length; i++) {
        holePath.lineTo(hole[i].x, hole[i].y)
      }

      face.holes.push(holePath)
    })
  }

  return new ShapeBufferGeometry(face)
}

export class Face extends Mesh {
  constructor (representationType, vertices, holes = [], spaceType) {

    const config = getFaceConfigByRepresentationType(representationType,spaceType)

    const faceGeometry = createFaceBufferGeometry(vertices, holes)

    super(faceGeometry, config.inactiveMaterial)

    this.position.z = 0.01

    this.geometryType = geometryTypes.face
    this.config = config
  }

  setMaterial(material) {
    this.material = material
  }

  changeFaceConfigByRepresentationType(representationType,type){

  }

  setActive (active) {
    this.material = active ? this.config.activeMaterial : this.config.inactiveMaterial
  }

  update (vertices, holes = []) {
    this.dispose()
    this.geometry = createFaceBufferGeometry(vertices, holes)
  }

  moveDelta (movement) {
    if (movement.lengthSq() === 0)
      return

    this.position.x += movement.x
    this.position.y += movement.y
  }

  dispose () {
    this.geometry.dispose()
  }

}

export function getFaceConfigByRepresentationType (representationType, type) {
  //console.log("getFaceConfig")
  switch (representationType) {
    case representationTypes.outline:
      return {
        activeMaterial: getMaterial(materialTypes.transparent),
        inactiveMaterial: getMaterial(materialTypes.transparent),
      }
    case representationTypes.door:
      return {
        activeMaterial: getMaterial(materialTypes.pink),
        inactiveMaterial: getMaterial(materialTypes.green),
      }
    case representationTypes.window:
      return {
        activeMaterial: getMaterial(materialTypes.pink),
        inactiveMaterial: getMaterial(materialTypes.cyan),
      }
    case representationTypes.pillar:
      return {
        activeMaterial: getMaterial(materialTypes.green),
        inactiveMaterial: getMaterial(materialTypes.black),
      }
    case representationTypes.space:
      console.log("type:  "+type)
      //zone, room, infrastructure, blocked
      if(type==="zone")
        return {
          activeMaterial: getMaterialClassic(materialTypesClassic.openSpacePattern),
          inactiveMaterial: getMaterialClassic(materialTypesClassic.openSpacePattern),
        }
      else if (type==="service")
        return {
          activeMaterial: getMaterialClassic(materialTypesClassic.servicePattern),
          inactiveMaterial: getMaterialClassic(materialTypesClassic.servicePattern),
        }
      else if (type==="room")
        return {
          activeMaterial: getMaterialClassic(materialTypesClassic.roomsPattern),
          inactiveMaterial: getMaterialClassic(materialTypesClassic.roomsPattern),
        }
      else if (type==="infrastructure")
        return {
          activeMaterial: getMaterialClassic(materialTypesClassic.infrastructurePattern),
          inactiveMaterial: getMaterialClassic(materialTypesClassic.infrastructurePattern),
        }
      else if (type==="blocked")
        return {
          activeMaterial: getMaterialClassic(materialTypesClassic.blockedPattern),
          inactiveMaterial: getMaterialClassic(materialTypesClassic.blockedPattern),
        }
      else
        return {
          activeMaterial: getMaterial(materialTypes.random),
          inactiveMaterial: getMaterial(materialTypes.random),
        }
    default:
      return {
        activeMaterial: getMaterial(materialTypes.pink),
        inactiveMaterial: getMaterial(materialTypes.transparent),
      }
  }
}

export const doorDecoGeometry = createDoorDecoGeometry()

function createDoorDecoGeometry () {
  const face = new Shape()

  face.moveTo(-.4, -.1)
  face.lineTo(-.4, -.8)
  face.absarc(-.4, -.1, .8, -Math.PI / 2, 0, false)
  face.absarc(-.4, -.1, .78, 0, 354 / 360 * (-Math.PI / 2), true)
  face.lineTo(-.38, -.1)

  return new ShapeBufferGeometry(face)
}

/*
function createDoubleDoorDecoGeometry() {
    const face = new Shape()

    face.moveTo(0, -.1)
    face.absarc(.4, -.1, .4, Math.PI, -Math.PI / 2, false)
    face.lineTo(.4, -.1)

    const face2 = new Shape()

    face2.moveTo(-.4, -.1)
    face2.lineTo(-.4, -.4)
    face2.absarc(-.4, -.1, .4, -Math.PI / 2, 0, false)

    return new ShapeBufferGeometry(face)
}*/