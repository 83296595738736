import { floorPlanActionTypes } from '../constants'
import { undoActionTypes } from '../../common/redux/undo/undo-actions'
import { listToTree } from '../../common/utils'

let initialState = {
  placedUnitsTree: [],
  placedUnits: [],
  unassignedDepartments: [],
  placedDepartments: [],
  assignedFloors: [],
  dirtyFlag: false,
}

function placedOrganisationalUnitsReducer (state = initialState, action) {

  switch (action.type) {

    case floorPlanActionTypes.SET_PLAN_DIRTY:
      return {
        ...state,
        dirtyFlag: true,
      }

    case floorPlanActionTypes.FETCH_PLACED_ORGANISATIONAL_UNITS_FULFILLED:
    case undoActionTypes.FETCH_ORGANISATIONAL_UNITS_AFTER_UNDO_FULFILLED:
    case undoActionTypes.UNDO_OPTIMISATION_FULFILLED:
      return {
        ...state,
        placedUnitsTree: action.payload.data,
        placedUnits: treeToList(action.payload.data),
        dirtyFlag: false,
      }
    case floorPlanActionTypes.FETCH_DEPARTMENT_ASSIGNMENTS_FULFILLED:
      const { allOrganisationalUnits, placedDepartments, floors } = action.payload.data

      console.log("placed Departments:")
      console.log(placedDepartments)

      console.log("allUnits:")
      console.log(allOrganisationalUnits)

      const assignedFloors = [...floors]

      floors.forEach(floor => {
        let allOrganisationalUnitsInFloor = JSON.parse(JSON.stringify(allOrganisationalUnits))

        let departmentsInFloor = placedDepartments.filter(department => department.floorId === floor.id)

        departmentsInFloor = allOrganisationalUnitsInFloor.filter(allUnit => departmentsInFloor.find(unit => unit.id === allUnit.id))

        for (let i = allOrganisationalUnitsInFloor.length - 1; i >= 0; i--) {
          const unit = allOrganisationalUnitsInFloor[i]
          unit.floorId = floor.id
          unit.floorIndex = floor.index

          if (unit.type === 'SUPER_DEPARTMENT' && !departmentsInFloor.find(department => department.parentId === unit.id)) {
            allOrganisationalUnitsInFloor.splice(i, 1)
          }

          if (unit.type === 'DEPARTMENT' && !departmentsInFloor.find(department => department.id === unit.id)) {
            allOrganisationalUnitsInFloor.splice(i, 1)
          }

          if (unit.type === 'TEAM' && !departmentsInFloor.find(department => department.id === unit.parentId)) {
            allOrganisationalUnitsInFloor.splice(i, 1)
          }
        }

        assignedFloors.find(f => f.id === floor.id).organisationalUnits = listToTree(allOrganisationalUnitsInFloor, 'parentId', 'organisationalUnits')
      })

      let unassignedDepartments = JSON.parse(JSON.stringify(allOrganisationalUnits))

      for (let i = unassignedDepartments.length - 1; i >= 0; i--) {
        const department = unassignedDepartments[i]

        if (department.type === 'TEAM' ||
          placedDepartments.find(placed => placed.id === department.id)) {
          unassignedDepartments.splice(i, 1)
        }
      }

      unassignedDepartments = listToTree(unassignedDepartments, 'parentId', 'organisationalUnits')

      for (let i = unassignedDepartments.length - 1; i >= 0; i--) {
        const superDepartment = unassignedDepartments[i]

        if (superDepartment.organisationalUnits.length === 0) {
          unassignedDepartments.splice(i, 1)
        }
      }

      return {
        ...state,
        placedDepartments: placedDepartments,
        assignedFloors: assignedFloors,
        unassignedDepartments: unassignedDepartments,
      }

    default:
      return { ...state }
  }

}

function treeToList (units) {
  let flatList = units.flatMap(unit => unit.organisationalUnits)
  if (flatList.length > 0) {
    return units.concat(treeToList(flatList))
  }
  return units
}

export function getSelectedOrganisationalUnit (state) {
  return state.placedOrganisationalUnits.placedUnits.find(unit => unit.id === state.appState.selectedOrganisationalUnitId) || {}
}

export function getOrganisationalUnitFromSelectedRow (state) {
  return state.placedOrganisationalUnits.placedUnits.find(unit => unit.rows.find(row => row.uuid === state.floorPlanState.selectedRowId))
}

export function getSelectedRow (state) {
  let unit = getOrganisationalUnitFromSelectedRow(state)

  return unit.rows.find(row => row.uuid === state.floorPlanState.selectedRowId)
}

export function getPlacedOrganisationalUnit (state, unitId) {
  return state.placedOrganisationalUnits.placedUnits.find(unit => unit.id === unitId) || {}
}

export function getSelectedOrganisationalUnitSummary (state) {
  let selected = getSelectedOrganisationalUnit(state)
  return selected && selected.summary ? selected.summary : getFloorSummary(state)
}

export function getFloorSummary (state) {
  return state.floorPlan.floorSummary ? state.floorPlan.floorSummary : {}
}

export default placedOrganisationalUnitsReducer