import { geometryTypes } from '../config/GeometryTypes'
import { PolyEdgeController } from './PolyEdgeController'
import { createGeometryByRepresentationType } from '../factories/GeometryFactory'
import { Vector2 } from 'three'
import { geometryToPositionArray, vector3ToVector2,getDistanceToEdge } from '../helper/GeometryHelper'
import { representationTypes } from '../config/RepresentationTypes'

export class PolygonController extends PolyEdgeController {

  closingDistance
  minVerticesCount

  constructor (floorPlanerController, representationType, group) {
    super(floorPlanerController, representationType, group)
    console.log("PolygonController")

    this.closingDistance = 1.0
    this.minVerticesCount = 3
  }

  onMouseMove (event, geometry, currentGeometries) {
    //console.log("mouse moveeee")

    super.onMouseMove(event, geometry, currentGeometries)
  }

  onLeftMouseDown (event, geometry, currentGeometries) {
    console.log("PolygonController lmd")

    if (!geometry) {
      console.log("new")
      return this.onLeftMouseDownNew(event, currentGeometries)
    }

    console.log(this.vertexMode)
    if(this.vertexMode===true){
      return this.onLeftMouseDownEdit(event, geometry, currentGeometries)
    }else{
      return this.onLeftMouseDownEdit(event, geometry, currentGeometries)
      //super.onLeftMouseDownDrag()   NEU!!
    }
  }


  onLeftMouseDownNew (event, currentGeometries) {
    const polygon = createGeometryByRepresentationType(this.representationType, this.group)
    return super.onLeftMouseDownNew(event, polygon, currentGeometries)
  }

  onLeftMouseDownEdit (event, polygon, currentGeometries) {
    console.log('onLeftMouseDownEdit: '+polygon)

    if (!polygon)
      return false


    console.log("poly: ")
    console.log(polygon)
    console.log("currentg length: "+currentGeometries.length)
    console.log(polygon.getAction())

    switch (polygon.getAction()) {
      case polygon.actions.addVertex:
        if (currentGeometries.length !== 1)
          return false

        const currentPosition = this.getCurrentPosition(event, polygon.getLastVertex().position)

        const firstVertex = polygon.getFirstVertex()

        let firstPos=new Vector2(firstVertex.position.x,firstVertex.position.y)
        console.log("POLYGOOON: ")
        //console.log(polygon)

        if (firstPos.distanceTo(currentPosition) < this.closingDistance) {

          console.log("polygon remove");

          let lastEdge=polygon.edgeGroup.getEdgeByIndex(-1)
          polygon.edgeGroup.removeEdge(lastEdge)

          console.log("polygon close");
          polygon.close()
          //console.log(polygon)
          this.removeGeometryFromCurrentGeometries(polygon, currentGeometries)
          console.log("removed")
          //console.log(polygon)
          this.floorPlanerController.saveChanges()
        } else {
          let lastPos= polygon.vertexGroup.getVertexByIndex(0).position
          let firstPos= polygon.vertexGroup.getVertexByIndex(-2).position
          let distToLast = currentPosition.distanceTo(lastPos)
          let distToFirst = currentPosition.distanceTo(firstPos)

          console.log(distToFirst+" :: "+distToLast)
          let addNewEdge=true
          if(distToLast<=0.1) addNewEdge=false
          if(distToFirst<=0.1) addNewEdge=false
          if(addNewEdge){
            console.log("addVertexxxx")
            //console.log(currentGeometries.length)
            //console.log(polygon.edgeGroup.children.length)

            if(polygon.edgeGroup.children.length > 1 ){
              let lastEdge=polygon.edgeGroup.getEdgeByIndex(-1)
              polygon.edgeGroup.removeEdge(lastEdge)    //-EDGE

              console.log("removed")
              console.log( polygon.edgeGroup.children.length)
            }

            const lineVertexA=polygon.vertexGroup.getVertexByIndex(-1)
            polygon.addVertex(currentPosition)            //+EDGE
            const lineVertexB=polygon.vertexGroup.getVertexByIndex(0)

            //polygon.edgeGroup.addEdge(lineVertexA, lineVertexB)
            console.log("add")
            console.log( polygon.edgeGroup.children.length)

            //polygon.close()
            const startVertex = polygon.vertexGroup.getVertexByIndex(-1)
            const endVertex = polygon.vertexGroup.getVertexByIndex(0)
            polygon.edgeGroup.addEdge(startVertex, endVertex)   //+EDGE
            console.log("close")
            console.log( polygon.edgeGroup.children.length)
            console.log( polygon.vertexGroup.children.length)
          }else{
            console.log("dont add")
          }

          //console.log(polygon)
        }
        console.log(polygon)
        return true
      case polygon.actions.select:
        console.log("EDIT SELECT")
        return this.onLeftMouseDownEditSelect(polygon, currentGeometries)
      default:
        return false
    }
  }

  onLeftMouseDownEditSelect (polygon, currentGeometries) {
    console.log("polygoncontroller")
    const raycastHitObject = this.floorPlanerController.getRaycastHitObjectForGeometryByGeometryTypes(polygon,
      [geometryTypes.vertex, geometryTypes.edge, geometryTypes.face])

    if (!raycastHitObject)
      return false

    switch (raycastHitObject.geometryType) {
      case geometryTypes.vertex:
        console.log("Action: dragvertex (vertex)")
        polygon.setDraggedVertex(raycastHitObject)
        polygon.setAction(polygon.actions.dragVertex)
        return true
      case geometryTypes.edge:
        console.log("Action: dragvertex (edge)")
        if(this.vertexMode===true){
          console.log("vertex")
          polygon.addVertexToEdge(raycastHitObject, this.floorPlanerController.getCurrentPosition())
          polygon.setAction(polygon.actions.dragVertex)
        }else{

          let cEdge=raycastHitObject
          console.log("edge: "+ cEdge)
          if(cEdge!=null){
            const innerGeometries=this.floorPlanerController.getGeometriesByRepresentationType (representationTypes.wall)
            console.log("inner:"+innerGeometries.length)
            console.log(innerGeometries)

            let vertexCollection=[];

            for(let iGeom=0; iGeom<innerGeometries.length;iGeom++){
              console.log(innerGeometries[iGeom])
              let children=innerGeometries[iGeom].vertexGroup.children;
              console.log("children")
              console.log(children)
              for(let iChild=0; iChild<children.length; iChild++){
                console.log("child: "+iChild)
                console.log(children[iChild])
                vertexCollection.push(children[iChild])
              }
            }
            console.log("VC:")
            console.log(vertexCollection)


            polygon.setAction(polygon.actions.dragVertex)
            let geometry=cEdge.getGeometry()
            let vertices=cEdge.getVertices()
            console.log("vertices")
            console.log(vertices)
            console.log("geometry")
            console.log(geometry)

            console.log("draggedVertices: "+vertices.length)
            geometry.setDraggedEdge(cEdge)

            let pos1=vector3ToVector2(vertices[0].position)
            let pos2=vector3ToVector2(vertices[1].position)

            console.log("v 1 2")
            console.log(pos1)
            console.log(pos2)
            //find Vertices on Edge
            let verticesOnEdge=[]
            for(let iVert=0; iVert<vertexCollection.length; iVert++){
              let maxDist=0.3//0.01
              let posV=vector3ToVector2(vertexCollection[iVert].position)
              let dist=getDistanceToEdge(posV,pos1,pos2)
              console.log("dist"+iVert+": "+dist)
              if(dist<maxDist){
                //vertexCollection[iVert].railEdge="draggedEdge"
                console.log("vertex: "+ iVert )
                console.log(vertexCollection[iVert].getEdges())
                verticesOnEdge.push(vertexCollection[iVert])
                vertexCollection[iVert].railEdge=cEdge
              }
            }
            console.log("verticesOnEdge:")
            console.log(verticesOnEdge)


            /*
            const representationGroupWall = this.floorPlanerController.representationGroups.get(representationTypes.wall)
            const geometriesWall=representationGroupWall.children
            console.log("geometriesWall:")
            console.log(geometriesWall)
            // Vertices on wall:
            */


            console.log("DRAGEDGE: "+geometry)
          }
        }

        return true
      default:
        return false
    }
  }

  onLeftMouseUp (event, geometry) {
    return super.onLeftMouseUp(event, geometry)
  }

  onLeftMouseDoubleClick (event, polygon, currentGeometries) {
    return super.onLeftMouseDoubleClick(event, polygon, currentGeometries, 3)
  }

  onEnterDown (event, polygon, currentGeometries) {
    return super.onEnterDown(event, polygon, currentGeometries, this.minVerticesCount)
  }

  removeLastPolyEdge(geometry){

    let lastVertex=geometry.vertexGroup.getVertexByIndex(1)
    let lastPos=lastVertex.position

    console.log("removeLastEdgePolygonEdge")
    //console.log(geometry.edgeGroup.children)

    let posArray=geometryToPositionArray(geometry)
    console.log(posArray)
    console.log(geometry.vertexGroup.children)

    let lastEdge=geometry.edgeGroup.getEdgeByIndex(-1)
    geometry.edgeGroup.removeEdge(lastEdge)
    lastEdge=geometry.edgeGroup.getEdgeByIndex(-1)//-EDGE
    geometry.edgeGroup.removeEdge(lastEdge)

    console.log("removed")
    console.log(geometry.edgeGroup.children)

    console.log( geometry.edgeGroup.children.length)

    console.log("removeLastVertex")
    //ab hier: alter code
    geometry.removeLastVertex()

    posArray=geometryToPositionArray(geometry)
    console.log(posArray)
    //geometry.updateVertex(this.getCurrentPosition(event, geometry.getLastVertex()), geometry.getLastVertex())
    //console.log(geometry)

    //neu!!
    const startVertex = geometry.vertexGroup.getVertexByIndex(-1)
    const endVertex = geometry.vertexGroup.getVertexByIndex(0)
    geometry.edgeGroup.addEdge(startVertex, endVertex)


    console.log("lastPos")
    console.log(lastPos)
    if(lastPos!==null){
      console.log(lastPos.x+"  "+lastPos.y)

      if(lastPos.x)
        startVertex.setPosition(lastPos)
    }
    posArray=geometryToPositionArray(geometry)
    console.log(posArray)
  }


}