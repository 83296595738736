import React, { Component } from 'react'

import Text, { textTypes } from '../common/components/Text'

import Button from '../common/components/Button'
import { activateAndLoadProject, createNewProject, deleteProjectAndFetchProjects, fetchUserProjectsList, uploadProjectFile } from '../common/redux/projects/projects-actions'
import { startOnBoarding } from '../onboarding/actions/onboarding-actions'
import { connect } from 'react-redux'
import { Scrollbars } from 'react-custom-scrollbars'

import './StartApp.scss'
import { setAdvancedSettings } from '../common/redux/settings/settings-actions'
import { withTranslation } from 'react-i18next'
import IconButton from '../common/components/IconButton'
import { iconTypes } from '../common/components/icons/IconFactory'
import ModalConsumer from '../common/components/modal/ModalConsumer'
import TemplateDialog from '../common/components/TemplateDialog'
import { ImportButton } from './ImportButton'
import UploadDialog from '../header/components/UploadDialog'
import LoadProjectDialog from '../header/components/dialogs/LoadProjectDialog'

class StartApp extends Component {

  constructor (props) {
    super(props)

    this.state = { selectedProjectId: null }
  }

  componentDidMount () {
    this.props.fetchUserProjectsList()
    this.props.setAdvancedSettings(false)
  }

  setSelectedProject (projectId) {

    this.setState({ selectedProjectId: projectId })
  }

  deleteProject (projectId, projectname, showModal) {
    showModal(TemplateDialog, {
      className: 'delete-project-dialog',
      text: 'Are you sure you want to permanently delete the project "' + projectname + '"? You can\'t undo this action.',
      title: 'Delete project',
      rightButtonText: 'Delete',
      rightButtonVariant: 'delete',
      onCancel: () => {},
      onRightButtonClicked: () => this.props.deleteProjectAndFetch(projectId),
    })
  }

  handleOpenProject (showModal) {
    showModal(UploadDialog,
      {
        title: 'Upload project file',
        upload: this.props.uploadCOPFile,
      })
  }

  handleOpenViewAllModalClick (showModal, component, data) {
    showModal(component, data)
  }

  renderProjectItem (showModal) {
    return <React.Fragment>

      <div className={'recent-project-border'}/>
      {
        this.props.projects.map((project, index) => {
          const selectedStyle = this.state.selectedProjectId === project.value ? ' selected' : ''
          return (
            <React.Fragment key={index}>
              <div className={'recent-project' + selectedStyle} onClick={() => this.setSelectedProject(project.value)} onDoubleClick={() => this.props.activateAndLoadProject(project.value)}>
                <div className={'recent-project-text-container'}>
                  <Text outerClassName={'recent-project-text-bold'}>{project.label}</Text>
                  <Text outerClassName={'recent-project-date'}>{project.date}</Text>
                </div>
                <div className={'recent-project-icon-container'}>
                  {selectedStyle ? <IconButton styleClass={'recent-project-delete'} icon={iconTypes.delete} onClick={() => this.deleteProject(project.value, project.label, showModal)}/> : null}
                  <IconButton className={'recent-project-arrow-icon'} styleClass={'recent-project-open'} icon={iconTypes.arrow} onClick={() => this.props.activateAndLoadProject(project.value)}/>
                </div>
              </div>
              <div className={'recent-project-border'}/>
              </React.Fragment>)
          },
        )
      }
    </React.Fragment>
  }

  render () {
    const oldProjectsAvailable = this.props.projects.length
    const { t } = this.props

    return (
      <div className={'start-app-container'}>
        <div className={'start-app-assistant'}>
          <div className={'start-app-assistant-text'}>
            <Text darkMode component={textTypes.runningTitle} outerClassName={'start-app-title'}>{t('how_you_want_to_start')}</Text>
            <Text darkMode multiline component={textTypes.hero} outerClassName={'start-app-hero'}>{t('create_floorplan_in_minutes')}</Text>
            <Text darkMode multiline component={textTypes.paragraph} outerClassName={'start-app-copy-text'}>{t('create_floorplan_easy')}</Text>
            <Button className={'start-app-button'} variant={'orange-filled'} onClick={() => this.props.startOnBoarding()}>{t('start')}</Button>
          </div>
        </div>
        <div className={'start-app-right'}>
          <ModalConsumer>
            {({ showModal }) => (
              <div className={'start-app-advanced' + (oldProjectsAvailable ? '' : ' expand')}>
                <Text multiline component={textTypes.hero} outerClassName={'start-app-hero'}>{t('set_up_deatiled_project')}</Text>
                <Text multiline component={textTypes.paragraph} outerClassName={'start-app-copy-text'}>{t('define_from_ground')}</Text>
                <div className={'start-planing-import-container'}>
                  <Button className={'start-app-button planning'} onClick={() => this.props.startOnBoarding(true)}>{t('start_planning')}</Button>
                  <ImportButton component={textTypes.paragraph} outerClassName={'import-button'} onClick={() => this.handleOpenProject(showModal)}/>
                </div>
              </div>
            )}
          </ModalConsumer>

          <ModalConsumer>
            {({ showModal }) => (
              <div className={'start-app-recent-projects'}>
                <div className={'start-app-recent-projects-scroll-area'}>
                  <div className={'recent-projects-container'}>
                    <Text component={textTypes.runningTitle} outerClassName={'start-app-recent-project'}>{t('recent_projects')}</Text>
                    <Button className={'view-all-button'} secondIcon={iconTypes.openFolder} iconColor={'#000'}
                            onClick={() => this.handleOpenViewAllModalClick(showModal, LoadProjectDialog)}>{t('view_all')}</Button>
                  </div>
                  <Scrollbars autoHide>
                    {this.renderProjectItem(showModal)}
                  </Scrollbars>
                </div>
              </div>
            )}
          </ModalConsumer>
        </div>

      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    projects: state.projects.userProjects ? state.projects.userProjects
      .slice(1)
      .slice(-5)
      .map(project => ({
        label: project.name,
        value: project.id,
        date: project.lastModifiedDate,
      }))
      .reverse() : [],
  }
}

const mapDispatchToProps = {
  createNewProject: createNewProject,
  startOnBoarding: startOnBoarding,
  fetchUserProjectsList: fetchUserProjectsList,
  activateAndLoadProject: activateAndLoadProject,
  deleteProjectAndFetch: deleteProjectAndFetchProjects,
  uploadCOPFile: uploadProjectFile,

  setAdvancedSettings: setAdvancedSettings,
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(StartApp))