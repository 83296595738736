import React from 'react'
import { Prompt } from 'react-router-dom'
import { history } from '../../store'

import RouteLeavingDialog from './RouteLeavingDialog'

export class RouteLeavingGuard extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      modalVisible: false,
      lastLocation: null,
      confirmedNavigation: false,
    }
  }

  navigate (path) {
    history.push(path)
  }

  showModal = (location) => {
    this.setState({
      modalVisible: true,
      lastLocation: location,
    })
  }

  closeModal = (callback) => {
    this.setState({
      modalVisible: false,
    }, callback)
    if (this.props.onCancel)
      this.props.onCancel()
  }

  handleBlockedNavigation = (nextLocation) => {
    if (!this.state.confirmedNavigation && this.props.when) {
      this.showModal(nextLocation)
      return false
    }

    return true
  }

  handleConfirmNavigationClick = () => this.closeModal(() => {
    if (this.state.lastLocation) {
      this.setState({
        confirmedNavigation: true,
      }, () => {
        // Navigate to the previous blocked location with your navigate function
        this.navigate(this.state.lastLocation.pathname + (this.props.withSearch ? this.state.lastLocation.search : ''))
      })
    }
  })

  onConfirm () {
    this.props.confirm()
    this.handleConfirmNavigationClick()
  }

  render () {

    return (
      <React.Fragment>
        <Prompt
          when={this.props.when}
          message={this.handleBlockedNavigation}/>
        {this.state.modalVisible && <RouteLeavingDialog onDiscard={this.handleConfirmNavigationClick}
                                                        onConfirm={() => this.onConfirm()}
                                                        onCancel={this.closeModal}
                                                        lines={this.props.lines}/>}
      </React.Fragment>
    )
  }
}

export default RouteLeavingGuard