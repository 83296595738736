import React from 'react'

import NavigationMenu from './NavigationMenu'
import VariationsNavigation from './VariationsNavigation'

import './NavigationBar.scss'

export function NavigationBar ({ onboarding = false }) {
  return (
    <div className={'navigation-bar' + (onboarding ? ' onboarding' : '')}>
      <NavigationMenu/>
      <VariationsNavigation/>
    </div>
  )
}

