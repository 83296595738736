import React, { Component } from 'react'
import Dialog, { DialogContent } from '../components/Dialog'
import { findProjectInProjectsList } from '../redux/projects/projects-reducer'
import { activateAndLoadProject, fetchUserProjectsList, loadProject } from '../redux/projects/projects-actions'
import { resolveNotification } from '../redux/notifications/notifications-actions'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import Text from '../components/Text'
import './InactiveProjectErrorDialog.scss'
import Button from '../components/Button'
import moment from 'moment'
import { getCurrentProjectId } from '../utils'
import { withTranslation } from 'react-i18next'
import i18n from 'i18next';


function getDurationText (lastModifiedDate) {

  let modified = moment(lastModifiedDate)
  let now = moment()

  if (modified.diff(now, 'hours') > 24) {
    return  i18n.t('last_edited_on') + ` ${modified.format('MMMM Do YYYY, h:mm:ss a')}`
  }

  let duration = moment.duration(modified.diff(now))
  return i18n.t('last_edited_about') + ` ${duration.humanize(true)}`
}

function Project ({ project, onClick }) {

  return (
    <div className={'project'}>
      {project &&
      <div className={'project-description'}>
        <div className={'project-description-title-row'}>
          <div className={'project-description-title-container'}>
            <Text outerClassName={'project-description-name'} multiline>{project.name}</Text>
            <Text outerClassName={'project-description-client'}>{project.companyName}</Text>
          </div>
          <Button onClick={onClick} variant={'contained'}>{i18n.t('continue')}</Button>
        </div>
        <Text outerClassName={'project-description-date'}>{getDurationText(project.lastModifiedDate)}</Text>
      </div>
      }
    </div>
  )

}

class InactiveProjectErrorDialog extends Component {

  componentDidMount () {
    this.props.fetchUserProjectsList()
  }

  render () {
    const { t } = this.props
    const message = t('already_opened_another_project')

    return (
      <Dialog className={'inactive-project-error-dialog'}
              innerClassName={'inactive-project-error-dialog-inner'}
              titleClassName={'inactive-project-error-dialog-title'}
              onCancel={() => this.handleCancel()}
              title={t('conflict')}>
        <DialogContent className={'inactive-project-error-dialog-content'}>
          <div className={'error-dialog-message'}>
            <Text multiline>{message}</Text>
          </div>
          <div className={'projects-list'}>
            <Text outerClassName={'label'}>{t('currently_active_projects')}</Text>
            <Project project={this.props.currentProject} onClick={() => this.handleActivateCurrent()}/>
            <Project project={this.props.otherProject} onClick={() => this.handleLoadActive()}/>
          </div>
        </DialogContent>
      </Dialog>
    )
  }

  handleCancel () {
    this.resolveInactiveProjectError()
    this.props.push('/')
  }

  handleLoadActive () {
    const { notification: { data } } = this.props
    this.resolveInactiveProjectError()
    this.props.loadProject(data.currentActiveProject)
  }

  handleActivateCurrent () {
    const projectId = this.props.currentProject.id
    this.resolveInactiveProjectError()
    this.props.activateAndLoadProject(projectId)
  }

  resolveInactiveProjectError () {
    this.props.resolveNotification(this.props.notification)
  }

}

let mapStateToProps = (state, ownProps) => {

  const { notification: { data } } = ownProps
  const currentProjectId = getCurrentProjectId(state)

  return {
    currentProject: currentProjectId ? findProjectInProjectsList(state, currentProjectId) : null,
    otherProject: data ? findProjectInProjectsList(state, data.currentActiveProject) : null,
  }
}

let mapDispatchToProps = {
  fetchUserProjectsList,
  activateAndLoadProject,
  loadProject,
  resolveNotification,
  push,
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(InactiveProjectErrorDialog))