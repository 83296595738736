import React from 'react'
import TitleBar from '../../../header/components/TitleBar'
import { NavigationBar } from '../../../header/components/NavigationBar'
import AuthConsumer from '../../auth/AuthConsumer'
import AuthService from '../../auth/auth.service'
import { withRouter } from 'react-router'
import { viewNames } from '../../globalConstants'

import './AppContainer.scss'

function AppContainer ({ children, location }) {

  return (
    <AuthConsumer>
      {({ user }) =>
        <React.Fragment>
          <TitleBar/>
          {AuthService.loginFinished(user, location) && <NavigationBar onboarding={location.pathname.includes(viewNames.ONBOARDING) || location.pathname.endsWith(viewNames.PROJECTS)}/>}
          {AuthService.loginFinished(user, location) ? children : null}
        </React.Fragment>
      }
    </AuthConsumer>
  )
}

export default withRouter(AppContainer)