
import panGif from '../../assets/gif/drawing/00_HAW_CCD_Pan.gif'

import selectGif from '../../assets/gif/drawing/00_HAW_CCD_Select.gif'
import scaleGif from '../../assets/gif/drawing/01_HAW_CCD_Scale.gif'
import polyGif from '../../assets/gif/drawing/02_HAW_CCD_Poly.gif'
import rectangleGif from '../../assets/gif/drawing/02_HAW_CCD_rect.gif'
import doorsGif from '../../assets/gif/drawing/03_HAW_CCD_doors.gif'
import pillarsGif from '../../assets/gif/drawing/03_HAW_CCD_pillars.gif'
import windowsGif from '../../assets/gif/drawing/03_HAW_CCD_windows.gif'
import blockedGif from '../../assets/gif/drawing/04_HAW_CCD_blocked.gif'
import infrastructureGif from '../../assets/gif/drawing/04_HAW_CCD_infrastructure.gif'
import linksGif from '../../assets/gif/drawing/04_HAW_CCD_links.gif'
import meetingroomsGif from '../../assets/gif/drawing/04_HAW_CCD_meetingrooms.gif'
import openspaceGif from '../../assets/gif/drawing/04_HAW_CCD_openspace.gif'
import autoaxesGif from '../../assets/gif/drawing/05_HAW_CCD_autoaxes.gif'
import deleteaxesGif from '../../assets/gif/drawing/05_HAW_CCD_deleteaxes.gif'
import drawaxesGif from '../../assets/gif/drawing/05_HAW_CCD_drawaxes.gif'
import zonesGif from '../../assets/gif/drawing/05_HAW_CCD_zonetypes.gif'
import axesGif from '../../assets/gif/drawing/06_HAW_CCD_axes.gif'

import { tools, shortcuts } from './Tools'



const generalInfo = {
  image: null,
  shortcuts: [shortcuts.esc, shortcuts.pan],
  textTitle: 'General shortcuts',
  text: 'These are the general shortcuts you can use at any time',
  shortText: 'These are the general shortcuts you can use at any time',
}
const generalInfos = [
  generalInfo,
]

const uploadInfo = {
  tool: null,
  image: null,
  shortcuts: [shortcuts.esc, shortcuts.pan],
  textTitle: 'Upload Image',
  text: 'The first step for preparing your plan is to upload an image-file. CoDesigner supports PNG and JPEG file-formats.',
  shortText: 'The first step for preparing your plan is to upload an image-file. CoDesigner supports PNG and JPEG file-formats.',
}
const uploadInfos = [
  uploadInfo,
]

const scaleHelpInfo = {
  tool: tools.scale,
  image: scaleGif,
  shortcuts: [shortcuts.shift],
  textTitle: 'Define scale',
  text: 'Click anywhere on the image to set the first point. After setting the second one, enter their distance.',
  shortText: 'Click anywhere on the image to set the first point. After setting the second one, enter their distance.',
}

const selectInfo = {
  tool: tools.select,
  image: selectGif,
  shortcuts: [],
  textTitle: 'Select',
  text: 'Click on objects to select them. Drag objects to move them, e.g. axes',
  shortText: 'Click on objects to select them.',
}

const panInfo = {
  tool:tools.pan,
  image: panGif,
  shortcuts: [],
  textTitle: 'Pan',
  text: 'Click on objects to select them.',
  shortText: 'Click on objects to select them.',
}

const scaleInfos = [
  scaleHelpInfo,
]

const spaceGeneralHelpInfo = {
  image: null,
  shortcuts: [shortcuts.backspace, shortcuts.esc],
  textTitle: 'Create spaces',
  text: 'Spaces are areas where people will work or have meetings. Create Spaces for openspaces, meeting rooms and areas between rooms, where people move around. To create spaces you can use rectangles or polygonal shapes.',
  shortText: 'Create Spaces for openspaces, meeting rooms and areas between rooms, where people move around.',
}
const rectangleHelpInfo = {
  tool:tools.outline,
  image: rectangleGif,
  shortcuts: [shortcuts.shift, shortcuts.ctrl],
  textTitle: 'Rectangles',
  text: 'Click and drag anywhere to add a rectangle. Use the handles to adjust the size.',
  shortText: 'Click and drag anywhere to add a rectangle. Use the handles to adjust the size.',
}
const polygonHelpInfo = {
  tool:tools.outline,
  image: polyGif,
  shortcuts: [shortcuts.shift, shortcuts.ctrl, shortcuts.doubleClickPolygon, shortcuts.enterPolygon],
  textTitle: 'Outer Wall',
  text: 'Click anywhere to create the first point, again for the second and so on. You can create as many points as you like. Click on the first point again to close your path.',
  shortText: 'Click and drag anywhere to add a polygon',
}

const wallHelpInfo = {
  tool:tools.wall,
  image: null,
  shortcuts: [shortcuts.shift, shortcuts.ctrl, shortcuts.doubleClickPolygon, shortcuts.enterPolygon],
  textTitle: 'Inner Wall',
  text: 'Click anywhere to create the first point, again for the second and so on. You can create as many points as you like. Click on the first point again to close your path.',
  shortText: 'Click and drag anywhere to add a polygon',
}
const spacesHelpInfos = [
  spaceGeneralHelpInfo,
  rectangleHelpInfo,
  polygonHelpInfo,
]

const doorHelpInfo = {
  tool: tools.door,
  image: doorsGif,
  shortcuts: [shortcuts.alt],
  textTitle: 'Doors',
  text: 'Place doors between spaces that need to be connected.',
  shortText: 'Place doors between spaces that need to be connected.',
}
const windowHelpInfo = {
  tool: tools.window,
  image: windowsGif,
  shortcuts: [shortcuts.alt, shortcuts.ctrlD],
  textTitle: 'Windows',
  text: 'Place windows on the outer lines of spaces.',
  shortText: 'Place windows on the outer lines of spaces.',
}
const pillarHelpInfo = {
  tool: tools.pillar,
  image: pillarsGif,
  shortcuts: [shortcuts.alt, shortcuts.ctrlD],
  textTitle: 'Pillars',
  text: 'Place pillars anywhere on your plan.',
  shortText: 'Place pillars anywhere on your plan.',
}
const detailsHelpInfos = [
  doorHelpInfo,
]

const linkHelpInfo = {
  image: linksGif,
  shortcuts: [shortcuts.cmd],
  textTitle: 'Link spaces',
  text: 'To connect spaces together click on doors or already existing link paths.',
  shortText: 'To connect spaces together click on doors or already existing link paths.',
}
const linksHelpInfos = [
  linkHelpInfo,
]

const areaOpenspaceInfo = {
  tool: tools.markzone,
  image: openspaceGif,
  shortcuts: [],
  textTitle: 'Openspace',
  text: 'Click in the spaces to make them Openspaces.',
  shortText: 'Click in the spaces to make them Openspaces.',
}

const areaRoomInfo = {
  tool: tools.markroom,
  image: meetingroomsGif,
  shortcuts: [],
  textTitle: 'Room',
  text: 'Click in the spaces to make them Rooms with a single application.',
  shortText: 'Click in the spaces to make Rooms with a single application.',
}

const areaInfrastructureInfo = {
  tool: tools.markinfrastructure,
  image: infrastructureGif,
  shortcuts: [],
  textTitle: 'Infrastructure',
  text: 'Click in the spaces to make them areas where people move.',
  shortText: 'Click in the spaces to make them areas where people move.',
}

const areaServiceInfo = {
  tool: tools.markservice,
  image: meetingroomsGif,
  shortcuts: [],
  textTitle: 'Service',
  text: 'Click in the spaces to make them service areas, e.g. toilet, kitchen, etc..',
  shortText: 'Click in the spaces to make service areas, e.g.toilet, kitchen, etc..',
}

const areaBlockedInfo = {
  tool: tools.markblocked,
  image: blockedGif,
  shortcuts: [],
  textTitle: 'Blocked',
  text: 'Click in the spaces to make them areas that are blocked.',
  shortText: 'Click in the spaces to make them areas that are blocked.',
}


const axesDrawInfo = {
  tool:tools.axesedit,
  image: drawaxesGif,
  shortcuts: [],
  textTitle: 'Draw Axes',
  text: 'Click anywhere in an openspace to create the first point, again for the second and so on.',
  shortText: 'Click anywhere to create the first point, again for the second and so on.',
}

const axesAutoInfo = {
  tool: tools.axesautogenerate,
  image: autoaxesGif,
  shortcuts: [],
  textTitle: 'Auto Axes',
  text: 'Click in the openspaces to let CoDesigner create the axes automatically.',
  shortText: 'Click in the openspaces to let CoDesigner create the axes automatically.',
}

const axesDeleteInfo = {
  tool: tools.axesdelete,
  image: deleteaxesGif,
  shortcuts: [],
  textTitle: 'Delete Axes',
  text: 'Click in the openspace delete the axes.',
  shortText: 'Click in the openspace to delete the axes.',
}


export function getToolHelpInfo (tool) {
  switch (tool) {
    case tools.scale:
      return scaleHelpInfo
    case tools.select:
      return selectInfo
    case tools.pan:
      return panInfo
    case tools.outline:
      return polygonHelpInfo
    case tools.wall:
      return wallHelpInfo  // polylineHelpInfo
    case tools.door:
      return doorHelpInfo
    case tools.window:
      return windowHelpInfo
    case tools.pillar:
      return pillarHelpInfo
    case tools.markzone:
      return areaOpenspaceInfo
    case tools.markroom:
      return areaRoomInfo
    case tools.markservice:
      return areaServiceInfo
    case tools.markinfrastructure:
      return areaInfrastructureInfo
    case tools.markblocked:
      return areaBlockedInfo
    case tools.axesedit:
      return axesDrawInfo
    case tools.axesautogenerate:
      return axesAutoInfo
    case tools.axesdelete:
      return axesDeleteInfo

    default:
      return spacesHelpInfos[0]
  }
}

