import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router'

import { getCurrentProjectId } from '../common/utils'
import { fetchProjectData } from '../floorplan/actions/actions'

import GeneralSettings from './components/GeneralSettings'
import ProjectInfo from './components/ProjectInfo'
import Spacing from './components/Spacing'
import ApplicationProfiles from './components/ApplicationProfiles'
import LayoutStyle from './components/LayoutStyle'
import PatternStyles from './components/PatternStyles'

import Inspector from '../common/components/Inspector'
import InspectorContainer from '../common/components/InspectorContainer'
import ListMenu from '../common/components/ListMenu'
import ListMenuNavItem from '../common/components/ListMenuNavItem'
import { PlanAppContainer } from '../common/components/layout/PlanAppContainer'

import './SettingsApp.scss'

function NavItem (props) {
  return <ListMenuNavItem {...props} replace activeClassName={'settings-menu-nav-item-active'}/>
}

class SettingsApp extends Component {

  componentDidMount () {
    if (!this.props.dataLoaded) {
      this.props.fetchProjectData(this.props.match.params.projectId)
    }
  }

  render () {
    return (
      <PlanAppContainer className={'settings-content'}>

        <div className="sidebar">
          <Inspector>
            <InspectorContainer headerTitle={'Settings'} className={'settings-inspector'}>
              <ListMenu>
                <NavItem title={'General'} to={location => ({ ...location, pathname: `${this.props.match.url}/general` })}/>
                <NavItem title={'Project Information'} to={location => ({ ...location, pathname: `${this.props.match.url}/info` })}/>
                <NavItem title={'Clearances & Spacing'} to={location => ({ ...location, pathname: `${this.props.match.url}/spacing` })}/>
                <NavItem title={'Application Styles'} to={location => ({ ...location, pathname: `${this.props.match.url}/applicationstyle` })} visible={this.props.advancedSettings}
                         disabled={this.props.useLens} tooltip={this.props.useLens ? 'Deactivate LENS in general settings to change styles' : ''}/>
                <NavItem title={'Layout Styles'} to={location => ({ ...location, pathname: `${this.props.match.url}/layoutstyle` })} visible={this.props.advancedSettings}
                         disabled={this.props.useLens} tooltip={this.props.useLens ? 'Deactivate LENS in general settings to change styles' : ''}/>
                <NavItem title={'Pattern Styles'} to={location => ({ ...location, pathname: `${this.props.match.url}/patternstyle` })} visible={this.props.advancedSettings}
                         disabled={this.props.useLens} tooltip={this.props.useLens ? 'Deactivate LENS in general settings to change styles' : ''}/>
              </ListMenu>
            </InspectorContainer>
          </Inspector>
        </div>

        <div className="content">
          <Switch>
            <Route path={`${this.props.match.path}/spacing`} component={Spacing}/>
            <Route path={`${this.props.match.path}/patternstyle`} component={PatternStyles}/>
            <Route path={`${this.props.match.path}/applicationstyle`} component={ApplicationProfiles}/>
            <Route path={`${this.props.match.path}/layoutstyle`} component={LayoutStyle}/>
            <Route path={`${this.props.match.path}/info`} component={ProjectInfo}/>
            <Route path={`${this.props.match.path}/general`} component={GeneralSettings}/>
            <Redirect to={{ ...this.props.location, pathname: `${this.props.match.url}/general` }}/>
          </Switch>
        </div>

      </PlanAppContainer>
    )
  }
}

let mapStateToProps = (state) => {
  return {
    dataLoaded: state.dataLoaded.dataLoaded,

    useLens: state.settings.appSettings.useLens,
    advancedSettings: state.settings.appSettings.advancedSettings,
    settings: state.settings.appSettings,
    projectId: getCurrentProjectId(state),
  }
}

let mapDispatchToProps = {
  fetchProjectData: fetchProjectData,
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsApp)