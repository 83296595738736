import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import ModalConsumer from '../modal/ModalConsumer'
import DropdownMenu from '../DropdownMenu'

import {
  deleteFloorAndFetchBuildings,
  duplicateFloorAndFetchBuildings,
  fetchDownloadFloor,
  fetchGeometriesFileFloor,
  loadDemoFloor,
  moveFloorDownAndFetchBuildings,
  moveFloorUpAndFetchBuildings,
  renameFloorAndFetch,
  uploadGeometriesFloor,
} from '../../../floorplanner/actions/actions'

import { ListMenuItem } from '../ListMenuItem'
import ListMenu from '../ListMenu'

import { getFloorsOfSelectedBuilding } from '../../../floorplan/reducers/buildings'
import { viewNames } from '../../globalConstants'

import UploadDialog from '../../../header/components/UploadDialog'
import DeletionDialog from '../DeletionDialog'
import { autoGenerateAssigmentInFloor, deleteOrganisationalUnitAssignmentInFloor } from '../../../floorplan/actions/actions'
import { iconTypes } from '../icons/IconFactory'
import { withTranslation } from 'react-i18next'
import RenameDialog from '../../../buildings/components/dialogs/RenameDialog'

class FloorMenu extends Component {

  showRenameDialog (showModal) {
    const floor = this.props.floors.find(floor => floor.id === this.props.floorId)

    showModal(RenameDialog, {
      name: floor.name,
      type: 'floor',
      rename: (floorName) => this.handleRenameFloor(floorName),
    })
  }

  showUploadGeometriesDialog (showModal) {
    const { t } = this.props
    showModal(UploadDialog, {
      title: t('upload_geometries'),
      upload: (file) => this.handleUploadGeometriesFloor(file),
    })
  }

  showDeleteDialog (showModal) {
    showModal(DeletionDialog, {
      onConfirm: () => this.handleDeleteFloor(),
    })
  }

  render () {
    const { t } = this.props

    const isFloorsView = this.props.location.pathname.endsWith(viewNames.FLOORS)
    const isDrawingView = this.props.location.pathname.endsWith(viewNames.EDIT)
    const development = false

    return (
      <DropdownMenu onClose={() => this.closeMenu()}>

        <ModalConsumer>
          {({ showModal }) => (
            <ListMenu>
              <ListMenuItem onClick={() => this.showRenameDialog(showModal)} secondIcon={iconTypes.rename}>
                {t('rename')}
              </ListMenuItem>
              {
                this.props.advancedSettings ?
                  <React.Fragment>
                    <ListMenuItem onClick={() => this.handleDuplicateFloor()} secondIcon={iconTypes.duplicate}>
                      {t('duplicate')}
                    </ListMenuItem>
                    <ListMenuItem onClick={() => this.handleMoveUpFloor()} secondIcon={iconTypes.triangleUp}>
                      {t('move_up')}
                    </ListMenuItem>
                    <ListMenuItem onClick={() => this.handleMoveDownFloor()} secondIcon={iconTypes.triangleDown}>
                      {t('move_down')}
                    </ListMenuItem>
                    {
                      isDrawingView && development ?
                        <React.Fragment>
                          <ListMenuItem onClick={() => this.showUploadGeometriesDialog(showModal)} secondIcon={iconTypes.import}>
                            {t('upload_geometries')}
                          </ListMenuItem>
                          <ListMenuItem onClick={() => this.handleDownloadGeometriesFloor()} secondIcon={iconTypes.folder}>
                            {t('download_geometries')}
                          </ListMenuItem>
                          <ListMenuItem onClick={() => this.handleDownloadFloor()} secondIcon={iconTypes.folder}>
                            {t('download_floor')}
                          </ListMenuItem>
                          <ListMenuItem onClick={() => this.handleLoadDemoFloor()} secondIcon={iconTypes.import}>
                            {t('load_demo_floor')}
                          </ListMenuItem>
                        </React.Fragment> :
                        null
                    }
                  </React.Fragment>
                  : null
              }
              {
                isFloorsView ?
                  <React.Fragment>
                    <ListMenuItem onClick={() => this.handleCleanUp()} secondIcon={iconTypes.cleaning}>
                      {t('clean_up')}
                    </ListMenuItem>
                    <ListMenuItem onClick={() => this.handleAssistant()} secondIcon={iconTypes.assistant}>
                      {t('assistant')}
                    </ListMenuItem>
                  </React.Fragment>
                  : null
              }
              {!isDrawingView ?
                <ListMenuItem onClick={() => this.handleEditFloor()} secondIcon={iconTypes.editOutline}>
                  {t('edit_outlines')}
                </ListMenuItem>
                : null
              }
              <ListMenuItem disabled={this.props.floors.length === 1} onClick={() => this.showDeleteDialog(showModal)} secondIcon={iconTypes.delete}>
                {t('delete')}
              </ListMenuItem>
            </ListMenu>
          )}
        </ModalConsumer>
      </DropdownMenu>
    )
  }

  closeMenu () {
    this.props.onClose()
  }

  handleOpenModalClick (showModal, component, data) {
    this.closeMenu()
    showModal(component, data)
  }

  handleRenameFloor (floorName) {
    this.props.renameFloor(this.props.currentBuildingId, this.props.floorId, floorName)
  }

  handleDuplicateFloor () {
    this.closeMenu()
    this.props.duplicateFloor(this.props.currentBuildingId, this.props.floorId)
  }

  handleMoveUpFloor () {
    this.closeMenu()
    this.props.moveUpFloor(this.props.currentBuildingId, this.props.floorId)
  }

  handleMoveDownFloor () {
    this.closeMenu()
    this.props.moveDownFloor(this.props.currentBuildingId, this.props.floorId)
  }

  handleUploadGeometriesFloor (file) {
    this.closeMenu()
    this.props.uploadGeometriesFloor(this.props.currentBuildingId, this.props.floorId, file)
  }

  handleDownloadGeometriesFloor () {
    this.closeMenu()
    this.props.downloadGeometriesFloor(this.props.currentBuildingId, this.props.floorId)
  }

  handleDownloadFloor () {
    this.closeMenu()
    this.props.downloadFloor(this.props.floorId)
  }

  handleLoadDemoFloor () {
    this.closeMenu()
    this.props.loadDemoFloor()
  }

  handleCleanUp () {
    this.closeMenu()
    this.props.cleanUpFloor(this.props.currentBuildingId, this.props.floorId)
  }

  handleAssistant () {
    this.closeMenu()
    this.props.autoGenerateAssigmentFloor(this.props.currentBuildingId, this.props.floorId)
  }

  handleEditFloor () {
    this.props.push('./' + viewNames.EDIT)
  }

  handleDeleteFloor () {
    this.props.deleteFloor(this.props.currentBuildingId, this.props.floorId)
  }

}

let mapStateToProps = (state) => {
  return {
    floors: getFloorsOfSelectedBuilding(state),

    currentBuildingId: state.appState.selectedBuildingId,

    advancedSettings: state.settings.appSettings.advancedSettings,

    location: state.router.location,
  }
}

let mapDispatchToProps = {
  renameFloor: renameFloorAndFetch,
  duplicateFloor: duplicateFloorAndFetchBuildings,
  moveUpFloor: moveFloorUpAndFetchBuildings,
  moveDownFloor: moveFloorDownAndFetchBuildings,
  cleanUpFloor: deleteOrganisationalUnitAssignmentInFloor,
  autoGenerateAssigmentFloor: autoGenerateAssigmentInFloor,
  deleteFloor: deleteFloorAndFetchBuildings,

  uploadGeometriesFloor: uploadGeometriesFloor,
  downloadGeometriesFloor: fetchGeometriesFileFloor,
  downloadFloor: fetchDownloadFloor,
  loadDemoFloor: loadDemoFloor,

  push: push,
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(FloorMenu))


