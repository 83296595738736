import React, { Component } from 'react'
import { connect } from 'react-redux'

import { createNewProject, saveUserProject, uploadProjectFile } from '../../common/redux/projects/projects-actions'
import { fetchFloorIFCFile, fetchFloorPlanFile, fetchFloorPlanPdf, fetchPConExportFile, fetchProjectFile } from '../../floorplan/actions/actions'
import { getSelectedBuildingId, getSelectedFloorId } from '../../common/redux/appstate/appstate-reducer'

import { settings as config } from '../../common/config.service'

import ModalConsumer from '../../common/components/modal/ModalConsumer'
import { dialogNames } from '../../floorplan/constants'

import ListMenu from '../../common/components/ListMenu'
import ListSubMenuItem from './ListSubMenuItem'
import { ListMenuItem } from '../../common/components/ListMenuItem'

import UploadDialog from './UploadDialog'
import SummaryFileDownloadDialog from './dialogs/SummaryFileDownloadDialog'
import XLSXDownloadDialog from './dialogs/XLSXDownloadDialog'
import LoadProjectDialog from './dialogs/LoadProjectDialog'
import DeleteProjectDialog from './dialogs/DeleteProjectDialog'

import DropdownMenu from '../../common/components/DropdownMenu'

import './ProjectsMenu.scss'

class ProjectsMenu extends Component {

  constructor (props, context) {
    super(props, context)
    this.state = {
      pdfExportDisabled: config.pdfExportDisabled,
      hoveredMenuItem: null,
    }
  }

  render () {
    const subHeader = this.props.project && this.props.project.companyName ? this.props.project.companyName : null

    return (
      <DropdownMenu
        onClose={() => this.closeMenu()}
        subHeader={subHeader}
        className={'projects-menu' + (this.props.advancedSettings ? ' dark-mode' : '')}>

        <ModalConsumer>
          {({ showModal }) => (
            <ListMenu className={this.props.advancedSettings ? 'dark-mode' : ''}>
              <ListMenuItem onClick={() => this.handleCreateNewClick()} onMouseEnter={(menuItem) => this.setHoveredMenuItem(menuItem)}>Create New</ListMenuItem>
              <ListMenuItem onClick={() => this.handleOpenModalClick(showModal, LoadProjectDialog)} onMouseEnter={(menuItem) => this.setHoveredMenuItem(menuItem)}>
                Open Recent...
              </ListMenuItem>
              <ListMenuItem disabled={!this.props.project}
                            onClick={() => this.handleOpenModalClick(showModal, DeleteProjectDialog)}
                            onMouseEnter={(menuItem) => this.setHoveredMenuItem(menuItem)}>
                Delete Project
              </ListMenuItem>
              <ListMenuItem disabled={!this.props.project || !this.props.buildingId || !this.props.floorId}
                            onClick={() => this.handleOpenModalClick(showModal, SummaryFileDownloadDialog)}
                            onMouseEnter={(menuItem) => this.setHoveredMenuItem(menuItem)}>
                Save Project as PDF
              </ListMenuItem>
              <ListMenuItem disabled={!this.props.project || !this.props.buildingId || !this.props.floorId}
                            onClick={() => this.handleFloorPlanPdfDownload()}
                            onMouseEnter={(menuItem) => this.setHoveredMenuItem(menuItem)}>
                Save Floorplan as PDF
              </ListMenuItem>
              <ListSubMenuItem className={this.props.advancedSettings ? 'dark-mode' : ''} title={'Export'}
                               onMouseEnter={(menuItem) => this.setHoveredMenuItem(menuItem)}
                               selectedMenuItem={this.state.hoveredMenuItem}>
                <ListMenu className={'submenu-export' + (this.props.advancedSettings ? ' dark-mode' : '')}>
                  <ListMenuItem disabled={!this.props.project}
                                onClick={() => this.handleProjectFileDownload(this.props.project.id)}>
                    Project File
                  </ListMenuItem>
                  <ListMenuItem disabled={!this.props.project || !this.props.buildingId || !this.props.floorId}
                                onClick={() => this.handleFloorPlanFileDownload(this.props.buildingId, this.props.floorId)}>
                    DXF-File
                  </ListMenuItem>
                  <ListMenuItem disabled={!this.props.project || !this.props.buildingId || !this.props.floorId}
                                onClick={() => this.handleFloorPlanIFCDownload(this.props.buildingId, this.props.floorId)}>
                    IFC-File
                  </ListMenuItem>
                  <ListMenuItem disabled={!this.props.project || !this.props.buildingId || !this.props.floorId}
                                onClick={() => this.handleOpenModalClick(showModal, XLSXDownloadDialog, { dialogType: dialogNames.XSLX_FURNITURE_FILE_DOWNLOAD })}>
                    Furniture XLS-File
                  </ListMenuItem>
                  <ListMenuItem disabled={!this.props.project || !this.props.buildingId || !this.props.floorId}
                                onClick={() => this.handleOpenModalClick(showModal, XLSXDownloadDialog, { dialogType: dialogNames.XSLX_DEPARTMENT_FILE_DOWNLOAD })}>
                    Departments XLS-File
                  </ListMenuItem>
                  <ListMenuItem disabled={!this.props.project || !this.props.buildingId || !this.props.floorId}
                                onClick={() => this.handlePConDownload(this.props.buildingId, this.props.floorId)}>
                    pCon Transfer-File
                  </ListMenuItem>
                </ListMenu>
              </ListSubMenuItem>
              <ListMenuItem onClick={() => this.handleOpenModalClick(showModal, UploadDialog,
                {
                  title: 'Upload project file',
                  upload: this.props.uploadCOPFile,
                })}
                            onMouseEnter={(menuItem) => this.setHoveredMenuItem(menuItem)}>
                Import Project-File...
              </ListMenuItem>


            </ListMenu>
          )}
        </ModalConsumer>
      </DropdownMenu>
    )
  }

  setHoveredMenuItem (menuItem) {
    if (menuItem !== this.state.hoveredMenuItem) {
      this.setState({
        hoveredMenuItem: menuItem,
      })
    }
  }

  handlePConDownload (buildingId, floorId) {
    this.closeMenu()
    this.props.fetchPConExportFile(buildingId, floorId)
  }

  handleProjectFileDownload (projectId) {
    this.closeMenu()
    this.props.fetchProjectFile(projectId)
  }

  handleFloorPlanFileDownload (buildingId, floorId) {
    this.closeMenu()
    this.props.fetchFloorPlanFile(buildingId, floorId)
  }

  handleFloorPlanIFCDownload (buildingId, floorId) {
    this.closeMenu()
    this.props.fetchFloorIFCFile(buildingId, floorId)
  }

  handleFloorPlanPdfDownload () {
    this.closeMenu()
    this.props.fetchFloorPlanPdf()
  }

  handleCreateNewClick () {
    this.closeMenu()
    this.props.fetchNewProject(this.props.advancedSettings)
  }

  handleSaveUserProject () {
    this.closeMenu()
    this.props.saveUserProject(this.props.project.id, this.props.project.projectName)
  }

  closeMenu () {
    this.props.onClose()
  }

  handleOpenModalClick (showModal, component, data) {
    this.closeMenu()
    showModal(component, data)
  }
}

const mapStateToProps = (state) => {
  return {
    project: state.projects.project,
    buildingId: getSelectedBuildingId(state),
    floorId: getSelectedFloorId(state),
    advancedSettings: state.settings.appSettings.advancedSettings,
  }
}

const mapDispatchToProps = {
  fetchNewProject: createNewProject,
  fetchProjectFile: fetchProjectFile,
  fetchFloorPlanFile: fetchFloorPlanFile,
  fetchFloorIFCFile: fetchFloorIFCFile,
  fetchPConExportFile: fetchPConExportFile,
  saveUserProject: saveUserProject,
  fetchFloorPlanPdf: fetchFloorPlanPdf,
  uploadCOPFile: uploadProjectFile,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsMenu)


