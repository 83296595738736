import React, { Component } from 'react'
import { connect } from 'react-redux'
import { updateFurnitureDragId } from '../../common/redux/furnituredrag/furnituredag-actions'
import FurnitureMenu from '../../common/components/builder/FurnitureMenu.js'

class PatternFurnitureMenu extends Component {

  render () {
    return (
      <FurnitureMenu furnitureLibrary={this.props.furnitureLibrary} updateFurnitureMenuItemDragId={(id) => this.props.updateFurnitureDragId(id)}/>
    )
  }
}

let mapDispatchToProps = {
  updateFurnitureDragId: updateFurnitureDragId,
}

export default connect(null, mapDispatchToProps)(PatternFurnitureMenu)
