import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, Form, Formik } from 'formik'

import { updateProject } from '../../common/redux/projects/projects-actions'

import './ProjectnameInput.scss'
import Text from '../../common/components/Text'
import ModalConsumer from '../../common/components/modal/ModalConsumer'
import FurtherInfoDialog from './FurtherInfoDialog'
import Button from '../../common/components/Button'
import { push } from 'connected-react-router'
import { viewNamesOnBoarding } from '../../common/globalConstants'
import SelectButton from '../../common/components/SelectButton'
import { updateFrontendLocation } from '../../common/redux/frontendLocation/frontend-location-action'

class ProjectNameInput extends Component {

  render () {
    console.log("renderProjectNameInput")
    console.log(this.props)

    let industryOptions = [{ label: 'Default', value: '' }]

    industryOptions = industryOptions.concat(this.props.industryAttributes.map(attribute => ({
      label: attribute.name,
      value: attribute.name,
    })))

    let countryOptions = [{ label: 'Default', value: '' }]

    countryOptions = countryOptions.concat(this.props.countries.map(country => ({
      label: country.name,
      value: country.code,
    })))

    return (
      <React.Fragment>
        <div className={'projectname-input-content'}>
          <div className={'projectname-input-container' + (this.props.advancedSettings ? ' advanced-settings' : '')}>
            <Formik initialValues={this.props.project} onSubmit={() => {}}>
              {({ values }) => {
                return (
                  <Form className={'projectname-form'}>
                    <Text outerClassName={'title'}>Projectname</Text>
                    <Field className={'projectname-input'}
                           placeholder={'Name your project'}
                           type={'input'}
                           autoFocus
                           autoComplete={'off'}
                           name={'name'}/>
                    <div className={'project-data-selection-area'}>
                      <div className={'project-data-selection'}>
                        <Text outerClassName={'title'}>Country</Text>
                        <Field name="country"
                               options={countryOptions}
                               component={SelectButton}/>
                      </div>

                      <div className={'project-data-selection'}>
                        <Text outerClassName={'title'}>Industry</Text>
                        <Field name="industry"
                               options={industryOptions}
                               component={SelectButton}/>
                      </div>
                    </div>

                    <ModalConsumer>
                      {({ showModal }) => (
                        <div className={'project-further-information'} onClick={() => showModal(FurtherInfoDialog)}>
                          <Text>Add further information</Text>
                        </div>
                      )}
                    </ModalConsumer>

                    <Field component={Button}
                           id = {this.props.advancedSettings ? 'CoDesignerPro' : 'Testfitting'}
                           type={'submit'}
                           className={'projectname-submit-button'}
                           variant={'orange-filled'}
                           disabled={this.isSubmitDisabled(values)}
                           onClick={() => this.handleSubmit(values)}>Submit</Field>
                  </Form>
                )
              }
              }
            </Formik>
          </div>
        </div>
      </React.Fragment>
    )
  }

  isSubmitDisabled (values) {
    console.log("is submit disabled")
    return !this.props.project ||
      values.name === '' ||
      values.country === '' ||
      values.industry === ''
  }

  handleSubmit (values) {
    console.log("NAME: "+values.name)
    if (values.name !== this.props.project.name) {
      this.props.updateProject(values.id, values)
    }

    if (values.name !== '') {
      this.props.push('./' + viewNamesOnBoarding.STEP_1)
      this.props.updateGlobalLocation('ONBOARDING', 'STEP_1')
    }
  }

}

let mapStateToProps = (state) => {
  return {
    project: state.projects.project,
    advancedSettings: state.settings.appSettings.advancedSettings,

    industryAttributes: state.industryAttributes.industryAttributes,
    countries: state.countries.countries,
  }
}

let mapDispatchToProps = {
  updateProject: updateProject,

  updateGlobalLocation: updateFrontendLocation,

  push: push,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectNameInput)