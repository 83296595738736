import React, { Component } from 'react'
import { connect } from 'react-redux'

import { fetchProjectData } from '../floorplan/actions/actions'

import Sidebar from '../common/components/buildingsFloorsInspector/Sidebar'
import FloorsContent from './components/FloorsContent'
import { PlanAppContainer } from '../common/components/layout/PlanAppContainer'

class FloorsApp extends Component {

  constructor (props) {
    super(props)

    this.state = {
      shortVersion: true,
    }
  }

  componentDidMount () {
    if (!this.props.dataLoaded) {
      this.props.fetchProjectData(this.props.match.params.projectId)
    }

    document.addEventListener('keydown', (event) => {
      if (event.code === 'AltLeft') {
        this.setState({ shortVersion: false })
        event.preventDefault()
      }
    })
    document.addEventListener('keyup', (event) => {
      if (event.code === 'AltLeft') {
        this.setState({ shortVersion: true })
        event.preventDefault()
      }
    })
  }

  render () {
    return (
      <PlanAppContainer className={'floors'}>
        <Sidebar title={'Floors'} shortVersion={this.state.shortVersion}/>
        <FloorsContent shortVersion={this.state.shortVersion}/>
      </PlanAppContainer>
    )
  }
}

let mapStateToProps = (state) => {
  return {
    buildingId: state.appState.selectedBuildingId,
    floorId: state.appState.selectedFloorId,

    location: state.router.location,

    dataLoaded: state.dataLoaded.dataLoaded,
  }
}

let mapDispatchToProps = {
  fetchProjectData: fetchProjectData,
}

export default connect(mapStateToProps, mapDispatchToProps)(FloorsApp)