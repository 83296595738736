import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import BuilderOverviewGroup from '../../../common/components/builder/BuilderOverviewGroup'
import BuilderOverviewNavBar from '../../../common/components/builder/BuilderOverviewNavBar'
import ModalConsumer from '../../../common/components/modal/ModalConsumer'
import Button from '../../../common/components/Button'
import ButtonToolbar from '../../../common/components/ButtonToolbar'
import UploadDialog from '../../../header/components/UploadDialog'
import { iconTypes } from '../../../common/components/icons/IconFactory'

import { deletePatterns, exportPatterns, importPatterns } from '../../../common/redux/patterns/patterns-actions'
import { deletePattern, duplicatePattern, renamePattern, updatePattern } from '../../actions/actions'
import { getUndoInformation } from '../../../common/redux/undo/undo-actions'

import './PatternBuilderOverview.scss'

class PatternBuilderOverview extends Component {

  render () {

    const { projectId } = this.props.match.params

    return (
      <div className={'pattern-builder-overview'}>

        <BuilderOverviewNavBar icon={iconTypes.patternGreen} text={`Patterns (${this.props.patterns.length})`}>

          <div className={'button-container'}>
            <ModalConsumer>
              {({ showModal }) => (
                <ButtonToolbar>
                  <Button className={'button'} variant={'text'}  icon={iconTypes.addPattern} onClick={() => showModal(UploadDialog, {
                    title: 'Import patterns file',
                    upload: this.props.importPatterns,
                    replace: false,
                    fileEndings: '.coa',
                  })}>
                    Import...
                  </Button>
                  <Button className={'button'} variant={'text'} icon={iconTypes.replacePattern} onClick={() => showModal(UploadDialog, {
                    title: 'Replace patterns file',
                    upload: this.props.importPatterns,
                    replace: true,
                    fileEndings: '.coa',
                  })}>
                    Replace...
                  </Button>
                  <Button className={'button'} variant={'text'} icon={iconTypes.exportFurnitureSets} onClick={() => this.props.exportPatterns()}>
                    Export
                  </Button>
                  <Button className={'button'} variant={'text'} icon={iconTypes.delete} onClick={() => this.props.deletePatterns()}>
                    Delete All
                  </Button>
                </ButtonToolbar>
              )}
            </ModalConsumer>
          </div>

        </BuilderOverviewNavBar>

        <BuilderOverviewGroup projectId={projectId}
                              overviewContent={this.props.patterns}
                              onElementClicked={(patternId) => this.setCurrentPattern(patternId)}
                              onCreateClicked={(pattern) => this.createNewPattern(pattern)}
                              onDuplicateClicked={(pattern) => this.handleDuplicateClicked(pattern)}
                              onDeleteClicked={(projectId, patternId) => this.props.deletePattern(projectId, patternId)}
                              renameContent={(pattern) => this.handleRenamePattern(pattern)}/>
      </div>
    )
  }

  createNewPattern () {
    this.props.push('patterns/new' + this.props.location.search)
  }

  setCurrentPattern (patternId) {
    this.props.push('patterns/' + patternId + this.props.location.search)
  }

  handleRenamePattern (pattern) {
    this.props.renamePattern(pattern.id, pattern.displayName)
    this.props.updatePattern(this.props.match.params.projectId, pattern)
  }

  handleDuplicateClicked (pattern) {
    this.props.duplicatePattern(this.props.match.params.projectId, pattern)
  }

}

let mapStateToProps = (state) => {
  return {
    patterns: state.pattern.patterns,
    location: state.router.location,
  }
}

let mapDispatchToProps = {
  deletePattern: deletePattern,
  renamePattern: renamePattern,
  duplicatePattern: duplicatePattern,
  updatePattern: updatePattern,
  importPatterns: importPatterns,
  exportPatterns: exportPatterns,
  deletePatterns: deletePatterns,
  getUndoInformation: getUndoInformation,
  push: push,
}

export default connect(mapStateToProps, mapDispatchToProps)(PatternBuilderOverview)