import React from 'react'

import CorporateData from './components/CorporateData'
import Text from '../common/components/Text'

import './PeopleContent.scss'

export function PeopleContent () {
  return (
    <React.Fragment>
      <Text outerClassName={'people-header'} component={'h3'} multiline>People</Text>
      <CorporateData/>
    </React.Fragment>
  )
}