import React, { Component } from 'react'

import Text from '../Text'
import FlyOut from '../FlyOut'

import FurnitureMenuItemDescription from './FurnitureMenuItemDescription'
import IconMenu from '../IconMenu'

import { createSVG } from '../../three/svgActions'

import ModalConsumer from '../modal/ModalConsumer'
import IconButton from '../IconButton'
import FurnitureUploadDialog from './dialogs/XlsxDXFUploadDialog'

import './FurnitureMenu.scss'
import { iconTypes } from '../icons/IconFactory'

function ImportIcon ({ uploadFurniture }) {
  return (
    <ModalConsumer>
      {({ showModal }) => (
        <div className={'import-button-container'}>
          <IconButton className={'import-button'} icon={iconTypes.import} onClick={() => showModal(FurnitureUploadDialog, { uploadXlsxDxf: uploadFurniture })}/>
        </div>
      )}
    </ModalConsumer>
  )
}

class FurnitureMenu extends Component {

  constructor (props) {
    super(props)

    this.state = {
      selectedFurnitureList: [],
      selectedCategory: '',
      flyOutOpen: false,
      invisibleMouse: new Image(0, 0),
      opacity: 1,
      furnitureMenuPointerEvents: 'auto',
    }
  }

  renderFurnitureList () {

    return this.state.selectedFurnitureList.map((item) => {

      let itemDescription = item.ofmlName && item.ofmlName.length > 0 ? item.ofmlName : item.name

      const sizeText = item.size.width + ' x ' + item.size.height + ' m'

       return (
         <li className={'fly-out-list-item'}
             key={item.name + Math.floor(Math.random() * 9999999)}>
           <div className={'list-item-svg'}
                onMouseDown={(e) => this.handleDragStart(e, item)}
                onDragStart={() => {return false}}>
             {createSVG(72, 72, item.polys, item.lines, 'svg')}
           </div>
           <div className={'list-item-text-area'}>
             <FurnitureMenuItemDescription description={itemDescription}/>
             <Text outerClassName={'list-item-size-container'} className={'list-item-size'}>{sizeText}</Text>
           </div>
         </li>)
    })
  }

  handleDragStart (e, item) {
    document.body.style.cursor = 'none'

    if (this.props.onDragStart)
      this.props.onDragStart(e)

    this.props.updateFurnitureMenuItemDragId(item.id)

    this.setState(
      {
        opacity: .1,
        furnitureMenuPointerEvents: 'none',

      },
    )

    document.addEventListener('mouseup', (event) => this.handleDragEnd(event))
  }

  handleDragEnd (e, onDragEnd) {
    document.body.style.cursor = 'default'

    if (this.props.onDragEnd)
      this.props.onDragEnd(e)

    this.setState(
      {
        opacity: 1,
        furnitureMenuPointerEvents: 'auto',
      },
    )

    document.removeEventListener('mouseup', this.handleDragEnd)
  }

  render () {

    const furnitureListItems = this.renderFurnitureList()

    return (
      <div className={'furniture-menu'} style={{ opacity: this.state.opacity, pointerEvents: this.state.furnitureMenuPointerEvents }}>
        <IconMenu onIconClicked={(category) => this.selectCategory(category)}
                  selectedCategory={this.state.selectedCategory}/>
        <FlyOut className={'builder-fly-out'}
                onCloseClicked={() => this.onFlyOutCloseClicked()}
                open={this.state.flyOutOpen}
                headerTitle={this.state.selectedCategory}>
          <div className={'fly-out-list-container'}>
            <ul>
              {furnitureListItems}
            </ul>
          </div>
        </FlyOut>
        {<ImportIcon uploadFurniture={this.props.uploadFurniture}/>}
      </div>
    )
  }

  selectCategory (category) {
    let selected = this.filterFurnituresWithCategory(category)

    this.setState(
      {
        flyOutOpen: true,
        selectedCategory: category,
        selectedFurnitureList: selected,
      },
    )
  }

  onFlyOutCloseClicked () {
    this.setState({
      flyOutOpen: false,
      selectedCategory: '',
    })
  }

  filterFurnituresWithCategory (category) {
    return this.props.furnitureLibrary.filter((item) => item.category === category)
  }
}

export default FurnitureMenu
