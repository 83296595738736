import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import FlyOut from '../../../common/components/FlyOut'
import CorporateFlyOutMenu from './CorporateFlyOutMenu'
import { closeFlyOut, openFlyOut } from '../../actions/ui-actions'

import './FloorPlanFlyOut.scss'
import { updateOrganisationalUnitPlaced } from '../../../common/redux/organisationunits/organisational-units-actions'

class FloorPlanFlyOut extends Component {

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.selectedOrganisationalUnitId
      && prevProps.selectedOrganisationalUnitId !== this.props.selectedOrganisationalUnitId
      && this.props.selectedRowId !== this.props.selectedOrganisationalUnitId
      && this.props.selectedContextMenuId === null) {
      this.props.openFlyOut()
    }
    if (this.props.selectedOrganisationalUnitId === this.props.selectedContextMenuId ||
      (this.props.selectedContextMenuId === this.props.selectedApplicationId &&
        this.props.selectedContextMenuId !== null)) {
      this.props.closeFlyOut()
    }
  }

  getOrganisationalUnit (unitId) {
    return this.props.organisationalUnits.find(unit => unit.id === unitId)
  }

  getOrganisationalUnitName (unitId) {
    let unit = this.getOrganisationalUnit(unitId)
    if (unit) {
      return unit.name
    }
  }

  getOrganisationalUnitColor (unitId) {
    let unit = this.getOrganisationalUnit(unitId)
    if (unit) {
      return unit.color
    }
  }

  render () {
    return (
      <FlyOut className='floor-plan-fly-out' open={this.props.open}
              onCloseClicked={() => this.props.closeFlyOut()}
              headerStyle={'floor-plan-fly-out-header'}
              headerTitle={this.getOrganisationalUnitName(this.props.selectedOrganisationalUnitId)}
              color={this.getOrganisationalUnitColor(this.props.selectedOrganisationalUnitId)}
              onColorChange={(colorAsHex) => this.handleColorChange(colorAsHex, this.props.selectedOrganisationalUnitId)}>
        <CorporateFlyOutMenu/>
      </FlyOut>
    )
  }

  handleColorChange (color, unitId) {

    let unit = {
      color: {
        red: color.rgb.r / 255,
        green: color.rgb.g / 255,
        blue: color.rgb.b / 255,
        alpha: color.rgb.a,
      },
    }

    this.props.updateOrganisationalUnit(unitId, unit)
  }
}

let mapStateToProps = (state) => {
  return {
    open: state.floorPlanUiState.flyOut.open,
    selectedOrganisationalUnitId: state.appState.selectedOrganisationalUnitId,
    selectedContextMenuId: state.floorPlanState.selectedContextMenuId,
    selectedRowId: state.floorPlanState.selectedRowId,
    selectedApplicationId: state.floorPlanState.selectedApplicationId,
    organisationalUnits: state.organisationalUnits.units,
  }
}

let mapDispatchToProps = {
  openFlyOut: openFlyOut,
  closeFlyOut: closeFlyOut,
  updateOrganisationalUnit: updateOrganisationalUnitPlaced,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FloorPlanFlyOut))