import React, { Component } from 'react'
import { connect } from 'react-redux'

import { fetchProjectData } from './actions/actions'

import Sidebar from '../common/components/buildingsFloorsInspector/Sidebar'
import DashboardContainer from './components/dashboard/DashboardContainer'
import { PlanAppContainer } from '../common/components/layout/PlanAppContainer'
import { FloorplanContent } from './FloorplanContent'

class FloorPlanApp extends Component {

  constructor (props) {
    super(props)

    this.initState = {
      viewMode: 'team',
    }

    this.state = this.initState
  }

  componentDidMount () {
    if (!this.props.dataLoaded) {
      this.props.fetchProjectData(this.props.match.params.projectId)
    }
  }

  handleViewModeChange (mode) {
    this.setState({
      viewMode: mode,
    })
  }

  render () {

    return (
      <PlanAppContainer id={'floor-plan'} className={'floor-plan-app'}>
        <Sidebar title={'Floorplan'}/>
        <FloorplanContent viewMode={this.state.viewMode}/>
        <DashboardContainer viewMode={this.state.viewMode} onViewModeChange={(mode) => this.handleViewModeChange(mode)}/>
      </PlanAppContainer>
    )
  }
}

let mapStateToProps = (state) => {
  return {
    appState: state.appState,

    dataLoaded: state.dataLoaded.dataLoaded,
  }
}

let mapDispatchToProps = {
  fetchProjectData: fetchProjectData,
}

export default connect(mapStateToProps, mapDispatchToProps)(FloorPlanApp)