import React from 'react'
import { Slide } from 'react-reveal'
import IconButton from './IconButton'
import { Scrollbars } from 'react-custom-scrollbars'

import './FlyOut.scss'
import { getHexColorFromRGB, getRgbaColor } from '../utils'
import ColorInput from './ColorInput'
import { iconTypes } from './icons/IconFactory'

function calculateStyle (open) {

  if (open) {
    return {
      zIndex: 1,
    }
  }

  return {
    zIndex: -1,
  }

}

function FlyOut ({ children, className, headerTitle, onCloseClicked, open, headerStyle, color, onColorChange }) {

  let styleClasses = className ? ' ' + className : ''
  let styleHeader = headerStyle ? ' ' + headerStyle : ''

  return (
    <Slide className='slide-fly-out' left when={open} duration={250}>
      <div className={'fly-out' + styleClasses} style={calculateStyle(open)}>
        <div className={'fly-out-header-color'} style={{ background: `rgba(${getRgbaColor(color, .8)})` }}/>
        <div className={'fly-out-header' + styleHeader}>
          {headerTitle && <h3>{headerTitle}</h3>}
          <div className={'fly-out-buttons'}>
            <IconButton className={'fly-out-close-button'} icon={iconTypes.close} onClick={() => onCloseClicked()}/>
            {color && <ColorInput leftSide color={getHexColorFromRGB(color)} onChange={(colorAsHex) => onColorChange(colorAsHex)}/>}
          </div>
        </div>
        <div className={'fly-out-content'}>
          <Scrollbars autoHide>
            {children}
          </Scrollbars>
        </div>
      </div>
    </Slide>
  )
}

export default FlyOut