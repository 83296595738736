import { Component } from 'react'
import { connect } from 'react-redux'
import { fetchProject } from './common/redux/projects/projects-actions'
import { getCurrentProjectId, getCurrentVariationId } from './common/utils'
import { fetchVariationsAndUpdateQuery } from './common/redux/variations/variations-actions'

class ProjectRouter extends Component {

  componentDidMount () {
    const { projectId, variationId } = this.props

    if (projectId) {
      this.props.fetchProject(projectId)
      this.props.fetchVariationsAndSetCurrent(projectId, variationId)
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { projectId, variationId } = this.props

    if (projectId) {
      if (this.projectParamHasChanged(prevProps)) {
        // If project param has changed then there was an upload. This means the project is already fetched,
        // routing was done and there is no variation query param
        this.props.fetchVariationsAndSetCurrent(projectId)
      } else if (this.variationParamHasChanged(prevProps)) {
        console.log('CHANGE')
        this.props.fetchVariationsAndSetCurrent(projectId, variationId)
      }
    }
  }

  render () {
    return null
  }

  variationParamHasChanged (prevProps) {
    return prevProps.variationId !== this.props.variationId
  }

  projectParamHasChanged (prevProps) {
    return this.props.projectId && prevProps.projectId !== this.props.projectId
  }
}

let mapStateToProps = (state) => {
  return {
    projectId: getCurrentProjectId(state),
    variationId: getCurrentVariationId(state),
  }
}

let mapDispatchToProps = {
  fetchProject: fetchProject,
  fetchVariationsAndSetCurrent: fetchVariationsAndUpdateQuery,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectRouter)