import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'

import Sidebar from '../../common/components/buildingsFloorsInspector/Sidebar'

import { fetchProjectData, setSelectedFloor } from '../../floorplan/actions/actions'
import { setDrawingHelpLegend } from '../../floorplanner/actions/drawingActions'

import { PlanAppContainer } from '../../common/components/layout/PlanAppContainer'
import { withTranslation } from 'react-i18next'

import './DrawingToolApp.scss'
import FloorPlanner from './DrawingTool'
import DrawingToolContent from './DrawingToolContent'

class DrawingToolApp extends Component {

  componentDidMount () {
    if (!this.props.dataLoaded) {
      this.props.fetchProjectData(this.props.match.params.projectId)
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.match.params.projectId !== this.props.match.params.projectId || prevProps.selectedFloorId !== this.props.selectedFloorId) {
      this.selectFloor()
    }
  }

  selectFloor () {
    const floorId = this.props.selectedFloorId
    const floor = this.props.buildings.flatMap(building => building.floors)
      .find(floor => floor.id === floorId)
    if (floor) {
      this.props.setSelectedFloor(floor.buildingId, floorId)
    }
  }

  fetchProject () {
    const projectId = this.props.match.params.projectId
    const floorId = this.props.selectedFloorId

    this.props.fetchProjectData(projectId, floorId)
  }

  getSelectedFloorName () {
    const building = this.props.buildings.find(building => building.id === this.props.selectedBuildingId)
    let name = ''
    if (building) {
      const floor = building.floors.find((floor) => floor.id === this.props.selectedFloorId)
      if (floor)
        name = floor.name
    }

    return name
  }

  render () {
    const {t} = this.props

    return (
      <PlanAppContainer id='test' className={'buildings-app'}>
        <Sidebar title={t('edit_outline')}/>
        <DrawingToolContent/>
      </PlanAppContainer>
    )
  }

}

let mapStateToProps = (state) => {
  return {
    buildings: state.buildings.buildings,
    selectedFloorId: state.appState.selectedFloorId,
    selectedBuildingId: state.appState.selectedBuildingId,
    dataLoaded: state.dataLoaded.dataLoaded,
  }
}

let mapDispatchToProps = {
  fetchProjectData: fetchProjectData,
  setSelectedFloor: setSelectedFloor,

  setDrawingHelpLegend: setDrawingHelpLegend,
}

export default compose(withTranslation(), withRouter)(connect(mapStateToProps, mapDispatchToProps)(DrawingToolApp))