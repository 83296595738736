import { variationsActionTypes } from './variations-actions'

let initialState = {
  variations: [],
  error: null,
}

function variationsReducer (state = initialState, action) {

  switch (action.type) {

    case variationsActionTypes.FETCH_VARIATIONS_FULFILLED:
      return {
        ...state,
        variations: action.payload.data,
      }

    case variationsActionTypes.CREATE_VARIATION_FULFILLED:
      return {
        ...state,
        variations: [...state.variations, action.payload.data],
      }

    case variationsActionTypes.UPDATE_VARIATION_FULFILLED:
      return {
        ...state,
        variations: [...state.variations, action.payload.data],
      }

    case variationsActionTypes.DELETE_VARIATION_FULFILLED:
      return {
        ...state,
      }

    default:
      return { ...state }
  }

}

export function getVariation (state, variationId) {

  if (!variationId) {
    return null
  }

  return state.variations.variations
    .find(variation => variation.id === variationId)
}

export function getFirstVariation (state) {
  return state.variations.variations.length ? state.variations.variations[0] : null
}

export default variationsReducer



