import {MeshBasicMaterial} from 'three'
import {getTexture, textureTypes} from './Textures'
import {materialTypes} from './MaterialTypes'

const materialColors = {
    default: '#ff00ff',
    defaultShadow: '#ffffff',
    defaultLine: '#000000',

    red: '#cc0000',
    green: '#1aae19',
    cyan: '#08eafc',
    blue: '#003fff',
    pink: '#ff00ff',
    black: '#000000',
    orange: '#ff7500',
    turquoise: '#a9ecea',
    grey: '#666666',
    white: '#ffffff',
    brightOrange: '#fdf4ee',

    selectionArea: '#b0b0b0',
}

function getRandomnOrangeColor() {

  let  b=Math.round(Math.random()*128.0)       //b
  let  g=(205+Math.round(Math.random()*50.0))*256   //g
  let  r=(205+Math.round(Math.random()*50.0))*65536       //r

  let c=r+g+b
  return c
}
function getRandomColor() {
  return Math.random() * 0xffffff
}

const materials = {
    background: new MeshBasicMaterial({map: getTexture(textureTypes.background)}),
    transparent: new MeshBasicMaterial({name: materialTypes.transparent, visible: false}),
    selectionArea: new MeshBasicMaterial({
        name: materialTypes.selectionArea,
        color: materialColors.selectionArea,
        transparent: true,
        opacity: .3
    }),
    pink: new MeshBasicMaterial({name: materialTypes.pink, color: materialColors.pink, transparent: true, opacity:1}),
    white: new MeshBasicMaterial({name: materialTypes.white, color: materialColors.white, transparent: true, opacity:1}),
    brightOrange: new MeshBasicMaterial({name: materialTypes.brightOrange, color: materialColors.brightOrange, opacity:1}),
    black: new MeshBasicMaterial({name: materialTypes.black, color: materialColors.black, transparent: true, opacity:1}),
    green: new MeshBasicMaterial({name: materialTypes.green, color: materialColors.green, transparent: true, opacity:1}),
    cyan: new MeshBasicMaterial({name: materialTypes.cyan, color: materialColors.cyan, transparent: true, opacity:1}),
    red: new MeshBasicMaterial({name: materialTypes.red, color: materialColors.red, transparent: true, opacity:1}),

    spaceGeometry: new MeshBasicMaterial({name: materialTypes.spaceGeometry, color: materialColors.grey, opacity: .2, transparent: true}),
    blue: new MeshBasicMaterial({name: materialTypes.blue, color: materialTypes.blue, transparent: true, opacity:1}),
    orange: new MeshBasicMaterial({name: materialTypes.orange, color: materialColors.orange, transparent: true, opacity:1}),
    blackWhitePattern: new MeshBasicMaterial({name: materialTypes.blackWhitePattern, map: getTexture(textureTypes.blackWhitePattern)} ),
    default: new MeshBasicMaterial({
        name: materialTypes.default,
        color: materialColors.default,
        visible: true,
        depthWrite: true
    })
}

export function getMaterial (materialType) {
    const material = materials[materialType]
    return material ? material :
      materialType === materialTypes.random ?
        new MeshBasicMaterial({name:materialType, color: getRandomnOrangeColor(), transparent: true, opacity:.4}) :
        new MeshBasicMaterial({name: 'undefined', visible: false})
}
