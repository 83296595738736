import { StampGeometry } from '../geometries/StampGeometry'
import { representationTypes } from '../config/RepresentationTypes'

let previewStamp = null

export class PreviewStamp extends StampGeometry {

  constructor (scene) {
    super(scene, representationTypes.pillar)

    this.name = 'previewStamp'
    previewStamp = this
    this.setVisible(false)
  }
}

export function setPreviewStamp (position) {
  if (!position) {
    previewStamp.setVisible(false)
    return
  }

  previewStamp.setVisible(true)
  previewStamp.updatePosition(position)
}

export function getPreviewStampConfig () {
  return previewStamp.properties
}

export function addSegmentToPreviewStamp (value) {
  previewStamp.addSegment(value)
}

export function rotatePreviewStamp (angle) {
  previewStamp.rotate(angle)
}

export function addRadiusToPreviewStamp(radius) {
  previewStamp.addRadius(radius)
}

