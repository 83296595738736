import React, { Component } from 'react'
import { Route, Switch } from 'react-router'
import PatternBuilder from './components/editor/PatternBuilder'
import PatternBuilderOverview from './components/overview/PatternBuilderOverview'
import { fetchProjectData } from '../floorplan/actions/actions'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { viewNames } from '../common/globalConstants'

class PatternBuilderApp extends Component {

  componentDidMount () {
    if (!this.props.dataLoaded) {
      this.props.fetchProjectData(this.props.match.params.projectId)
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.advancedSettings === undefined && this.props.advancedSettings === false) {
      this.props.push('./floorplan')
    }
  }

  render () {
    return (
      <div id={'pattern-builder'}>
        <Switch>
          <Route path={'/projects/:projectId/' + viewNames.PATTERNS + '/new'} component={PatternBuilder}/>
          <Route path={'/projects/:projectId/' + viewNames.PATTERNS + '/:patternId'} component={PatternBuilder}/>
          <Route path={'/projects/:projectId/' + viewNames.PATTERNS} component={PatternBuilderOverview}/>
        </Switch>
      </div>
    )
  }
}

let mapStateToProps = (state) => {
  return {
    advancedSettings: state.settings.appSettings.advancedSettings,

    dataLoaded: state.dataLoaded.dataLoaded,
  }
}

let mapDispatchToProps = {
  fetchProjectData: fetchProjectData,

  push: push,
}

export default connect(mapStateToProps, mapDispatchToProps)(PatternBuilderApp)