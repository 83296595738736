import {RepeatWrapping, TextureLoader} from 'three'

import backgroundImage from '../../../floorplanner/FloorPlanner/assets/jpg/Background.jpg'
import blackWhitePattern from '../assets/BlackWhitePattern.jpg'

const textureLoader = new TextureLoader()

export const textureTypes = {
    background: 'background',
  blackWhitePattern: 'blackWhitePattern'
}

export function getTexture (textureType) {
    const textureLoader = new TextureLoader()

    switch (textureType) {
        case textureTypes.background:
            return textureLoader.load(backgroundImage, function (texture) {
                texture.wrapS = texture.wrapT = RepeatWrapping
                texture.offset.set(0, 0)
                texture.repeat.set(75, 75)
            })
      case textureTypes.blackWhitePattern:
        return textureLoader.load(blackWhitePattern, function (texture) {
          texture.wrapS = texture.wrapT = RepeatWrapping
          texture.offset.set(0, 0)
          texture.repeat.set(1, 1)
        })
        default:
            return
    }
}

export function loadTextureFromBase64Image(base64Image) {
  return textureLoader.load(base64Image)
}
