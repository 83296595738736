import React from 'react'

import BlockingEditor from './BlockingEditor'
import DraggedListItemRenderContainer from './DraggedListItemRenderContainer'
import Blocking from '../../floorplan/components/inspector/blocking/Blocking'

import './FloorsContent.scss'

export default function FloorsContent ({ shortVersion, onboarding = false }) {
  return (
    <div className={'floors-container'}>
      <div className={'floors-content' + (onboarding ? ' onboarding' : '')}>
        <BlockingEditor header={onboarding ? 'Floors' : ''}/>
        <DraggedListItemRenderContainer/>
      </div>
      <Blocking shortVersion={shortVersion}/>
    </div>
  )
}
