import { FN3D, FN3DExtended } from '../three/classes'
import { floorPlanActionTypes } from '../../floorplan/constants'

const initialState = {

  furnitureLibrary: [],
}

function furniture (state = initialState, action) {

  switch (action.type) {

    case floorPlanActionTypes.FETCH_FURNITURE_LIBRARY_PENDING:
      return { ...state }

    case floorPlanActionTypes.FETCH_FURNITURE_LIBRARY_FULFILLED:
      return {
        ...state,
        furnitureLibrary: action.payload.data,
      }

    case floorPlanActionTypes.FETCH_FURNITURE_LIBRARY_REJECTED:
      return { ...state, error: action.payload }

    default:
      return { ...state }
  }
}

export function getFurniture3DObjects (state) {
  return state.furniture.furnitureLibrary
    .map(furniture => {
      let object3D = new FN3D()
      object3D.build(furniture)
      return object3D
    })
}



export function getFurniture3DObjectsXT (state) {
  return state.furniture.furnitureLibrary
    .map(furniture => {
      let object3D = new FN3DExtended()
      object3D.build(furniture)
      return object3D
    })
}

export default furniture
