import React, { Component } from 'react'
import { connect } from 'react-redux'
import ModalConsumer from '../modal/ModalConsumer'
import DropdownMenu from '../DropdownMenu'

import { addFloorAndFetchBuildings, deleteBuildingAndFetch, renameBuildingAndFetch } from '../../../floorplanner/actions/actions'

import { ListMenuItem } from '../ListMenuItem'
import ListMenu from '../ListMenu'

import DeletionDialog from '../DeletionDialog'
import { viewNames } from '../../globalConstants'

import { autoGenerateAssignmentsInBuilding, deleteOrganisationalUnitAssignmentsInBuilding } from '../../../floorplan/actions/actions'
import { iconTypes } from '../icons/IconFactory'
import { withTranslation } from 'react-i18next'
import RenameDialog from '../../../buildings/components/dialogs/RenameDialog'

class BuildingMenu extends Component {

  showRenameDialog (showModal) {
    const building = this.props.buildings.find(building => building.id === this.props.currentBuildingId)

    showModal(RenameDialog, {
      name: building.name,
      type: 'building',
      rename: (buildingName) => this.handleRenameBuilding(buildingName),
    })
  }

  showDeleteDialog (showModal) {
    showModal(DeletionDialog, {
      onConfirm: () => this.handleDeleteBuilding(),
    })
  }

  render () {
    const { t } = this.props
    const isFloorsView = this.props.location.pathname.endsWith(viewNames.FLOORS)

    return (
      <DropdownMenu
        onClose={() => this.props.onClose()}>

        <ModalConsumer>
          {({ showModal }) => (
            <ListMenu>
              <ListMenuItem onClick={() => this.showRenameDialog(showModal)} secondIcon={iconTypes.rename}>
                {t('rename')}
              </ListMenuItem>
              {
                isFloorsView ?
                  <React.Fragment>
                    <ListMenuItem onClick={() => this.handleCleanUpBuilding()} secondIcon={iconTypes.cleaning}>
                      {t('clean_up')}
                    </ListMenuItem>
                    <ListMenuItem onClick={() => this.handleAssistant()} secondIcon={iconTypes.assistant}>
                      {t('assistant')}
                    </ListMenuItem>
                  </React.Fragment>
                  : null
              }
              <ListMenuItem disabled={this.props.buildings.length === 1} onClick={() => this.showDeleteDialog(showModal)} secondIcon={iconTypes.delete}>
                {t('delete')}
              </ListMenuItem>
              <ListMenuItem className={'list-menu-add-item'} onClick={() => this.handleAddFloorToBuilding()} icon={iconTypes.addFloor}>
                {t('add_new_floor')}
              </ListMenuItem>
            </ListMenu>
          )}
        </ModalConsumer>
      </DropdownMenu>
    )
  }

  handleOpenModalClick (showModal, component, data) {
    this.props.onClose()
    showModal(component, data)
  }

  handleRenameBuilding (buildingName) {
    this.props.renameBuilding(this.props.currentBuildingId, buildingName)
  }

  handleCleanUpBuilding () {
    this.props.onClose()
    this.props.cleanUpBuilding(this.props.currentBuildingId)
  }

  handleAssistant () {
    this.props.onClose()
    this.props.autoGenerateAssignmentsInBuilding(this.props.currentBuildingId)
  }

  handleDeleteBuilding () {
    this.props.deleteBuilding(this.props.currentBuildingId)
  }

  handleAddFloorToBuilding () {
    this.props.onClose()
    this.props.addFloor(this.props.currentBuildingId)
  }

}

let mapStateToProps = (state) => {
  return {
    buildings: state.buildings.buildings,

    currentBuildingId: state.appState.selectedBuildingId,

    location: state.router.location,
  }
}

let mapDispatchToProps = {
  renameBuilding: renameBuildingAndFetch,
  cleanUpBuilding: deleteOrganisationalUnitAssignmentsInBuilding,
  autoGenerateAssignmentsInBuilding: autoGenerateAssignmentsInBuilding,
  deleteBuilding: deleteBuildingAndFetch,
  addFloor: addFloorAndFetchBuildings,
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(BuildingMenu))

