import React, { Component } from 'react'
import PropTypes from 'prop-types'

import BuilderOverviewElement from './BuilderOverviewElement'
import BuilderOverviewElementCreate from './BuilderOverviewElementCreate'
import { Scrollbars } from 'react-custom-scrollbars'
import { withTranslation } from 'react-i18next'
import Text from '../Text'

import './BuilderOverviewGroup.scss'

class BuilderOverviewGroup extends Component {

  getElements () {
    if (this.props.overviewContent != null) {
      return this.props.overviewContent
        .map((content, index) => {
          let imagePolys = (this.props.imagesPolygons && this.props.imagesPolygons.furnitureEnsembles) ? this.props.imagesPolygons.furnitureEnsembles.find(
            function (element) { return element.id === content.id}) : null

          return (
            <BuilderOverviewElement key={index}
                                    projectId={this.props.projectId}
                                    displayName={content.displayName}
                                    subtitle={this.getSubTitle(content)}
                                    overviewContent={content}
                                    onElementClicked={this.props.onElementClicked}
                                    renameContent={this.props.renameContent}
                                    onDeleteClicked={this.props.onDeleteClicked}
                                    onDuplicateClicked={this.props.onDuplicateClicked}
                                    imagePolys={imagePolys}/>
          )
        })
    } else {
      return (<div/>)
    }
  }

  render () {
    const elementsDiv = this.getElements()

    return (
      <div className='builder-overview-group'>

        {this.props.title ?
          <div className={'overview-header'}>
            <Text component={'title'}>{this.props.title}</Text>
          </div> : null
        }


        <Scrollbars autoHide>
          <div className='builder-overview-group-content'>
            <BuilderOverviewElementCreate onClicked={this.props.onCreateClicked}/>
            {elementsDiv}
          </div>
        </Scrollbars>
      </div>
    )
  }

  getSubTitle (content) {
    const {t} = this.props

    if (content.categoryId !== undefined)
      return t('type') + ': ' + this.getCapitalizedSubTitle(content.categoryId)

    let numberWorkplaces = this.getNumberOfWorkplaces(content)

    return numberWorkplaces + (numberWorkplaces === 1 ? ' ' + t('workplace') : ' ' + t('workplaces'))

  }

  getNumberOfWorkplaces (content) {
    let numberOfChildren = 0
    Object.keys(content)
      .forEach(child => content[child] && content[child].numberWorkplaces ? numberOfChildren += content[child].numberWorkplaces : null)
    return numberOfChildren
  }

  getCapitalizedSubTitle (str) {
    const {t} = this.props

    if (!str)
      return t('not_defined')

    let splitStr = str.split('_')

    if (splitStr.length > 1)
      return splitStr[0].charAt(0)
        .toUpperCase() + splitStr[0].slice(1) + ' ' + splitStr[1].charAt(0)
        .toUpperCase() + splitStr[1].slice(1)
    else
      return splitStr[0].charAt(0)
        .toUpperCase() + splitStr[0].slice(1)

  }

}

BuilderOverviewGroup.propTypes = {
  title: PropTypes.string,
  overviewContent: PropTypes.array,
  onElementClicked: PropTypes.func,
  onCreateClicked: PropTypes.func,
  onDeleteClicked: PropTypes.func,
  onDuplicateClicked: PropTypes.func,
  renameContent: PropTypes.func,
  imagesPolygons: PropTypes.object,
}

export default withTranslation() (BuilderOverviewGroup)
