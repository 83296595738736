import React, { Component } from 'react'
import PatternNavigation from './../../components/editor/PatternNavigation'
import PatternFurnitureMenu from './../../components/PatternFurnitureMenu'
import PatternInspector from './../../components/editor/PatternInspector'
import PatternBuilderPlan from './../../components/editor/PatternBuilderPlan'
import {
  addNewFurniture,
  addNewPattern,
  changeFurnitureParent,
  changeFurniturePosition,
  changeFurnitureRotation90,
  deleteFurniture,
  discardUndo,
  getUndoAvailable,
  redoPatternBuilder,
  replacePatternData,
  storePatternBuilderUndo,
  undoPatternBuilder,
} from './../../actions/actions'
import { updateFurnitureDragId } from '../../../common/redux/furnituredrag/furnituredag-actions'
import { fetchApplicationSettings } from '../../../common/redux/settings/settings-actions'
import { connect } from 'react-redux'
import PatternAreaSettings from './PatternAreaSettings'
import { changeFurnitureAnchorSide, changeFurnitureRotation, fetchPattern } from '../../actions/actions'
import BuilderFurnitureSettings from '../../../common/components/builder/BuilderFurnitureSettings'

import './PatternBuilder.scss'

class PatternBuilder extends Component {

  constructor (props) {
    super(props)
    this.state = {
      showAreaSettings: false,
      selectedArea: null,
      showFurnitureMenu: false,
      furnitureId: null,
    }

    this.furnitureList = null
  }

  componentDidMount () {
    const { projectId, patternId } = this.props.match.params

    if (patternId) {
      this.props.fetchPattern(projectId, patternId)
    } else {
      this.props.addNewPattern()
    }

  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { projectId, patternId } = this.props.match.params
    const { patternId: prevPatternId } = prevProps.match.params

    if (patternId && patternId !== prevPatternId) {
      this.hideFurnitureMenu()
      if (patternId) {
        this.props.fetchPattern(projectId, patternId)
      } else {
        this.props.addNewPattern()
      }
    }

    if (this.props.pattern)
      this.furnitureList = this.getFurnitureListFromPattern(this.props.pattern)
  }

  getFurnitureListFromPattern (pattern) {
    let furnList = []

    Object.values(pattern)
      .forEach((p) => {
        if (p && p.furnitureList) {
          p.furnitureList.forEach((furn) => furnList.push(furn))
        }
      })

    return furnList
  }

  showAreaSettings (area, width, depth) {
    this.setState({
      showAreaSettings: true,
      selectedArea: area,
      areaWidth: width,
      areaDepth: depth,
      showFurnitureMenu: false,
      furnitureId: null,
    })

  }

  hideAreaSettings (area) {
    this.setState({
      showAreaSettings: false,
      selectedArea: null,
      showFurnitureMenu: false,
      furnitureId: null,
    })
  }

  showFurnitureMenu (furnId) {
    this.setState({
      showAreaSettings: false,
      selectedArea: null,
      showFurnitureMenu: true,
      furnitureId: furnId,
    })
  }

  hideFurnitureMenu () {
    this.setState({
      showAreaSettings: false,
      selectedArea: null,
      showFurnitureMenu: false,
      furnitureId: null,
    })
  }

  handleSideMenuOnDeleteFurniture (id) {
    this.hideFurnitureMenu()
    if (id) {
      this.props.deleteFurniture(id)
    }
  }

  render () {

    const { projectId, patternId } = this.props.match.params

    return (
      <div id={'pattern-builder'}>

        <PatternNavigation
          projectId={projectId}
          currentPatternId={patternId}
          getUndoAvailable={this.props.getUndoAvailable}
          undoPatternBuilder={this.props.undoPatternBuilder}
          redoPatternBuilder={this.props.redoPatternBuilder}
          undoAvailable={this.props.undoAvailable}
          redoAvailable={this.props.redoAvailable}
          discardUndo={this.props.discardUndo}
        />

        <div className="main">

          <div className={'sidebar left'}>
            <PatternFurnitureMenu furnitureLibrary={this.props.furnitureLibrary}/>
          </div>

          <div className={'content'}>

            <PatternBuilderPlan
              furnitureLibrary={this.props.furnitureLibrary}
              onChangeFurniturePosition={(pos, uuid, area) => this.props.changeFurniturePosition(pos, uuid, area)}
              onChangeFurnitureRotation={(rot, uuid) => this.props.changeFurniturePosition(rot, uuid)}
              onChangeFurnitureParent={(par, uuid, connectorSide, posOnConnectorSide, root, area) => this.props.changeFurnitureParent(par, uuid, connectorSide, posOnConnectorSide, root, area)}
              onAddNewFurniture={(par, uuid, newId, connectorSide, posOnConnectorSide, root, constructor, area) => this.props.addNewFurniture(par, uuid, newId, connectorSide, posOnConnectorSide, root,
                constructor, area)}
              deleteFurniture={(uuid) => this.handleSideMenuOnDeleteFurniture(uuid)}
              updateFurnitureMenuItemDragId={(id) => this.props.updateFurnitureDragId(id)}
              furnitureMenuItemDragId={this.props.furnitureMenuItemDragId}
              replacePatternData={(pattern) => this.props.replacePatternData(pattern)}
              applicationSettings={this.props.settings}
              changeFurnitureRotation90={(uuid, areaType) => this.props.changeFurnitureRotation90(uuid, areaType)}
              showAreaSettings={(area, width, depth) => this.showAreaSettings(area, width, depth)}
              hideAreaSettings={() => this.hideAreaSettings()}
              showFurnitureMenu={(furnId) => this.showFurnitureMenu(furnId)}
              hideFurnitureMenu={() => this.hideFurnitureMenu()}
              getUndoAvailable={this.props.getUndoAvailable}
              storePatternBuilderUndo={this.props.storePatternBuilderUndo}
            />

          </div>

          <div className={'sidebar right'}>
            {this.state.showAreaSettings ?
              <PatternAreaSettings
                area={this.state.selectedArea}
                areaWidth={this.state.areaWidth}
                areaDepth={this.state.areaDepth}
                close={() => this.hideAreaSettings()}
                getUndoAvailable={this.props.getUndoAvailable}
                storePatternBuilderUndo={this.props.storePatternBuilderUndo}
              />
              :
              this.state.showFurnitureMenu ?
                <BuilderFurnitureSettings furnitureList={this.furnitureList} selectedFurnitureId={this.state.furnitureId}
                                          onRotateFurniture={(furnId, rot) => this.props.changeFurnitureRotation(furnId, rot)}
                                          onFurnitureChangeAnchorSide={(furnId, anchorSide) => this.props.changeFurnitureAnchorSide(furnId, anchorSide)}
                                          onDeleteFurniture={(id) => this.handleSideMenuOnDeleteFurniture(id)}
                                          getUndoAvailable={this.props.getUndoAvailable}
                                          storeApplicationBuilderUndo={this.props.storePatternBuilderUndo}
                />
                :
                <PatternInspector/>}
          </div>

        </div>
      </div>
    )
  }
}

let mapStateToProps = (state) => {
  return {
    settings: state.settings.appSettings,
    furnitureLibrary: state.furniture.furnitureLibrary,
    furnitureMenuItemDragId: state.furnitureDrag.furnitureMenuItemDragId,
    dirtyFlag: state.furnitureEnsembles.dirtyFlag,
    pattern: state.pattern.patternPlan,
    undoAvailable: state.pattern.undoAvailable,
    redoAvailable: state.pattern.redoAvailable,

  }
}

let mapDispatchToProps = {

  addNewFurniture: addNewFurniture,
  changeFurniturePosition: changeFurniturePosition,
  changeFurnitureParent: changeFurnitureParent,
  updateFurnitureDragId: updateFurnitureDragId,
  deleteFurniture: deleteFurniture,
  replacePatternData: replacePatternData,
  fetchApplicationSettings: fetchApplicationSettings,
  changeFurnitureRotation90: changeFurnitureRotation90,
  fetchPattern: fetchPattern,
  addNewPattern: addNewPattern,
  changeFurnitureRotation: changeFurnitureRotation,
  changeFurnitureAnchorSide: changeFurnitureAnchorSide,
  storePatternBuilderUndo: storePatternBuilderUndo,
  undoPatternBuilder: undoPatternBuilder,
  redoPatternBuilder: redoPatternBuilder,
  getUndoAvailable: getUndoAvailable,
  discardUndo: discardUndo,
}

export default connect(mapStateToProps, mapDispatchToProps)(PatternBuilder)
