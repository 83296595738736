import React from 'react'
import { NavLink } from 'react-router-dom'
import Icon from '../../common/components/icons/IconFactory'

import './NavLinkMenuItem.scss'

export function NavLinkMenuItem ({ urlBase, view, icon, title, darkMode = false }) {

  return (
    <NavLink className={'navigation-menu-item'}
             activeClassName={'toolbar-nav-active'}
             to={location => ({ ...location, pathname: `${urlBase}/${view}` })}>
      <div className={'navigation-menu-item-content'}>
        <div className={'navigation-menu-item-content-wrapper'}>
          {icon ? <Icon className={'navigation-menu-item-wrapper-icon'} name={icon} color={darkMode ? '#ffffff' : null}/> : null}
          <div className={'navigation-menu-item-text-wrapper' + (darkMode ? ' dark-mode' : '')}>
            <div className={'navigation-menu-item-text'}>
              <span className={darkMode ? 'dark-mode' : ''} title={title}>{title}</span>
            </div>
          </div>
        </div>
      </div>
    </NavLink>)

}
