import { projectsActionTypes } from './projects-actions'

let initialState = {
  project: null,
  userProjects: [],
}

function projectsReducer (state = initialState, action) {

  switch (action.type) {

    case projectsActionTypes.CREATE_PROJECT_FULFILLED: // Fall trough
    case projectsActionTypes.UPDATE_PROJECT_FULFILLED: // Fall trough
    case projectsActionTypes.FETCH_PROJECT_FULFILLED:

      return {
        ...state,
        project: action.payload.data,
      }

    case projectsActionTypes.CLEAR_PROJECT:
      return {
        ...state,
        project: null,
      }

    case projectsActionTypes.FETCH_USER_PROJECTS_LIST_FULFILLED:
      return {
        ...state,
        userProjects: action.payload.data,
      }

    default:
      return state
  }

}

export function findProjectInProjectsList (state, projectId) {
  return state.projects.userProjects
    .find(project => project.id === projectId)
}

export default projectsReducer