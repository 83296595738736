import store from '../../../store'
import { replace } from 'connected-react-router'

/**
 * This will add or overwrite the specified query param to the current location and push it to the history.
 * If the given history is the browser history this will lead to a change of the URL
 *
 * @param queryParamName the name of the query param
 * @param value the value to assign to the query param
 */
export function setQueryParam (queryParamName, value) {

  return (dispatch) => {
    const state = store.getState()
    const params = new URLSearchParams(state.router.location.search)

    if (queryParamName && value) {
      params.set(queryParamName, value)
    }

    dispatch(replace({
      pathname: state.router.location.pathname,
      search: params.toString(),
    }))
  }
}

export function removeQueryParam (queryParamName) {

  return (dispatch) => {
    const state = store.getState()
    const params = new URLSearchParams(state.router.location.search)

    if (queryParamName) {
      params.delete(queryParamName)
    }

    dispatch(replace({
      pathname: state.router.location.pathname,
      search: params.toString(),
    }))
  }
}