import React, { Component } from 'react'

import DropdownMenu from '../../common/components/DropdownMenu'
import { viewNames } from '../../common/globalConstants'

import ListMenu from '../../common/components/ListMenu'
import { NavLinkDropDownItem } from './NavLinkDropDownItem'
import { connect } from 'react-redux'

class FurnitureMenu extends Component {

  render () {

    const urlBase = this.props.projectId ? `/projects/${this.props.projectId}` : ''

    return (
      <DropdownMenu
        onClose={() => this.props.onClose()}
        className={'furniture-menu'}>
        <ListMenu className={this.props.advancedSettings ? 'dark-mode' : ''}>
          <NavLinkDropDownItem darkMode={this.props.advancedSettings}
                               onClose={() => this.props.onClose()}
                               urlBase={urlBase}
                               view={viewNames.PATTERNS}>
            Patterns
          </NavLinkDropDownItem>
          <NavLinkDropDownItem darkMode={this.props.advancedSettings}
                               onClose={() => this.props.onClose()}
                               urlBase={urlBase}
                               view={viewNames.SETS}>
            Furniture Sets
          </NavLinkDropDownItem>
        </ListMenu>
      </DropdownMenu>
    )
  }

}

let mapStateToProps = (state) => {
  return {
    advancedSettings: state.settings.appSettings.advancedSettings,
  }
}

export default connect(mapStateToProps)(FurnitureMenu)


