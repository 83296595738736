import React, { Component, Suspense } from 'react'
import { Route } from 'react-router'
import { Redirect, Switch } from 'react-router-dom'
import { hotjar } from 'react-hotjar'

import { viewNames } from './common/globalConstants'
import { Provider } from 'react-redux'
import store, { history } from './store'
import { ConnectedRouter } from 'connected-react-router'

import AppContainer from './common/components/layout/AppContainer'

import ModalProvider from './common/components/modal/ModalProvider'
import ProjectRouter from './ProjectRouter'
import AuthProvider from './common/auth/AuthProvider'

import DefaultApp from './default/DefaultApp'
import OnboardingApp from './onboarding/OnboardingApp'
import FloorPlanApp from './floorplan/FloorPlanApp'
import FloorsApp from './floors/FloorsApp'
import PeopleApp from './people/PeopleApp'
import DrawingToolApp from './DrawingTool/components/DrawingToolApp'
import ApplicationBuilderApp from './applicationbuilder/ApplicationBuilderApp'
import PatternBuilderApp from './patternbuilder/PatternBuilderApp'
import SettingsApp from './settings/SettingsApp'

import ModalRoot from './common/components/modal/ModalRoot'
import LoadingScreen from './common/components/layout/LoadingScreen'
import NotificationsContainer from './common/notification/NotificationsContainer'
import ErrorDialogsContainer from './common/error/ErrorDialogsContainer'
import './il8n'

class App extends Component {

  componentDidMount () {
    hotjar.initialize(2334192, 6)
  }

  render () {
    return (
        <Provider store={store} ref={(rootObject) => {window.rootObject = rootObject}}>
          <ModalProvider>
            <AuthProvider>
              <ConnectedRouter history={history}>
                <AppContainer>
                  <Route path="/projects/:projectId?" component={ProjectRouter}/>
                  <Suspense fallback={<LoadingScreen active/>}>
                    <Switch>
                      <Redirect exact from="/projects/:projectId" to={'/projects/:projectId/' + viewNames.FLOORPLAN}/>
                      <Route path={'/projects/:projectId/' + viewNames.ONBOARDING} component={OnboardingApp}/>
                      <Route path={'/projects/:projectId/' + viewNames.FLOORPLAN} component={FloorPlanApp}/>
                      <Route path={'/projects/:projectId/' + viewNames.EDIT} component={DrawingToolApp}/>
                      <Route path={'/projects/:projectId/' + viewNames.FLOORS} component={FloorsApp}/>
                      <Route path={'/projects/:projectId/' + viewNames.PEOPLE} component={PeopleApp}/>
                      <Route path={'/projects/:projectId/' + viewNames.SETS} component={ApplicationBuilderApp}/>
                      <Route path={'/projects/:projectId/' + viewNames.PATTERNS} component={PatternBuilderApp}/>
                      <Route path={'/projects/:projectId/' + viewNames.SETTINGS} component={SettingsApp}/>
                      <Route path="/projects" component={DefaultApp}/>
                      <Redirect to="/projects"/>
                    </Switch>
                  </Suspense>
                </AppContainer>
              </ConnectedRouter>

              <ModalRoot/>
              <LoadingScreen/>
              <NotificationsContainer/>
              <ErrorDialogsContainer/>

            </AuthProvider>
          </ModalProvider>
        </Provider>
    )
  }
}

export default App
