import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { push } from 'connected-react-router'

import BuilderNavBar from '../../../common/components/builder/BuilderNavBar'
import FurnitureSelector from '../inspector/FurnitureSelector'
import Inspector from '../inspector/Inspector'
import ApplicationBuilderPlan from './ApplicationBuilderPlan'

import { selectedElementType } from '../../reducers/uiState'

import {
  createFurnitureEnsembleAndFetch,
  fetchFurnitureEnsembleCategories,
  fetchFurnitureEnsemblePlan,
  fetchFurnitureLibrary,
  fetchTags,
  patchFurnitureEnsemble,
  updateFurnitureEnsemblePlan,
} from '../../actions/backendActions'

import {
  addNewFurniture,
  changeFurnitureEnsembleSize,
  changeFurnitureParent,
  changeFurniturePosition,
  changeFurnitureRotation,
  changeSelectedElement,
  deleteFurniture,
  discardNewApplication,
  discardUndo,
  getUndoAvailable,
  redoApplicationBuilder,
  renameApplication,
  resetDirtyFlag,
  storeApplicationBuilderUndo,
  undoApplicationBuilder,
} from '../../actions/frontendActions'

import { updateFurnitureDragId } from '../../../common/redux/furnituredrag/furnituredag-actions'

import './ApplicationBuilderEditor.scss'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { viewNames } from '../../../common/globalConstants'

class ApplicationBuilderEditor extends Component {

  componentDidMount () {
    if (this.props.match.params.applicationId) {
      this.props.fetchFurnitureEnsemblePlan(this.props.match.params.projectId, this.props.match.params.applicationId)
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.match.params.applicationId && this.props.match.params.applicationId !== prevProps.match.params.applicationId) {
      this.props.fetchFurnitureEnsemblePlan(this.props.match.params.projectId, this.props.match.params.applicationId)
    }
  }

  render () {
    const projectId = this.props.match.params.projectId
    const appId = this.props.furnitureEnsemblePlan.id
    const {t} = this.props

    // saveChanges & discardChanges
    let saveChanges
    let discardChanges

    if (appId) {
      saveChanges = () => this.handleSaveChanges(projectId, this.props.furnitureEnsemblePlan)
      discardChanges = () => this.handleDiscardChanges(projectId, appId)
    } else {
      saveChanges = () => this.props.saveNewApplication(projectId, this.props.furnitureEnsemblePlan)
      discardChanges = () => {
        this.props.discardNewApplication()
      }
    }

    return (
      <div className="application-builder-editor">
        <BuilderNavBar navItems={this.props.furnitureEnsembles}
                       projectId={this.props.match.params.projectId}
                       current={this.props.furnitureEnsemblePlan}
                       onNavigation={(appId) => this.handleChangeDisplayedApplication(appId)}
                       backToOverview={() => this.handleBackToOverview()}
                       dirtyFlag={this.props.dirtyFlag}
                       saveChanges={saveChanges}
                       discardChanges={discardChanges}
                       resetDirtyFlag={this.props.resetDirtyFlag}
                       renameTitle={(furnitureEnsemblePlan) => this.renameTitle(furnitureEnsemblePlan)}
                       dialogMessage={[t('application_has_been_modified'), t('confirm_or_discard_changes'), t('close_to_continue')]}
                       undoApplicationBuilder={() => {this.props.undoApplicationBuilder()}}
                       redoApplicationBuilder={() => {this.props.redoApplicationBuilder()}}
                       undoAvailable={this.props.undoAvailable}
                       redoAvailable={this.props.redoAvailable}
                       discardUndo={this.props.discardUndo}
        />

        <div className="main">
          <div className="sidebar left">
            <FurnitureSelector/>
          </div>

          <div className="content">
            <div id='application-builder-editor-plan'>
              <ApplicationBuilderPlan
                furnitureEnsemblePlan={this.props.furnitureEnsemblePlan}

                onChangeFurnitureEnsembleSize={(sizes) => this.props.changeFurnitureEnsembleSize(sizes)}
                onChangeFurniturePosition={(pos, uuid) => {this.props.changeFurniturePosition(pos, uuid)}}
                onChangeFurnitureRotation={(rot, uuid) => this.props.changeFurniturePosition(rot, uuid)}
                onChangeFurnitureParent={(par, uuid, connectorSide, posOnConnectorSide, root) => this.props.changeFurnitureParent(par, uuid, connectorSide, posOnConnectorSide, root)}
                onAddNewFurniture={(par, uuid, newId, connectorSide, posOnConnectorSide, root, constructor) => this.props.addNewFurniture(par, uuid, newId, connectorSide, posOnConnectorSide, root,
                  constructor)}
                onChangeSelectElement={(category, uuid) => this.props.changeSelectedElement(category, uuid)}
                updateFurnitureMenuItemDragId={(id) => this.props.updateFurnitureDragId(id)}
                furnitureMenuItemDragId={this.props.furnitureMenuItemDragId}
                furnitureLibrary={this.props.furnitureLibrary}
                deleteFurniture={this.props.deleteFurniture}
                selectedElementId={this.props.selectedElementId}
                roomSizeLocked={this.props.roomSizeLocked}
                storeApplicationBuilderUndo={this.props.storeApplicationBuilderUndo}
                undoRandom={this.props.undoRandom}
                getUndoAvailable={this.props.getUndoAvailable}

              />
            </div>
          </div>

          <div className="sidebar right">
            <Inspector/>
          </div>
        </div>

      </div>
    )
  }

  renameTitle (furnitureEnsemblePlan) {
    this.props.renameApplication(furnitureEnsemblePlan.id, furnitureEnsemblePlan.displayName)

    if (furnitureEnsemblePlan.id)
      this.props.renameFurnitureEnsemblePlan(this.props.match.params.projectId, furnitureEnsemblePlan.id, furnitureEnsemblePlan)
  }

  handleDiscardChanges (projectId, currentApplicationId) {
    this.props.changeSelectedElement(selectedElementType.FLOOR, '')
    this.props.discardChanges(projectId, currentApplicationId)
  }

  handleSaveChanges (projectId, furnitureEnsemblePlan) {
    this.props.changeSelectedElement(selectedElementType.FLOOR, '')
    this.props.saveChanges(projectId, furnitureEnsemblePlan.id, furnitureEnsemblePlan)
  }

  handleBackToOverview () {
    this.props.push('../' + viewNames.SETS + this.props.location.search)
  }

  handleChangeDisplayedApplication (applicationId) {
    this.props.changeSelectedElement(selectedElementType.FLOOR, '')

    this.props.push('../' + viewNames.SETS + '/' + applicationId + this.props.location.search)
  }

}

let mapStateToProps = (state) => {
  return {
    furnitureEnsembles: state.furnitureEnsembles.furnitureEnsembles,
    furnitureEnsemblePlan: state.furnitureEnsembles.furnitureEnsemblePlan,
    undoRandom: state.furnitureEnsembles.undoRandom,
    furnitureLibrary: state.furniture.furnitureLibrary,
    furnitureMenuItemDragId: state.furnitureDrag.furnitureMenuItemDragId,
    selectedElementId: state.applicationBuilderUiState.selectedElement,
    roomSizeLocked: state.applicationBuilderUiState.roomSizeLocked,
    dirtyFlag: state.furnitureEnsembles.dirtyFlag,
    undoAvailable: state.furnitureEnsembles.undoAvailable,
    redoAvailable: state.furnitureEnsembles.redoAvailable,

  }
}

let mapDispatchToProps = {
  fetchFurnitureEnsemblePlan: fetchFurnitureEnsemblePlan,
  fetchFurnitureEnsembleCategories: fetchFurnitureEnsembleCategories,
  fetchFurnitureLibrary: fetchFurnitureLibrary,
  fetchTags: fetchTags,
  getUndoAvailable: getUndoAvailable,

  renameApplication: renameApplication,
  renameFurnitureEnsemblePlan: patchFurnitureEnsemble,
  saveChanges: updateFurnitureEnsemblePlan,
  saveNewApplication: createFurnitureEnsembleAndFetch,
  discardChanges: fetchFurnitureEnsemblePlan,
  discardNewApplication: discardNewApplication,
  resetDirtyFlag: resetDirtyFlag,

  changeFurnitureEnsembleSize: changeFurnitureEnsembleSize,
  changeFurniturePosition: changeFurniturePosition,
  changeFurnitureRotation: changeFurnitureRotation,
  changeFurnitureParent: changeFurnitureParent,
  addNewFurniture: addNewFurniture,
  changeSelectedElement: changeSelectedElement,
  updateFurnitureDragId: updateFurnitureDragId,
  deleteFurniture: deleteFurniture,

  storeApplicationBuilderUndo: storeApplicationBuilderUndo,
  undoApplicationBuilder: undoApplicationBuilder,
  redoApplicationBuilder: redoApplicationBuilder,
  discardUndo: discardUndo,

  push: push,
}

export default compose(withTranslation(), withRouter)(connect(mapStateToProps, mapDispatchToProps)(ApplicationBuilderEditor))
