import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import { loadConfig } from './common/config.service'
import { applyPolyfills, defineCustomElements } from 'haworth-service-bar/loader'
import App from './App'

(async () => {

  await loadConfig()

  const root = document.getElementById('root')

  ReactDOM.render(<App/>, root)

  // include the stencil component library
  // applyPolyfills is needed for targeting Edge or IE11
  applyPolyfills()
    .then(() => {
      defineCustomElements(window)
    })

})()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
